.payment_container {
    padding: 50px 24px;
    background-image: url(../assets/img/pattern.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
    .container{
        max-width: 800px;
        width: 100%;
        border-radius: 16px;
        box-shadow: 0 7px 27px rgba(44, 41, 37, .06), 0 4px 16px rgba(0, 0, 0, .07);
        display: flex;
        position: relative;
        background-color: #fff;
        align-items: flex-start;
        padding: 40px 0 0 0;
        flex-direction: column;
        flex-wrap: wrap;
        .status{
            width: 56px;
            height: 56px;
            position: absolute;
            top: -30px;
            left: 50%;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #ccc;
            border: 5px solid #fff;
            transform: translateX(-50%);
            &.success {
                background-color: #7be994;
                &::before {
                    content: '\f00c';
                    position: absolute;
                    font-size: 20px;
                    font-family: "Font Awesome 5 Pro";
                    color: #fff;
                    font-weight: 100;
                }
               
            }
            &.error {
                background-color: #ffc107;
                &::before {
                    content: '\f119';
                    position: absolute;
                    font-size: 30px;
                    font-family: "Font Awesome 5 Pro";
                    color: #fff;
                    font-weight: 100;
                }
            }
        }
        .status_message{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-bottom: 1px dashed #ccc;
            padding-bottom: 10px;
            .title_medium{
                color: #000;
                font-size: 24px;
            }
            p{
                color: #868282;
            }
            &.order_traking{
                border-bottom: 0;
                border-top: 1px dashed #ccc;
                padding: 24px 0;
                justify-content: flex-start;
                .title_medium{
                    color: #000;
                    font-size: 18px;
                    line-height: unset;
                }
                a{
                    color: #bc8129;
                    font-size: 14px;
                    text-decoration: none;
                }
            }
        }
        .account_info{
            width: 100%;
            background-color: #f8f8f8;
            padding: 32px;
            .txn-id{
                text-align: center;
                padding-bottom: 16px;
                font-weight: 600;
                font-size: 14px;
            }
            .box_align{
                width: 100%;
                max-width: 500px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap:24px;
                margin: 0 auto;
            }
            .field{
                display: flex;
                width: 45%;
                flex-direction: column;
                label{
                    color: #868282;
                    font-size: 13px;
                }
                .value{
                    color: #393655;
                    font-size: 15px;
                    font-weight: 600;
                    display: inline-flex;
                    flex-direction: column;
                    gap: 5px;
                    .tag{
                        background-color: #fff7d1;
                        border-radius: 3px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0px 6px;
                        font-size: 13px;
                        border: 1px solid #ccc;
                        max-width: max-content;
                        margin-left: 5px;
                        &.field{
                            color: #fff;
                            background-color: #ffc107;
                            border-color: #ffc107;
                        }
                    }
                    &.title2{
                        font-weight: unset;
                        font-size: 14px;
                        color: #868282;
                    }
                }
                &:nth-child(even){
                    text-align: right;
                }

            }
            .field_info{
                max-width: 500px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
                .title_medium {
                    font-size: 22px;
                    color: #000;
                }
            }
            &.without_info{
                text-align: center;
                padding: 100px 32px;
                max-width: 700px;
                max-width: 100%;
                font-size: 18px;
                margin: 0 auto;
                background-color: #fff;
                line-height: 30px;
                border-radius: 0 0 16px 16px;
            }
           
        }
    }
}