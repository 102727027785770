@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
.textbox-lines {
  width: 100%;
  max-width: 100%;
  font-size: 19px;
  border: none;
  height: 200px;
  background-attachment: local;
  background-image: linear-gradient(to right, transparent, transparent 10px, transparent 10px),
    linear-gradient(to left, transparent, transparent 10px, transparent 10px),
    repeating-linear-gradient(transparent, transparent 36px, #ccc 36px, #ccc 38px);
  background-color: transparent;
  line-height: 38px;
  resize: none;
  overflow: auto;
  color: #333 !important;
}

section.slam_book {
  flex: 1;
  display: flex;
  .book-contain {
    transition: transform 250ms;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: all 5s;
    padding: 20px;
    &:has(.open) {
      justify-content: flex-end;
      transition: all 5s;
    }

    .book {
      position: relative;
      border-radius: 10px;
      width: 100%;
      max-width: 555px;
      height: 100%;
      background-color: var(--sliderCardBKG);
      -webkit-box-shadow: 1px 1px 12px #8b8b8b;
      box-shadow: 1px 1px 12px #8b8b8b;
      -webkit-transform: preserve-3d;
      -ms-transform: preserve-3d;
      transform: preserve-3d;
      -webkit-perspective: 2000px;
      perspective: 2000px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      color: #000;
      flex-direction: column;
      background-image: url(../assets/img/form-left-banner.png);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
      transition: all 10s;
      .book_content {
        width: 100%;
        padding: 50px;
        height: 100%;
        form {
          height: 100%;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
        }

        h1 {
          font-size: 25px;
          color: #bc8129;
        }

        textarea {
          color: #000000;
        }

        div.upload {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          gap: 40px;
          flex-direction: column;
          p {
            font-size: 16px;
            color: #000000;
            font-weight: 100;
          }
          .three-dots-load {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          label {
            cursor: pointer;
            margin-bottom: 10px;
            input {
              display: none;
            }

            span {
              position: relative;
              width: 60px;
              height: 60px;
              border: 1px dashed var(--orange);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: transform 0.4s;

              i {
                position: absolute;
                font-size: 25px;
                color: var(--orange);
              }

              &:hover {
                transform: scale(0.8);
              }
            }
          }

          .gallery {
            max-height: 250px;
            .img_loader {
            display: flex;
            justify-content: center;
          }
            .gallery__item {
              &:nth-child(2n) {
                grid-column: span 1 !important;
              }
            }
          }
        }
      }
    }
    .cover {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      cursor: pointer;
      padding: 20px;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      -webkit-transform-origin: 0;
      -ms-transform-origin: 0;
      transform-origin: 0;
      -webkit-box-shadow: 1px 1px 12px #b9b9b9;
      box-shadow: 1px 1px 12px #b9b9b9;
      display: -webkit-box;
      display: -ms-flexbox;
      background-size: cover;
      background: url(../assets/img/back-cover-slambook2.png),
        linear-gradient(270deg, rgba(221, 200, 179, 1) 0%, rgba(210, 155, 116, 1) 100%);
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.5s;
      background-size: cover;
      left: 0;
      transition: all 0.5s;
      .cover-content {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;

        span {
          width: 100%;
          max-width: 370px;
          h4 {
            font-size: 45px;
            color: #5a2e02;
            font-family: 'Great Vibes', cursive;
            text-align: center;

            code {
              font-family: 'Great Vibes', cursive;
              font-size: 60px;
              color: #5a2e02;
              display: block;
              padding-left: 30px;

              @media (max-width: 767px) {
                padding-left: 0px;
                font-size: 40px;
              }
            }
            @media (max-width: 767px) {
              padding-left: 0px;
              font-size: 40px;
            }
          }
          h5 {
            font-size: 40px;
            color: #5a2e02;
            font-family: 'Great Vibes', cursive;
            font-weight: 600;
            display: flex;
            width: 100%;
            justify-content: center;
          }

          @media (max-width: 580px) {
            h5 {
              padding-left: 0px;
              font-size: 35px !important;
            }
            h4 {
              font-size: 38px !important;
            }
          }
        }

        .wedding_image {
          span {
            img {
              width: 250px;
              height: 250px;
              border-radius: 50%;
              margin: 20px;
              border: 5px solid #ddc6b1;
              object-fit: cover;
              object-position: center right;
              @media (max-width: 580px) {
                margin: 0;
                width: 200px;
                height: 200px;
              }
            }
          }
        }

        .couple_name {
          font-family: 'Great Vibes', cursive;
          font-size: 50px;
          color: #5a2e02;
          line-height: 1;
          span {
            display: block;
            margin-left: 80px;
          }
          @media (max-width: 580px) {
            font-size: 35px !important;
          }
        }
      }
      .close-icon {
        display: none;

        i {
          color: #953c00;
          font-size: 25px;
        }
      }
    }

    .open .cover {
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      -webkit-transform: rotatey(-180deg);
      -ms-transform: rotatey(-180deg);
      transform: rotatey(-180deg);
      display: flex;
      justify-content: flex-end;
      background: url(../assets/img/back-cover-slambook2.png),
        linear-gradient(270deg, rgba(221, 200, 179, 1) 0%, rgba(210, 155, 116, 1) 100%);
      background-size: cover;
      .close-icon {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 20px;
      }
      .cover-content {
        display: none;
      }
    }
  }
}

// Edit greetings page
.content_list {
  .box-content {
    .both_content {
      .left_content {
        .field_container {
          input {
            outline: none !important;
          }
        }

        .texture-images {
          display: flex;
          flex-wrap: wrap;
          gap: 2px;
          img {
            width: 55px;
          }
        }
      }
    }
    .slam_book {
      height: 100%;
      min-height: 650px;
      padding: 20px;
      .book {
        .cover-content {
          h4 {
            code {
              font-size: 40px;
            }
          }
          h5 {
            font-size: 30px;
          }
          .wedding_image {
            span {
              img {
                width: 200px;
                height: 200px;
              }
            }
          }
          .couple_name {
            font-size: 50px;
          }
        }
      }
    }
  }
}
