

.table_grid {
  border: 1px solid #ccc;
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 16px;

  .tbl-header {
    border-bottom: 0 !important;
    @media (max-width: 580px) {
      .ant-btn {
        font-size: 12px !important;
        padding: 5px !important;
      }
    }
  }

  .rdt_TableCol {
    font-size: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
    .ant-tag {
      background-color: var(--sliderCardBKG);
      white-space: pre-line !important;
    }
    .rsvp_tag {
      display: flex;
      text-transform: capitalize;
      border-radius: 6px;
      overflow: hidden;
      font-size: 12px;
      &.orange {
        border: 1px solid #d9d9d9;
        span:nth-child(1) {
          border-color: #d9d9d9;
          padding: 5px;
          border-right: 1px solid #d9d9d9;
          background-color: var(--sliderCardBKG);
          font-weight: 100;
        }
        span:nth-child(2) {
          padding: 5px;
          background: #f05537;
          color: #fff;
          font-weight: 100;
        }
      }
      &.green {
        border: 1px solid green;
        span:nth-child(1) {
          padding: 5px;
          border-right: 1px solid #d9d9d9;
          background-color: #b5cf49;
          font-weight: 100;
        }
        span:nth-child(2) {
          padding: 5px;
          background: #617b30;
          color: #fff;
          font-weight: 100;
        }
      }
    }
  }

  .rdt_ExpanderRow {
    margin-left: 48px;
    width: calc(100% - 48px);
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    .rdt_TableHeadRow {
      background-color: #ffede6;
    }
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  .rdt_TableHeadRow {
    background-color: #f4f4f7;
  }

  table {
    width: 100%;
    table-layout: fixed;
  }

  .tbl-header {
    background-color: #f4f4f7;
    border-bottom: 1px solid #ccc;

    text-align: center;
  }

  .tbl-content {
    overflow-x: auto;
    margin-top: 0px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    tr:nth-child(even) {
      background-color: #f4f4f7;
    }

    tr {
      &:hover {
        .action_column {
          .fa-trash {
            opacity: 1;
          }
        }
      }
    }

    .action_column {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      gap: 16px;

      i {
        font-size: 18px;
        font-weight: 200;
      }

      .fa-ellipsis-v {
        font-size: 28px;
      }

      .fa-trash {
        opacity: 0;
      }

      .more_menu {
        .popup_menu {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 160px;
          background-color: #fff;
          border-radius: 3px;
          box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
          border: 1px solid #ccc;
          right: 0;
          top: 0;
          opacity: 0;
          z-index: -1;

          a {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 12px;
            border-bottom: 1px solid #ccc;
            color: var(--purple);
            box-sizing: border-box;
            text-decoration: none;

            &:hover {
              background-color: #f4f4f7;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }

        &:hover {
          .popup_menu {
            opacity: 1;
            z-index: 9;
          }
        }
      }
    }
    .memories-icon {
      img {
        border-radius: 50%;
        margin-left: -10px;
        border: 2px solid #fff;
        display: none;
        &:nth-child(1) {
          margin-left: 0px;
          display: inline-block;
        }
        &:nth-child(2) {
          display: inline-block;
        }
        &:nth-child(3) {
          display: inline-block;
        }
      }
    }
  }

  th {
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  }

  td {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 15px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);

    .wedding_day {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 14px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
