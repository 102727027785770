.page_not_found {
  height: calc(100vh - 80px);
  background-image: url('../assets/img/form-bkg.png');
  background-repeat: repeat;
  background-position: left center;
  background-size: contain;
  background-color: #fffaf6;

  @media (max-width: 480px) {
    height: calc(100vh - 110px);
    h1 {
      font-size: 50px !important;
    }
  }
}
