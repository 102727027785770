@use '../../abstracts/variables' as *;
@use '../../themes/default/variables' as *;
@use '../../abstracts/mixins' as *;
@use '../../themes/default/colors' as *;
@use '../../abstracts/breakpoints' as *;
@use '../../components/buttons' as *;

// common variables related to the video invitation
$form-bkg: '../../assets/img/v1/video-invite/form-bkg.png';

.create_invitation {
  .container {
    gap: var(--s-8) !important;
    padding-bottom: 0;
  }
  .edit_slide_container {
    overflow: auto;
    @include flex-direction(flex, flex-start, center, column);
    .video_panel {
      // transform: scale(0.8);
      // transform-origin: center top;
      position: relative;
      width: 500px;
      div {
        span {
          display: inline-block;
          border: 2px dashed #bc8129;
          padding: 0 var(--s-16);
        }
      }
    }
    @include respond-to('laptop') {
      overflow: unset;
      display: unset;
    }
  }
}

.slide_info_toolbar {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: var(--s-32) 1fr var(--s-32);
  grid-gap: var(--s-8);
  align-items: center;
  .prev,
  .next {
    min-width: unset;
    width: var(--s-32);
    height: var(--s-32);
    overflow: hidden;
    border-radius: 50%;
    border: 0;
  }

  .stepper_wrapper {
    @include flex-direction(flex, flex-start, center, row);

    scrollbar-width: none;
    overflow: auto;
    flex: 1;
    padding: var(--s-4);
    .btn {
      min-width: 4.25rem;
    }
  }
}
.mobile_screen {
  .slide_info_toolbar {
    height: var(--s-56);
    width: 100%;
    padding: 0 var(--s-12);
    &.fixed {
      @include box-shadow(0 2px 5px rgba($black, 0.1));

      position: fixed;
      top: var(--s-56);
      left: 0;
      z-index: $zindex-fixed;
      background-color: $white;
      padding: 0 var(--s-12);
    }
  }
  .notification_panel {
    background-color: $notification;
    border-bottom: 2px solid #f5e0b8;
    .container {
      @include flex-direction(flex, flex-start, flex-start, column);

      padding-top: var(--s-8);
      padding-bottom: var(--s-8);
      gap: var(--s-8);
      p {
        font-size: var(--s-14);
      }
    }
    .info_tooltip_panel {
      z-index: 9;
    }
    .react-tooltip {
      width: 90vw;
    }
  }
  .stepper_btn {
    @include flex-direction(flex, center, center, row);

    position: fixed;
    height: var(--s-72);
    background-color: $white;
    width: 100%;
    bottom: 0;
    z-index: $zindex-modal-backdrop;
    left: 0;
    padding: 0 var(--s-12);
  }
}

.large_screen {
  background-color: #f9f5f0;
  .notification_panel {
    background-color: $notification;
    border-bottom: 2px solid #f5e0b8;
    .container {
      @include flex-direction(flex, flex-start, flex-start, column);

      padding-top: var(--s-16);
      padding-bottom: var(--s-16);
      gap: var(--s-16);
      p {
        font-size: var(--s-16);
      }
    }
  }
  .stepper_btn {
    .inner_container {
      @include flex-direction(flex, flex-start, flex-start, row);

      gap: var(--s-24);
      padding: var(--s-16) 0;
    }
    .stepper_wrapper {
      .btn {
        font-size: var(--s-14);
        padding: 6px 8px;
      }
    }
    &.fixed {
      @include box-shadow(0 2px 5px rgba($black, 0.1));

      position: fixed;
      top: var(--s-56);
      height: var(--s-56);
      left: 0;
      z-index: $zindex-fixed;
      background-color: $white;
      padding: 0 var(--s-12);
      width: 100%;
      display: flex;
      align-items: center;
      .inner_container {
        width: 100%;
        max-width: $Container-width;
        margin: 0 auto;
        padding: 0 var(--s-16);
      }
    }
  }
  .edit_slide_container {
    @include flex-direction(flex, flex-start, flex-start, row);

    gap: var(--s-24);
    .video_panel {
      @include box-shadow(0 5px var(--s-12) rgba($black, 0.2));

      transform: scale(0.85);
      transform-origin: left top;
      background-color: $white;
      max-width: 500px;
      padding: 0;
      position: relative;
      width: 100%;
      @include respond-to('largeScreen') {
        transform: unset;
        transform-origin: unset;
      }
    }
    .stepper_form_panel {
      flex: 1;
      &.fixed {
        position: sticky;
        top: 140px;
        left: 0;
        padding: 0 var(--s-12);
        width: 100%;
      }
    }
  }
}

.video_text_edit {
  position: absolute;
  width: inherit;
  z-index: $zindex-sticky;
  .text_edit_span {
    background-color: #212121;
    padding: var(--s-6) !important;
    display: flex;
    border-radius: 8px;
    width: 250px;
    height: 100px;

    margin: auto;
    position: relative;
    &::before {
      content: '\f0de';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      display: block;
      font-size: 28px;
      position: absolute;
      top: -17px;
      text-shadow: none;
      left: 45%;
      color: #212121;
    }
    textarea {
      background-color: #414141;
      height: 100% !important;
      border: none;
      color: #fff;
      font-size: 15px !important;
      font-family: 'Arima', system-ui;
    }
  }
}

.who_inviting_popup {
  background-color: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    @include flex-direction(flex, center, center, row);

    max-width: unset;
    height: 100vh;
    margin: 0;
    width: 100vw;
    .modal-content {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      width: 100%;
      max-width: 768px;
      margin: var(--s-12);
    }
    .who_inviting {
      @include flex-direction(flex, flex-start, flex-start, column);

      gap: var(--s-24);
      padding: var(--s-12);
      border-radius: 5px;
    }
  }
  .upload_img .border_dotted {
    width: 100%;
    max-width: 420px;
    border-radius: 5px;
  }
}

.video_signup_success {
  background-color: $light-medium-pink;
  background-image: url($form-bkg);
  background-position: 0;
  background-repeat: repeat;
  background-size: contain;
  height: calc(100vh - 50px);
  .container {
    @include flex-direction(flex, center, center, row);
    .card-body {
      @include flex-direction(flex, center, center, column);

      padding: var(--s-12);
      gap: var(--s-8);
    }
  }
}

.otp_field_container {
  .otp_parent {
    justify-content: center;
    gap: var(--s-12);
    margin: var(--s-12) 0;
    input {
      margin: 0 !important;
      width: var(--s-20) !important;
      height: var(--s-20) !important;
      @include respond-to('laptop') {
        width: var(--s-28) !important;
        height: var(--s-28) !important;
      }
    }
  }
}
