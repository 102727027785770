@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Kanit:wght@300;400&family=Lato:wght@300;400;700&family=Noto+Sans:wght@300;400&family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima:wght@200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600&family=Poppins:wght@200;300;400;500&display=swap");

// video fonts
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Cookie&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
:root {
  /* Colors */
  --gray: #858a92;
  --grayLight: #b0afaf;
  --orange: #bc8129;
  --purple: #393655;
  --blueDark: #191c4b;
  --purpleLight: #a39fc5;
  --offWhite: #eeeeee;
  --blueDark: #1c355e;
  --redLight: #f9f5f0;
  --lightBrown: #e5e1d5;

  /* All Sizes */
  --size56: 56px;
  --size36: 36px;
  --size16: 16px;

  /* font sizes */
}

:where(.css-dev-only-do-not-override-htwhyh)[class^='ant-drawer'],
:where(.css-dev-only-do-not-override-htwhyh)[class*=' ant-drawer'],
:where(.css-dev-only-do-not-override-htwhyh)[class^='ant-btn'],
:where(.css-dev-only-do-not-override-htwhyh)[class*=' ant-btn'] {
  font-family: inherit;
}

.btn-group{
  .dropdown-menu{
    padding: 0;
    button {
      height: 36px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      &:hover,&:active {
        background-color: #bc8129;
        color: #fff
      }
    }
    .dropdown-divider{
      display: none;
    }
  }
}

.primary-setup {
  --header-height: var(--size56);
  --border: 1px solid var(--grayLight);
  --btn-height: var(--size32);
  --textGlobalColor: var(--purple);
  /* --heroPanelBG: #1c355e; */
  --heroPanelBG: #191c4b;
  --textLightColor: var(--purpleLight);
  --sliderCardBKG: var(--redLight);

  &.inner_page {

    /* --heroPanelBG: var(--lightBrown); */
    .container {
      border: 0;
    }

    .footer {
      background-color: #191c4b;
    }
  }
}

.running_text{
  font-size: 16px;
  font-family: "Arima", system-ui;
  font-weight: 500;

}
.field_error {
  font-size: 12px;
  color: red;
}

.field_success {
  font-size: 13px;
  color: green;
}

.btn {
  border: var(--border) !important;
  color: var(--textGlobalColor);
  height: var(--btn-height);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  min-width: 80px;
  line-height: unset;
  white-space: nowrap;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.without-border {
    border: 0 !important;
  }

  &.active {
    color: #fff;
    border-color: #bc8129 !important;

    background-image: linear-gradient(30deg, #bc8129 50%, #995e09 64%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: all .3s ease-out;

  //   background: linear-gradient(to right, #5c3f12 50%, #bc8129 50%);
  // background-size: 200% 100%;
  // background-position: bottom right;
  // transition: all .3s ease-out;
  
  &:hover {
    background-position: 85%;
    color: #fff !important;
  }
  &.active_orange {
    color: #fff;
    border-color: #f96328 !important;

    background-image: linear-gradient(30deg, #f96328 50%, #e1470a 64%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: all .3s ease-out;
    &:hover {
      background-position: 85%;
      color: #fff !important;
    }
  }

  }

  &.fit-to-content {
    width: fit-content;
  }

  &.without_background {
    border: 0 !important;
    background-color: transparent;
    padding: 0;
    color: #000;
    min-width: unset;
  }

  &.without_background-with-border {
    border: 1px solid var(--orange);
    background-color: transparent;
    color: var(--orange);
  }

  &.disabled {
    pointer-events: none;
    background-color: #e7e7e7;
    border-color: #ccc !important;
    color: #222;
    opacity: 0.5;
  }

  &.with-icon {
    gap: 8px;
  }
  // &:hover {
  //   color: #fff;
  //   border-color: #bc8129 !important;

  //   background-image: linear-gradient(30deg, #bc8129 50%, #995e09 64%);
  //   background-size: 500px;
  //   background-repeat: no-repeat;
  //   background-position: 0%;
  //   -webkit-transition: background 300ms ease-in-out;
  //   transition: all .3s ease-out;
  //   background-position: 85%;
  //     color: #fff !important;
  // }

  &.big {
    font-size: 16px;
    padding: 10px 16px;
    border-radius: 4px;

    @media (max-width: 1024px) {
      font-size: 16px;
      padding: 10px 24px;
      border-radius: 5px;
    }
  }
}

// image upload file button 
.upload_img {
  .border_dotted {
    border: 1px dashed #ccc;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 18px;
    align-items: center;

    .icon-img {
      width: 20%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .file_upload {
      input[type='file'] {
        display: none;
      }

      .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        background-color: var(--orange);
        color: #fff;

        .upload_field {
          padding: 10px;
        }
      }
    }
  }
}

//loader
.img_loader{
  &.full_screen {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.4);
  }

}


.customize_btn {
  a {
    .btn.big {
      font-size: 16px;
      padding: 6px 12px;
      border-radius: 3px;
    }
  }
}

.link_btn{
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: #bc8129;
}

.title_large {
  font-size: 32px;
  font-family: "Arima", system-ui;
  color: #bc8129;

  @media (max-width: 700px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

.title_medium {
  font-size: 32px;
  line-height: 48px;
  position: relative;
  font-family: "Arima", system-ui;
  color: #bc8129;

  @media (max-width: 700px) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  &.title_border {
    .design{
      position: relative;
      &::after {
        width: 60%;
        content: "";
        position: absolute;
        height: 5px;
        background-color: #bc8129;
        left: 0;
        bottom: 0;
        border-radius: 5px;
      }
    }
   
   
  }
}

.title_mediumLight {
  font-size: 24px;
  line-height: 32px;
}

.title_small {
  font-size: 18px;
}

.text_smallMedium {
  font-size: 14px;
}

.small_text {
  font-size: 12px;
}

/* Checkbox */

/* react drop-down style */
.select-dropdown-container {
  .select_dropdown {
    &__control {
      box-sizing: border-box;
      height: 36px;
      min-height: unset;
      border-color: #b0afaf;
      border-radius: 3px;

      &--is-focused {
        outline: none;
        border-color: #b0afaf;
        box-shadow: none;
      }

      &--menu-is-open {
        border-color: #b0afaf !important;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      height: 36px;
    }

    &__input-container {
      height: 0;
    }

    &__placeholder {
      font-size: 13px;
      font-weight: 300;
      color: #000;
    }

    &_dropdown-indicator {
      color: #959090;
    }

    &__input {
      height: 0;
    }
  }

  &.with_input_field {
    .select_dropdown__input-container {
      height: auto;

    }

    .select_dropdown__input {
      height: auto;
    }
  }
  .select_dropdown__menu {

    .select_dropdown__option {

      &.css-eeavu3-option {

        background-color: #fff6ed !important;
      }
      &.css-8zqerl-option {

        background-color: var(--orange);
      }
    }
  }
}

/* This css is for normalizing styles. You can skip this. */

.form-group input[type='checkbox'] {
  display: none;
  cursor: pointer;
}

input[type='checkbox']+label:before {
  content: '';
  -webkit-appearance: none;
  background-color: #ccc;
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

input[type='checkbox']:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 11px;
  left: 16px;
  width: 4px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Text box */
input[type='email'],
input[type='password'],
input[type='text'],
input[type='date'],
input[type='number'],
input[type='time'],
textarea,.box-with-same_input {
  width: 100%;
  height: 36px;
  border-radius: 3px;
  padding: 0 8px;
  font-size: 13px;
  font-weight: 300;
  align-items: center;
  border: var(--border);
  overflow: hidden;





  &:focus {
    outline: none;
  }

  &:read-only {
    cursor: default;
    background-color: #f7f7f7;
    color: #9f9797;
    border-color: #ccc;
  }

  &.only_bottom_line {
    border-radius: 0;
    border: 0;
    border-bottom: var(--border);
    background-color: transparent !important;
  }
}
.box-with-same_input {

  padding: 0;
                    display: flex;
                    border-color: #b0afaf;

                   i {
                      height: 100%;
                      width: 32px;
                      min-width: 32px;
                      display: inline-flex;
                      align-items: center;
                      justify-content: center;
                      border-right: 1px solid #b0afaf;
                      color: #09090b;
                      font-size: 15px;
                  }
                  .PhoneInputCountry {
                    height: 100%;
                    width: 48px;
                    min-width: 48px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid #b0afaf;
                    color: #09090b;
                    font-size: 15px;
                    margin-right: 0;
                }

input {
    border: 0 !important;
    height: 100%;
    &:focus-visible {

      outline: none;
    }
}
}

textarea {
  resize: none;
}

/* Select box */
.select {
  position: relative;
  margin-bottom: 15px;
  width: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  .selectBtn {
    background: var(--bg1);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff;

    &:after {
      content: '';
      position: absolute;
      top: 45%;
      right: 15px;
      width: 6px;
      height: 6px;
      -webkit-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg);
      border-right: 2px solid #666;
      border-bottom: 2px solid #666;
      transition: 0.2s ease;
    }

    &.toggle {
      border-radius: 3px 3px 0 0;

      &:after {
        -webkit-transform: translateY(-50%) rotate(-135deg);
        transform: translateY(-50%) rotate(-135deg);
      }
    }
  }

  .selectDropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    border-radius: 0 0 3px 3px;
    overflow: hidden;
    background: var(--bg1);
    border-top: 1px solid #eee;
    z-index: 1;
    background: #fff;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: top center;
    transform-origin: top center;
    visibility: hidden;
    transition: 0.2s ease;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);

    .option {
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: #f8f8f8;
      }
    }

    &.toggle {
      visibility: visible;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }

  &.button {
    .selectBtn {
      background-color: var(--orange);
      color: #fff;

      &::after {
        border-color: #fff;
      }
    }
  }
}

/* Model popover */

.ant-drawer-content-wrapper {
  @media (max-width: 800px) {
    width: 90% !important;

    .multi_field_container {
      flex-direction: column;

      .divider {
        flex-direction: column;
      }
    }

    .field_container {
      width: 100% !important;
    }
  }
}

.ant-drawer {
  z-index: 999999;
}

.ant-drawer-body {
  display: flex;
  flex-direction: column;

  input[type='email'],
  input[type='password'],
  input[type='text'],
  input[type='date'],
  input[type='number'],
  input[type='time'],
  textarea,
  .select_dropdown__control {
    background-color: #f8f8fa;
  }

  .group_field_container {
    width: 100%;

    form {
      margin: 0 auto;
      width: 100%;
    }
  }
}

.ant-drawer-header {
  .ant-drawer-title {
    font-weight: unset;
  }

  button {
    color: #000 !important;
  }
}

.content_panel {
  flex: 1;
  overflow: auto;

  &.more_space {
    padding: 70px;
  }
}

.btn-toolbar {
  .select {
    box-shadow: none;
  }

  .btn.with-icon {
    border: var(--border);
  }

  .selectBtn {
    border: var(--border);
    font-weight: 300;
  }
}

.group_field_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 600px;

  .field_container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .PhoneInput {
        input {

          background-color: #f8f8fa;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    padding: 0 8px;
    font-size: 13px;
    font-weight: 300;
    align-items: center;
    border: var(--border);
       
      }

      &.signup_input {
        input {

          background-color: #fff;

        }

      }
    }

    .field_error {
      position: absolute;
      font-size: 12px;
      color: red;
      bottom: -21px;
    }

    &.title_action_panel {
      flex-direction: row;
      gap: 10px;
    }
  }

  .form_group_title {
    height: 40px;
    display: flex;
    gap: 16px;
    align-items: center;
    border-bottom: var(--border);
    margin: 16px 0;
  }

  .multi_field_container {
    display: flex;
    gap: 16px;
  }
  .additional_setting_toggle_child{
    background-color: #fff3ee;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
}

/* Message popover */
.message_popover_panel {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  transition: all 0.5s ease-out;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99;

  .popover_body {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    .tab_container {
      flex: 1;
      overflow: auto;
      padding: 16px;

      .nav-tabs {
        .nav-item {
          position: relative;

          .nav-link {
            color: #6e6a93 !important;
            list-style: none;
            display: flex;
            align-items: center;
            border-bottom: 5px solid transparent !important;
            border-bottom-color: transparent;
            height: 100% !important;
            border: none;
            padding: 5px 0px;
            margin-right: 30px;

            &.active {
              border-color: var(--orange) !important;
            }

            &:hover {
              color: #6e6a93 !important;
            }
          }
        }
      }
    }

    .content_panel {
      flex: 1;
      overflow: auto;
      padding: 16px;

      &.more_space {
        padding: 70px;
      }
    }

    header,
    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      width: 100%;
      min-height: var(--header-height);
      /* border-bottom:  var(--border); */
      box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
      border-bottom: var(--border);

      .close_panel {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
      }

      .message_close_panel {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
      }
    }

    footer {
      border-bottom: 0;
      border-top: var(--border);
      justify-content: flex-end;
      gap: 8px;
      min-height: 72px;
    }
  }

  &.show {
    right: 0;
    transition: all 0.5s ease-out;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

/* Message popover */
.message_compose_panel {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  transition: all 0.5s ease-out;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99;

  .popover_body {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    .tab_container {
      flex: 1;
      overflow: auto;
      padding: 16px;
    }

    .content_panel {
      flex: 1;
      overflow: auto;
      padding: 16px;

      &.more_space {
        padding: 70px;
      }
    }

    header,
    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      width: 100%;
      min-height: var(--header-height);
      /* border-bottom:  var(--border); */
      box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
      border-bottom: var(--border);

      .close_panel {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
      }

      .message_close_panel {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
      }
    }

    footer {
      border-bottom: 0;
      border-top: var(--border);
      justify-content: flex-end;
      gap: 8px;
      min-height: 72px;
    }
  }

  &.show {
    right: 0;
    transition: all 0.5s ease-out;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

/* Manage Website Page */
.content_panel {
  .block_elements {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    div {
      background: #f0f0f0;
      text-align: center;

      padding: 10px 30px;

      i {
        font-size: 40px;
        margin-bottom: 10px;
      }

      span {
        display: block;
      }
    }
  }

  .block_title {
    margin: 20px 0;
    max-width: 600px;

    input {
      margin-block: 8px;

      padding: 24px 10px;
    }

    .btn-box {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .upload_img {
    margin: 10px 0;

    .border_dotted {
      border: 3px dashed #ccc;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-radius: 18px;
    }
  }
}

/* Toggle Switch btn */
.check-box {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    position: relative;
    appearance: none;
    width: 60px;
    height: 28px;
    background: #ccc;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.4s;
  }

  input {
    &:checked[type='checkbox'] {
      background-color: var(--orange);
    }

    &:checked[type='checkbox']::after {
      left: 39px;
    }
  }

  input[type='checkbox']::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    top: 6px;
    left: 5px;
    background: #fff;
    border-radius: 50%;
    transform: scale(1.1);
    transition: 0.4s;
  }

  &.small {
    input[type='checkbox'] {
      width: 42px;
      height: 20px;

      &:after {
        top: 4px;
        width: 12px;
        height: 12px;
      }
    }

    input:checked[type='checkbox']::after {
      left: 26px;
    }
  }
}

/* Tooltip */
.tool {
  cursor: help;
  position: relative;
  font-weight: normal;

  &::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #3e474f transparent transparent transparent;
    bottom: 115%;
    content: '';
    margin-left: -0.5em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26), opacity 0.65s 0.5s;
    transform: scale(0.6) translateY(-90%);
  }

  &::after {
    background: #3e474f;
    border-radius: 0.25em;
    bottom: 180%;
    color: #edeff0;
    content: attr(data-tip);
    margin-left: -8.75em;
    padding: 1em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
    transform: scale(0.6) translateY(50%);
    width: 17.5em;
  }
}

.tool::before,
.tool::after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -100;
}

.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
}

.tool:hover::before,
.tool:focus::before {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}

.tool:hover::after,
.tool:focus::after {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}

/* Radio checked btn */
.rect_check-box {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    background: #ccc;

    cursor: pointer;
    transition: 0.4s;
  }

  input {
    &:checked[type='checkbox'] {
      background-color: var(--orange);
    }
  }

  input[type='checkbox']::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    top: 6px;
    left: 5px;
    background: #fff;
    border-radius: 50%;
    transform: scale(1.1);
    transition: 0.4s;
  }
}

/* progress-css-bar */
.progress-css-bar {
  width: 400px;
  height: 12px;
  background-color: #ffd18c;
  position: relative;
  display: inline-flex;
  overflow: hidden;
  border-radius: 8px;

  .bar {
    position: absolute;
    left: 0;
    height: 100%;
    background-color: #ff9900;
    transition-property: width;
    transition-duration: 2s;
    transition-timing-function: linear;
    border-radius: 40px;
  }
}

.check-group {
  &:hover {
    input[type='checkbox'].ckb+label::before {
      border: 1px solid var(--orange);
    }
  }

  border-bottom: 1px solid #f4f4f7;

  input[type='checkbox'].ckb {
    z-index: -1;
    display: none;
  }

  input[type='checkbox'].ckb+label {
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: all 0.3s;
    color: #626981;
    width: 100%;
  }

  input[type='checkbox'].ckb:checked+label::before {
    transition: all 0.3s;
    background-color: var(--orange);
    font-family: 'Font Awesome 5 Pro';
    content: '\f00c';
    position: relative;
    top: 0px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  input[type='checkbox'].ckb+label::before {
    transition: all 0.3s;
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: none;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    /* background: #000; */
    background-position: center center;
    background-size: 50% 50%;
    background: transparent;
    border: 1px solid #ccc;
  }

  input[type='checkbox']:checked+label:after {
    display: none;
  }

  [type='checkbox'] {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

/* Title Toggle Panel */
.title_toggle_panel {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 0;
  padding-top: 0;
  border-bottom: 1px solid #cfcdcd;

  .box_size {
    flex: 1;
    max-width: 250px;

    font-size: 16px;
    font-weight: 300;
  }
}

/* Loading Panel */
.loader_container {
  width: 100vw;
  height: calc(100vh - 56px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);

  p {
    color: #fff;
    font-weight: 300;
  }
}

// input field with search icon
.input_with_search {
  display: flex;
  width: 100%;
  height: 36px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 300;
  align-items: center;
  border: var(--border);
  overflow: hidden;

  input {
    border: 0;
    height: 100%;
    flex: 1;
  }

  .icon_panel {
    width: 36px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8fa;
    border-left: var(--border);
    cursor: pointer;
    font-weight: bold;
  }
}

//
.Checkbox {
  width: 24px;
  height: 24px;
  position: relative;

  >* {
    position: absolute;
  }

  &-visible {
    border: 1px solid gray;
    width: 24px;
    height: 24px;
    margin: 2px;
    background: #fff;
    border: var(--border);
    border-radius: 3px;
  }

  >input {
    z-index: 1;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translatex(-50%) translatey(-50%);
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  >input:checked+.Checkbox-visible {
    background: var(--orange);
    border-color: var(--orange);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMTQnIHZpZXdCb3g9JzAgMCAxNCAxNCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48dGl0bGU+d2hpdGU8L3RpdGxlPjxwYXRoIGQ9J001LjM3OCAxMS4zN0wyIDcuNTlsMS40MzgtMS4yODZMNS4zNzQgOC40N2w1LjE4NS01Ljg0TDEyIDMuOTFsLTYuNjIyIDcuNDYnIGZpbGw9JyNmZmYnLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
  }

  >input:hover+.Checkbox-visible {
    border-color: var(--orange);
  }

  >input:hover:checked+.Checkbox-visible {
    border-color: var(--orange);
  }
}

/* Hide the default radio button */
input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Style the custom radio button */
input[type='radio']+label {
  position: relative;
  padding-left: 30px;
  /* Adjust the spacing as needed */
  cursor: pointer;
}

/* Style the custom indicator dot */
input[type='radio']+label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  /* Adjust the size as needed */
  height: 18px;
  /* Adjust the size as needed */
  border: 2px solid #ccc;
  /* Border color */
  border-radius: 50%;
  /* Make it circular */
}

/* Style the custom indicator dot when selected */
input[type='radio']:checked+label::before {
  background-color:  var(--orange);
  /* Change to desired color */
}

// Normal serach box

.normal_search_panel {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 38px;
  min-height: 38px;
  border: var(--border);
  border-radius: 3px;

  .search_field {
    flex: 1;
    height: initial;
    border: 0;
    border-right: var(--border);
    padding: 0 8px;
    border-radius: 0;
    background: border-box;

    &:focus {
      outline: none;

      +.btn {
        background-color:  var(--orange);
        color: #fff;
      }
    }
  }

  .btn {
    width: 40px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: unset;
    border-radius: 0;
  }
}

// css grid image gallery
.gallery {
  display: grid;
  width: 100%;
  max-height: 400px;

  overflow: hidden;
  overflow-y: auto;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}

.gallery__item {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &:nth-child(1) {
    grid-column: span 2;
    grid-row: span 2;
  }

  &:nth-child(2n) {
    grid-column: span 1;
  }

  &:nth-child(4n) {
    grid-column: span 2;
    grid-row: span 2;
  }

  .btn {
    position: absolute;
    top: 6px;
    right: 6px;
    background-color:  var(--orange);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #fff;
    min-width: unset;
    font-size: 12px;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    object-position: top;
  }
}

.disable{
  pointer-events: none;
  opacity:0.6
}

// tooltip design
.info_tooltip_panel{
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 99999;
  .fa{
    color: #444;
    font-size: 20px;
  }
  .react-tooltip{
    font-size: 14px;
  }

}

.remove-br-mobile{
  @media (max-width: 767px) {
    br{
      display: none
    }
  }
}