.rsvp_grid {
  .ag-header-cell::before,
  .ag-header-group-cell:not(.ag-header-span-height.ag-header-group-cell-no-group)::before {
    background-color: transparent;
  }

  .grid_box {
    height: calc(100vh - 155px);
  }
  .rsvp_table_grid {
    padding: 20px;
    border-radius: 8px;

    @media (min-width: 992px) and (max-width: 1110px) {
      .rsvp_events {
        max-width: 220px !important;
      }

      .rsvp_events_count {
        .rsvp_count_list {
          h4 {
            flex: 1;
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .rsvp_table_guest-details {
    .rsvp_heading {
      font-family: 'Arima', system-ui;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      h2 {
        color: #090909;
        font-size: 24px;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;

        h2 {
          font-size: 18px;
        }
      }

      .rsvp-dropdown {
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .ag-grid-header {
      justify-content: center;
      background-color: #f6f8fa;
    }

    .ag-header-row-column {
      background-color: #f6f8fa;
      color: #1f2328 !important;

      .ag-header-cell-comp-wrapper {
        .ag-cell-label-container {
          .ag-header-cell-label {
            justify-content: space-between;

            .ag-sort-indicator-container {
              color: #fff !important;

              .ag-icon {
                &::after {
                  color: #fff !important;
                }

                &::before {
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }

    .invitation_cell {
      background-color: #e3cdb8;
      font-weight: 600;
      color: #191919;
    }

    .event_cell {
      // background-color: #f9f5f1;
    }

    .rsvp_cell {
      font-weight: 600;
      color: #191919;
    }

    .ag-row {
      border-bottom: 1px solid #ccc !important;

      .ag-cell {
        // border: 1px solid #88837F !important;
        border: 0 !important;
        display: flex;
        align-items: center;
        font-family: Arima, system-ui;
        font-size: 13px;
        font-weight: 600;
        color: #1c1c1c;
        border-right: 1px solid #d9d9d9 !important;
      }
    }

    .ag-header-cell,
    .ag-header-group-cell {
      border-right: 1px solid #d9d9d9;
      font-family: Arima, system-ui;
      font-size: 14px;
      font-weight: 600;
      color: #000;
      .ag-header-cell-text {
        font-family: Arima, system-ui;
        font-size: 13px;

        color: #000;
      }
      .ag-header-group-text {
        font-weight: 600;
      }
    }
  }

  .rag-red-outer .rag-element {
    color: #f35756;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  .rag-green-outer .rag-element {
    color: #1ead83;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  .rag-green-outer .rsvp_yes {
    color: #fff;
    background-color: #1ead83;
    border-radius: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 12px;
    font-weight: 600;
  }
  .rag-green-outer .rsvp_no {
    color: #fff;
    background-color: #f35756;
    border-radius: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 12px;
    font-weight: 600;
  }
}

.rsvp_events_list {
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;

  h2 {
    font-size: 20px;
  }

  .export_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }

  .img_loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.rsvp_status {
  padding-top: 20px;

  h2 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .rsvp_response_wrapper {
    display: flex;
    padding: 15px;
    background: #f5f5f7;
    justify-content: space-between;
    border-radius: 15px;

    .rsvp_response_count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      span {
        width: 40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        font-size: 18px;
        align-items: center;
        justify-content: center;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.rsvp_guest_list {
  margin-top: 20px;
  margin-top: 20px;

  .accordion {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .rsvp_response {
      flex-direction: column;
      display: flex;
      border: none;

      .accordion-button {
        cursor: pointer !important;
        display: flex;

        &::after {
          display: none;
        }

        &::before {
          flex-shrink: 0;

          margin-left: auto;
          content: '\f078';
          font-family: 'Font Awesome 5 Pro';
          display: block;
          font-weight: 300;
          background-image: none;
          font-size: 15px;
          height: 20px;
          width: 20px;
          transition: transform 0.2s ease-in-out;
          color: var(--textGlobalColor);
        }

        &:not(.collapsed) {
          &::before {
            content: '\f077';
            font-family: 'Font Awesome 5 Pro';
            display: block;
            font-weight: 300;
            background-image: none;

            font-size: 15px;
            color: var(--textGlobalColor);
            height: 20px;
            width: 20px;
          }
        }

        &:focus {
          box-shadow: none;
        }

        .rsvp_response_header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          cursor: pointer !important;

          span {
            font-size: 18px;
            color: var(--textGlobalColor);
          }
        }
      }

      .accordion-body {
        display: flex;
        gap: 20px;
        flex-direction: column;
        gap: 0px;

        .rsvp_guest_names_response {
          padding: 0 30px;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          border-bottom: 1px solid #eee;

          .rsvp_guest_names {
            width: 100%;
          }

          .rsvp_guest_email {
            width: 100%;
          }

          .rsvp_guest_phone {
            width: 100%;
            justify-content: flex-end;
            display: flex;
          }

          @media (max-width: 520px) {
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            border-bottom: 1px solid #eee;

            .rsvp_guest_phone {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}
