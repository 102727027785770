/**
 * Common Mixins
 *
 * This file contains a set of common mixins that can be used across various projects.
 *
 * Mixins Included:
 * - clearfix: Clearfix hack for floating elements.
 * - transition: Handle CSS transitions with vendor prefixes.
 * - box-shadow: Apply box shadow with vendor prefixes.
 * - border-radius: Apply border radius with vendor prefixes.
 * - responsive-font-size: Responsive typography for different screen sizes.
 * - flex-center: Apply display flex, justify content center and align-items center 
 *
 * Usage:
 * Import this file into your main SCSS file to use these mixins.
 * ```scss
 * @import 'path/to/_mixins';
 * ```
 *
 * @version: 1.0
 * @author: Ganesh Ughade
 */

// Clearfix hack
@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

// CSS Transitions with vendor prefixes
@mixin transition($properties...) {
    -webkit-transition: $properties;
    -moz-transition: $properties;
    -o-transition: $properties;
    transition: $properties;
}

// Box Shadow with vendor prefixes
@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

// text Shadow with vendor prefixes
@mixin text-shadow($shadow...) {  
    text-shadow: $shadow;
}


// Border Radius with vendor prefixes
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

// Background Properties
@mixin background-properties($image-url, $color) {
    background-image: url($image-url);
    background-repeat: repeat;
    background-position: left center;
    background-size: contain;
    background-color: $color;
}

// Responsive Font Size
@mixin responsive-font-size($size) {
    font-size: $size;

    @media (max-width: 1200px) {
        font-size: $size * 0.9;
    }

    @media (max-width: 992px) {
        font-size: $size * 0.8;
    }

    @media (max-width: 768px) {
        font-size: $size * 0.7;
    }

    @media (max-width: 576px) {
        font-size: $size * 0.6;
    }
}

/**
 * Display Mixins
 * 
 *
 * @version: 1.0
 * @author: Ganesh Ughade
 */

// Flex Center
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-direction($view, $yAxis, $xAxis, $flexDirection) {
    display: $view;
    justify-content: $yAxis;
    align-items: $xAxis;
    flex-direction: $flexDirection;
}

