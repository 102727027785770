@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

// .contact_us_section {
//   padding: 24px;

//   .contact_info_container {
//     @include flex-direction(flex, flex-start, center, column-reverse);
//     gap: 16px;
//     .map {
//       background-color: $white;
//       padding: var(--s-16);
//       border-radius: var(--s-8);
//       width: 100%;
//       max-width: 500px;
//       border: 1px solid #ccc;
//       @include respond-to('tablet-portrait') {
//         display: block;
//       }
//       .contact_form_container {
//         a,
//         button {
//           max-width: fit-content;
//         }
//       }
//     }
//     @include respond-to('tablet-portrait') {
//       flex-direction: row;
//     }
//   }
//   .text_container {
//     @include flex-direction(flex, flex-start, center, column);
//     gap: 10px;
//     width: 100%;
//     max-width: 500px;
//     margin: 0 auto;
//     p {
//       line-height: unset;
//       text-align: center;
//     }
//   }
// }

.new_custom_form {
  @include flex-direction(flex, flex-start, flex-start, column);
  padding-top: 24px;
  gap: 0px;
  img {
    width: 100%;
    max-width: 600px;
    height: auto;
    display: none;
    @include respond-to('tablet-portrait') {
      display: block;
    }
  }
  .custom_form_container {
    p {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      border-bottom: 1px solid #c9c4c4;
      padding-bottom: 16px;
    }
    textarea,
    input {
      padding: 6px 8px;
      &:read-only {
        background-color: #f3f2f1;
        border-color: #c8c2c2;
      }
      &::placeholder {
        font-size: 14px;
      }
    }
    label {
      color: #36393b;
      font-weight: 600;
      font-size: 15px;
    }
    .multi_field_container {
      .field_container {
        width: 100% !important;
      }
    }
  }
  @include respond-to('tablet-portrait') {
    @include flex-direction(flex, flex-start, flex-start, row);
    gap: 16px;
  }
}
