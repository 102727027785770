/* Dashboard Page */

.item_disabled {
  opacity: 0.5;
  pointer-events: none;
}

.vitePlan_dashboard {
  background-color: #fbf7f4;
  width: 100%;
  padding: 50px 0;

  .container {
    width: 100%;
    max-width: 1600px;

    .right_panel_container {
      width: 48%;

      .welcome_panel {
        background-color: #fff;
        width: 100%;
        padding: 24px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
        border-radius: 5px;

        .event_action {
          .action_btn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #f05537;
            display: inline-flex;
            color: #fff;
            align-items: center;
            justify-content: center;
          }
        }

        .report_panel {
          margin-top: 32px;

          .weekly_report {
            display: flex;
            overflow: hidden;

            width: 100%;
            height: 200px;
            /* padding: 24px; */
            border-radius: 5px;

            background-position: right bottom;
            background-size: 100px auto;
            background-repeat: no-repeat;
            background-color: #b7e0ed;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;

            .btn {
              max-width: fit-content;
              border-color: #000 !important;
              color: #000;
            }

            .video_panel {
              width: 250px;
              background-color: #fff;
              clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
              position: relative;
              right: -62px;

              img {
                width: 100%;
              }
            }

            @media (max-width: 767px) {
              height: auto;

              .video_panel {
                display: none;
              }

              .h-100 {
                gap: 16px;
              }

              background-image: url('../assets/img/floral.png');
            }
          }

          .status {
            height: 240px;
            background-color: #ffdacc;
            border-radius: 5px;
            padding: 24px;
            flex: 1 1;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
            background-image: url('../assets/img/sun-flower.png');
            background-position: center right;
            background-size: 217px auto;
            background-repeat: no-repeat;
            /* background-blend-mode: luminosity; */
            cursor: pointer;
            text-decoration: none;
            color: inherit;
          }

          @media (max-width: 767px) {
            .wedding_style {
              flex-direction: column;
            }
          }
        }
      }
    }

    .recent_activity_panel {
      display: flex;
      flex-direction: column;
      width: 50%;
    }

    .checklist {
      margin-top: 24px;

      .checklist_table_container {
        background-color: #fff;
        width: 100%;
        min-width: 500px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
        border-radius: 5px;
        padding: 16px;

        .checklist_row {
          display: flex;
          padding: 12px 0;
          padding-bottom: 0;

          .title_mediumLight {
            font-size: 20px;
          }

          .small_text {
            font-size: 13px;
          }

          .check-group {
            width: 32px;
            height: 32px;
            border-bottom: 0;
            position: relative;
            top: 7px;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }

  .tag_line {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
  }

  .activity_listing {
    padding-top: 24px;
    padding-bottom: 24px;
    flex-wrap: wrap;

    .activity_item {
      padding: 24px;
      border-radius: 5px;
      border: 1px solid #ccc;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
      flex-basis: 48%;
      text-decoration: none;
      color: inherit;
      overflow: hidden;

      p {
        margin-bottom: 0;
      }

      .small_title {
        font-size: 13px;
      }

      .btn {
        max-width: fit-content;
        min-width: 150px;
      }

      &:hover {
        background-color: #fff;
        cursor: pointer;
      }
    }
  }

  .feature_item_listing {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .feature_items {
      flex-basis: 48%;
      text-decoration: none;
      color: inherit;

      .item_icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #000;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 3px;
        background-color: #ccc;
      }

      .title_mediumLight {
        font-size: 20px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 1024px) {
    .right_panel_container {
      width: 100% !important;

      .activity_listing {
        .activity_item {
          flex-basis: 100% !important;
        }
      }
    }

    .recent_activity_panel {
      width: 100% !important;
      margin-top: 40px;

      .activity_listing {
        .activity_item {
          flex-basis: 100% !important;
        }
      }

      .box_panel {
        .feature_item_listing {
          .feature_items {
            flex-basis: 100% !important;
          }
        }
      }
    }

    .checklist {
      .checklist_table_container {
        min-width: 100% !important;
      }
    }
  }

  @media (min-width: 1025px) and (max-width: 1343px) {
    .right_panel_container {
      width: 100% !important;

      .activity_listing {
        .activity_item {
          flex-basis: 100% !important;
        }
      }
    }

    .recent_activity_panel {
      width: 100% !important;
      margin-top: 40px;

      .activity_listing {
        .activity_item {
          flex-basis: 45% !important;
        }
      }

      .box_panel {
        .feature_item_listing {
          .feature_items {
            flex-basis: 45% !important;
          }
        }
      }
    }

    .checklist {
      .checklist_table_container {
        min-width: 100% !important;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 20px 0;
  }
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
  }
}

.coming_soon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(251, 247, 244, 0.9);
  z-index: 9;
  pointer-events: none;
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    /* border-left: 2px solid #CCC; */
  }

  &-item {
    position: relative;
    counter-increment: list;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
    padding: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      color: var(--orange);
      font-size: 13px;
      letter-spacing: 1px;
      max-width: 54px;
      display: inline-flex;
      /* max-width: 100px; */
      height: 32px;
      border-radius: 3px;
      border: 1px solid var(--orange);
      text-decoration: none;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
    }

    p {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      left: -30px;
      height: 100%;
      width: 10px;
    }

    &::after {
      content: '';
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: -42px;
      width: 24px;
      height: 24px;
      border: 2px solid #3f9544;
      border-radius: 50%;
      background-color: #fff;
    }

    &.is-done {
      &::before {
        border-left: 1px solid #3f9544;
      }

      &::after {
        content: '✔';
        font-size: 15px;
        color: #fff;
        text-align: center;
        border: 2px solid #3f9544;
        background-color: #3f9544;
      }

      &:last-child {
        &::before {
          border-left: 0;
        }
      }
    }

    /*
    &.current {
      &::before {
        border-left: 1px solid green;
      }

      &::after {
        content: counter(list);
        padding-top: 1px;
        width: 19px;
        height: 18px;
        top: -4px;
        left: -40px;
        font-size: 14px;
        text-align: center;
        color: green;
        border: 2px solid green;
        background-color: white;
      }
    } */
  }

  title {
    display: block;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
    padding-left: 0 !important;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    .StepProgress-item {
      min-width: 250px;
      width: 100%;
      padding: 15px 20px 20px 30px;

      title {
        font-size: 20px;
        line-height: 28px;
      }

      &::before {
        display: none;
      }
      &::after {
        top: 20px;
        left: 8px;
        width: 16px;
        height: 16px;
        font-size: 12px;
      }
    }
  }
}
