/**
 * Video Card Styles
 *
 * Defines styles for video card components used in the application.
 * These styles include card layout, box shadow, border radius, and
 * various content and button styles.
 *
 * Dependencies:
 * - Utilizes mixins for flexbox centering and applying box shadows.
 * - Inherits colors from the default theme for backgrounds, borders,
 *   text, and button styling.
 *
 * Components:
 * - .video-card-container: Container for video cards, utilizes flexbox
 *   to center and wrap cards.
 * - .video-card: Individual video card style, includes box shadow,
 *   border radius, and flexbox centering for consistent layout.
 * - .badge: Styling for badges displayed on video cards, including
 *   background color, text color, and border radius.
 * - .image-container: Container for video card images, ensuring images
 *   maintain full width and auto height within their containers.
 * - .content: Styling for text content within video cards, including
 *   padding, margin, and font size adjustments for headings and paragraphs.
 * - .price-container: Container for displaying prices within video cards,
 *   with styles for font size and color adjustments based on the primary
 *   theme color.
 * - .select-button: Button styling for selection actions, with background,
 *   text color, padding, and hover effect adjustments based on the primary
 *   theme color.
 *
 * @author: Ganesh Ughade
 * @version: 1.0
 */

  /* Bootstrap scss */
  @use '~bootstrap/scss/bootstrap';

// Import mixins and colors
@use '../abstracts/mixins' as *;
@use '../themes/default/colors' as *;
@use '../abstracts/variables' as *;

// Styles for .video-card-container
.video-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--s-12);
}

// Styles for .video-card
.video-card {
    @include box-shadow(0 2px 4px rgba($black, 0.1));
    @include border-radius(0.5rem);

    position: relative;
    background: $white;
    border: 1px solid $light-gray;
    overflow: hidden;
    flex-direction: column;
    padding: var(--s-8);

    // Styles for .badge within .video-card
    .badge {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        left: 0px;
        top: 31px;
        height: 28px;
        padding: 0 12px;
        border-radius: 0 3px 3px 0;
        min-width: 80px;
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
        line-height: unset;
        font-size: 12px;
        background-color: #d70c0c;
    }

    // Styles for .image-container within .video-card
    .image-container {
        width: 100%; // Ensure image container spans full width

        img {
            width: 100%; // Ensure image spans full width
            height: auto; // Maintain aspect ratio for images
            display: block; // Remove extra space below images
        }
    }

    // Styles for .content within .video-card
    .content {
        padding: var(--s-12) 0 var(--s-8) 0; // Padding around content within the card
    }

    // Styles for .price-container within .video-card
    .price-container {
        position: relative;
        width: 100%; 
        justify-content: flex-start;
        gap: var(--s-8);

        span {
           
            &.offer_price {
                color: $primary-color; // Color for discounted price
            }

            &.real_price {
                color: $secondary-color; // Color for discounted price
            }
        }
    }

    // Styles for .select-button within .video-card
    .select-button {
        background: $primary-color; // Background color for the button
        color: $white; // Text color for the button
        border: none; // Remove border from the button
        padding: 0.75rem 1.5rem; // Padding inside the button
        margin-bottom: 1rem; // Bottom margin for spacing

        &:hover {
            background: darken($primary-color, 10%); // Darken background on hover
        }
    }
}


// nornal card design
.vt_card{
    @extend .card !optional;
    @include box-shadow(0 0px 6px rgba($black, 0.2));
    @include border-radius(0.5rem);

    width: 100% !important;
    flex-direction: row;
    overflow: hidden;
    border: 0;
    img{
        width: max(50px, 30%);
        object-fit: cover;
    }
    .card-body {
        @include flex-direction(flex, flex-start, flex-start, column);

        gap: var(--s-8);
        flex: 1;
        .card-body_inner{
            @include flex-direction(flex, flex-start, flex-start, column);
            .btn_container{
                width: 100%;
                text-align: left;
            }
            a{
                border: 0;
            }
        }
    }
}