@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

$gust-animattion-img-1: '../../assets/img/guest-banner/guest-1.png';
$gust-animattion-img-2: '../../assets/img/guest-banner/guest-2.png';
$gust-animattion-img-3: '../../assets/img/guest-banner/guest-3.png';
$gust-animattion-img-4: '../../assets/img/guest-banner/guest-1.png';

$sv-date-and-invt-bg-1:'../../assets/img/save-date-1.png';
$sv-date-and-invt-bg-2:'../../assets/img/save-date-2.png';
$sv-date-and-invt-bg-3:'../../assets/img/save-date-3.png';

$vendor-banner-bg-1:'../../assets/img/vendor-banner/vendor-1.png';
$vendor-banner-bg-2:'../../assets/img/vendor-banner/vendor-2.png';
$vendor-banner-bg-3:'../../assets/img/vendor-banner/vendor-3.png';



.guest_banner_animation,
.sv-date-and-invt-banner,
.vendor_banner_animation {
    background-size: 100% auto; 
    background-position: center bottom;
    background-repeat: no-repeat; 
}
    
  
.guest_banner_animation {
    animation: BKG-swing-guest 8s infinite !important;
    background-image: url($gust-animattion-img-1);    
    @keyframes BKG-swing-guest {
    33% {background-image:  url($gust-animattion-img-2);}
    66% {background-image:  url($gust-animattion-img-3);}
    100% {background-image:  url($gust-animattion-img-4);}
    }
}
  
  .sv-date-and-invt-banner {   
    background-image: url($sv-date-and-invt-bg-1);    
    animation: BKG-swing 8s infinite;  
    @keyframes BKG-swing {
      33% {background-image: url($sv-date-and-invt-bg-2);}
      66% {background-image: url($sv-date-and-invt-bg-3);}
      100% {background-image: url($sv-date-and-invt-bg-1);}
    }
  }

  .vendor_banner_animation {
    animation: BKG-swing-vendor 8s infinite !important;
    background-image: url($vendor-banner-bg-1);
    background-position: right center;
    @keyframes BKG-swing-vendor {
      33% {background-image: url($vendor-banner-bg-2);}
      66% {background-image: url($vendor-banner-bg-3);}
      100% {background-image: url($vendor-banner-bg-1);}
    }
  }