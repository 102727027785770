@use '../../abstracts/variables' as *;
@use '../../themes/default/variables' as *;
@use '../../abstracts/mixins' as *;
@use '../../themes/default/colors' as *;
@use '../../abstracts/breakpoints' as *;
@use '../../components/buttons' as *;

.preview_cart {
    @include box-shadow(0 2px 4px rgba($black, 0.1));
    @include border-radius(0.5rem);

    width: 100% !important;
    padding: var(--s-16);
    flex-direction: row;
    overflow: hidden;
    border: 0;
    margin-top: var(--s-16);
    .video_type_details{
        @include flex-direction(inline-flex, space-between, flex-start, row);
        border-bottom: 1px dashed #444;

        width: 100%;
        padding-bottom: var(--s-12);
        margin-bottom: var(--s-12);
        gap: var(--s-8);
        &:last-child {
            border: 0;
            margin: 0;
            padding: 0;
        }
    }
    .coupon_box{
        @include flex-direction(inline-flex, flex-start, center, row);

        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
        overflow: hidden;
        input{
            border: 0;
        }
        .btn{
            border-radius: 0;
        }
        &.payment_option{
            flex-direction: column;
            align-items: flex-start;
            gap: var(--s-12);
            .single_payment_box{
                width: 100%;
                border-radius: 3px;
                overflow: hidden;
            }
        }
    }
    &.fix_width {
        max-width: 600px;
    }
}

.large_view{
    &.cart_view{
        .container{
            width: 100%;
            max-width: 930px;
            margin: 0 auto;
        }
    }
}
