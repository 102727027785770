.wordage_container {
  width: 100%;
  padding: 72px 0;
  flex: 1;

  .box-align {
    &.left {
      width: 100%;
      max-width: 300px;
      min-width: 300px;
    }

    &.right {
      border-left: 1px solid #ccc;
      min-width: 700px;
      max-width: 700px;
      padding-left: 48px;
      position: relative;

      .loader_container {
        background-color: transparent;
        width: 100%;
        height: 100%;

        p {
          color: #000;
        }
      }

      .message_text {
        font-size: 16px;
        font-weight: 100;
        text-align: justify;
        white-space: pre-line;
      }
    }
  }

  .form_container {
    width: 100%;
    padding: 32px 0;

    label {
      font-size: 13px;
      display: block;
      padding-bottom: 4px;
    }
  }

  @media (max-width:1024px) {
    padding: 24px 0;
    position: relative;

    .container {
      flex-direction: column;
      align-items: center;

      .box-align.right {
        position: unset;
        width: 100%;
        max-width: unset;
        min-width: unset;
        border-left: 0;
        padding: 0;
      }
    }

    .message_text {
      .loader_container {
        background-color: rgba(255, 255, 255, 0.8) !important;
        top: 0;
      }
    }
  }
}
