.wedding_section {
padding: 50px 0;
  img {
    width: auto;
    max-width: 600px
  }

  .content-text {
    max-width: 370px;
}

.pd-50 {
  padding: 60px 0;
}
}