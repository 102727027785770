#master_record_popover_panel {
  .pop-up_pannel {
    height: 100%;

    .add-to-page {
      height: 100%;
    }
  }

  header {
    display: inline-block;

    .header_div {
      display: flex;
      align-items: center;

      padding: 20px 16px;
      width: 100%;
      min-height: var(--header-height);
      /* border-bottom: var(--border); */
      box-shadow: none;
      border-bottom: none;

      h1 {
        font-size: 24px;
        display: flex;
        align-items: center;
        width: 100%;

        span {
          i {
            margin-right: 10px;
          }
        }
      }

      .left-view {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .button_view {
          margin-right: 40px;
        }
      }
    }

    .tab_view {
      display: flex;
      justify-content: center;

      .nav-pills {
        .nav-item {
          .nav-link {
            padding: 0;
            padding-bottom: 10px;
            color: var(--textGlobalColor);
            margin-right: 30px;

            &.active {
              color: var(--textGlobalColor);
              background-color: transparent;
              border-bottom: 5px solid var(--orange);
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  .master_panel {
    padding: 32px 90px;
    background-color: #f4f4f7;
    height: 100%;

    .table_grid {
      border: 1px solid #ccc;
      border-radius: 3px;
      overflow: hidden;
      background-color: #fff;

      table {
        width: 100%;
        table-layout: fixed;
      }

      .tbl-header {
        background-color: #f4f4f7;
        border-bottom: 1px solid #ccc;
      }

      .tbl-content {
        overflow-x: auto;
        margin-top: 0px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        height: 500px;

        .icon_action {
          display: block;

          i {
            font-size: 14px;
            margin: 5px;
          }
        }

        tr:nth-child(even) {
          background-color: #f4f4f7;
        }

        tr {
          &:hover {
            .action_column {
              .fa-trash {
                opacity: 1;
              }
            }
          }
        }

        .action_column {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          gap: 16px;

          i {
            font-size: 18px;
            font-weight: 200;
          }

          .fa-ellipsis-v {
            font-size: 28px;
          }

          .fa-trash {
            opacity: 0;
          }

          .more_menu {
            .popup_menu {
              display: flex;
              flex-direction: column;
              position: absolute;
              width: 160px;
              background-color: #fff;
              border-radius: 3px;
              box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
              border: 1px solid #ccc;
              right: 0;
              top: 0;
              opacity: 0;
              z-index: -1;

              a {
                display: flex;
                align-items: center;
                height: 40px;
                padding: 12px;
                border-bottom: 1px solid #ccc;
                color: var(--purple);
                box-sizing: border-box;
                text-decoration: none;

                &:hover {
                  background-color: #f4f4f7;
                }

                &:last-child {
                  border-bottom: 0;
                }
              }
            }

            &:hover {
              .popup_menu {
                opacity: 1;
                z-index: 9;
              }
            }
          }
        }

        .dropdown-box {
          position: relative;
          font-size: 14px;
          color: #333;

          .dropdown-list {
            padding: 15px;
            background: #fff;
            position: absolute;
            top: 30px;
            left: 2px;
            right: 2px;
            box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15);
            transform-origin: 50% 0;
            transform: scale(1, 0);
            transition: transform 0.15s ease-in-out 0.15s;
            max-height: 66vh;
            z-index: 99;
          }

          .dropdown-option {
            display: block;
            padding: 12px 0px;
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
          }

          .dropdown-label {
            display: block;
            height: 30px;
            background: transparent;
            border: none;
            padding: 6px 12px;
            line-height: 1;
            cursor: pointer;
            font-size: 15px;

            &:before {
              content: '';
              position: absolute;
              top: 45%;
              right: 15px;
              width: 8px;
              height: 8px;
              transform: translateY(-50%) rotate(45deg);
              border-right: 2px solid #666;
              border-right-color: rgb(102, 102, 102);
              border-bottom: 2px solid #666;
              border-bottom-color: rgb(102, 102, 102);
              transition: 0.2s ease;
            }
          }

          &.on {
            .dropdown-list {
              transform: scale(1, 1);
              transition-delay: 0s;

              .dropdown-option {
                opacity: 1;
              }
            }

            .dropdown-label:before {
              content: '';
              position: absolute;
              top: 45%;
              right: 15px;
              width: 8px;
              height: 8px;
              transform: translateY(-50%) rotate(-135deg);
              border-right: 2px solid #666;
              border-right-color: rgb(102, 102, 102);
              border-bottom: 2px solid #666;
              border-bottom-color: rgb(102, 102, 102);
              transition: 0.2s ease;
            }
          }


        }
      }

      th {
        padding: 20px 15px;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
      }

      td {
        padding: 15px;
        text-align: left;
        vertical-align: middle;
        font-weight: 300;
        font-size: 15px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);

        .wedding_day {
          display: flex;
          flex-direction: column;
          gap: 8px;

          h1 {
            font-size: 20px;
          }

          h2 {
            font-size: 14px;
          }

          p {
            font-size: 14px;
          }

          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}