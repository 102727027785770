  /* Bootstrap scss */
  @use '~bootstrap/scss/bootstrap';

@use '../abstracts/variables' as *;
@use '../themes/default/variables' as *;
@use '../abstracts/mixins' as *;
@use '../themes/default/colors' as *;
@use '../abstracts/breakpoints' as *;
@use '../components/buttons' as *;


.shopping_cart_container{
    .accordion{
        .accordion-button{
            background-color: lighten($gray, 60%);
            gap:var(--s-8);
            font-size: var(--heading-6);
            &:focus,&:active {
                outline: 0;
                box-shadow: none;
            }
        }
        .accordion-body{
            padding: var(--s-12);

            .inside_header{
                @include flex-direction(flex, flex-start, center, row);

                gap: var(--s-8);
                height: var(--s-40);
            }
        }
    }
    .cart_detail_box{
        @include box-shadow(0 2px 4px rgba($black, 0.1));
        @include border-radius(0.5rem);
    
        width: 100% !important;
        border: 1px solid lighten($gray, 40%);
    
        flex-direction: row;
        overflow: hidden;
        margin-bottom: var(--s-12);
        &:last-child {
            margin-bottom: 0;
        }
        *{
            color: lighten($darker-gray, 25%);
        }
        .cart_description{
            @include flex-direction(flex, flex-start, flex-start, row);
    
            gap: var(--s-16);
            padding: var(--s-16);
            .description {
                .item_row{
                    @include flex-direction(flex, space-between, center, row);
    
                    margin-bottom: var(--s-12);
                    gap:var(--s-s12);
                    &.btn_group{
                        justify-content: flex-end;
                        gap: var(--s-8);
                        margin-bottom: 0;
                    }
                }
                .order_id a{
                    color:$primary-color;
                }
            }
            img{
                @include box-shadow(0 2px 4px rgba($black, 0.1));
                @include border-radius(0.3rem);
    
                width: 100%;
                max-width: 50px;
                margin: 0;
            }
            
        }
        .help_panel{
            @include flex-direction(flex, space-between, center, row);
    
            padding: 0 var(--s-16);
            border-top: 1px solid #f2f2f2;
            width: 100%;
            min-height: 56px;
            gap: var(--s-8);
            .agent {
                @include flex-direction(inline-flex, center, center, row);
    
                width: var(--s-32);
                height: var(--s-32);
    
                border-radius: 50%;
                border: 1px solid #f2f2f2;
                background-color: #f2f2f2;
            }
            a{
                color:$primary-color;
            }
        }
    }
    &.on_desktop{
        .cart_description{
            img{
                max-width: 120px;
            }
            
        }
    }
}
