/* Common */

#wedding_website_view {
  // * {
  //     margin: 0;
  //     padding: 0;
  //     border: 0;
  //     box-sizing: border-box;
  //     -moz-box-sizing: border-box;
  //     -webkit-box-sizing: border-box;
  //     -o-box-sizing: border-box;
  //     -ms-box-sizing: border-box;
  // }

  // body {
  //     color: #fff;
  //     font-family: 'Fira Sans', sans-serif;
  //     background-image: url('../images/background-7.png');
  //     background-repeat: repeat;
  //     font-size: 16px;
  //     overflow-x: hidden;
  // }

  html {
    scroll-padding-top: 180px;
  }


  @media only screen and (min-width: 992px) {
    .offcanvas {
        visibility: visible !important;
    }
  
  
  }
  .offcanvas {
    visibility: visible;
    position: relative;
    background-color: transparent;
    align-items: center;
    transform: unset;

    .offcanvas-header {
      display: none;
    }
  }

  img {
    max-width: 100%;
    width: auto;

    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .container {
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .left-right-wrap {
    img {
      height: 100%;
      max-height: 320px;
    }
  }

  .main_banner {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
    max-height: 1024px;
    height: 100%;
    position: relative;

    img {
      border-radius: 5px;
      border: 3px solid #b9250d;
    }

    .couple_wrap {
      color: #333;
      padding: 30px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;

      .couple_details {
        color: #b9250d;

        h1 {
        }

        .save_date {
          font-size: 20px;
        }
      }
    }

    img {
      width: 100%;
    }

    @media (max-width: 767px) {
      .couple_wrap {
        position: static;
        display: block;
      }
    }
  }

  section.main_events {
    color: #333;

    h2 {
      text-align: center;
      font-size: 38px;
      color: #583a20;
    }

    .all_events {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: 24px;

      .event-row {
        padding: 30px;
        width: 100%;
        max-width: 500px;
        background-color: #ffedde;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
        // background-image: url('../images/border.png'), url('../images/border-r.png');
        background-repeat: no-repeat;
        background-position: left top, right bottom;
        background-size: 110px;

        * {
          text-align: center;
        }

        .when_where {
        }

        .event_details {
          font-size: 20px;

          h4 {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .rsvp_stripe {
    .bottomMenu.show {
      opacity: 1;
    }

    .bottomMenu {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 99;
      background: #fff;
      transition: all 0.5s;
      padding: 8px 0;
      box-shadow: 0 -3px 8px rgba(64, 87, 109, 0.07);

      .cta_box {
        display: flex;
        justify-content: center;
      }
    }
  }

  // .rsvp_Container {
  //     max-width: 800px;
  //     width: 100%;

  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     gap: 16px;
  //     border-radius: 10px;
  //     margin: 32px auto;
  //     // background-image: url('../images/border-red-l.png'), url('../images/border-red-r.png');
  //     background-repeat: no-repeat;
  //     background-position: left top, right bottom;
  //     background-size: 110px;
  //     padding: 50px;

  //     .form_container {
  //         background-color: #ffedde;
  //         box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
  //         width: 100%;
  //         height: 100%;
  //         border-radius: 10px;
  //         border: 1px dashed #583A20;
  //         padding: 16px;

  //         .all_invited_Guests {
  //             list-style: none;
  //             padding: 0;
  //             display: flex;
  //             flex-direction: column;
  //             gap: 12px;
  //             border-bottom: 1px dashed #583A20;
  //             padding-bottom: 16px;
  //             margin-bottom: 16px;

  //             input[type='tel'] {
  //                 width: 130px;
  //             }

  //             input.last_name {
  //                 width: 130px;
  //             }

  //             li {
  //                 position: relative;
  //                 display: flex;
  //                 align-items: center;
  //                 font-size: 16px;

  //                 gap: 10px;

  //                 &:before {
  //                     content: attr(count);
  //                     position: relative;
  //                     display: inline-flex;
  //                     align-items: center;
  //                     justify-content: center;
  //                     font-size: 12px;
  //                     font-weight: 600;
  //                     width: 24px;
  //                     height: 24px;
  //                     border-radius: 50%;
  //                     border: 1px solid #583A20;
  //                 }
  //             }
  //         }

  //         .event_list {
  //             &:not(:last-child) {
  //                 border-bottom: 1px dashed #583A20;
  //                 margin-bottom: 16px;
  //             }
  //         }

  //         .guest_selction {
  //             tbody {
  //                 td {
  //                     padding-bottom: 16px;
  //                 }
  //             }
  //         }
  //     }

  //     .email {
  //         font-size: 13px;
  //     }

  //     $dark: #292929;
  //     $light: #efefef;
  //     $red: #D91E18;
  //     $green: #2ECC71;
  //     $blue: #4183D7;
  //     $small: 40em;

  //     .button-wrap {
  //         @media (max-width: $small) {
  //             margin-top: -1.5em;
  //         }
  //     }

  //     .button-label {
  //         display: inline-flex;
  //         align-items: center;
  //         justify-content: center;
  //         width: 60px;
  //         height: 40px;
  //         margin-right: 10px;
  //         cursor: pointer;
  //         color: $dark;
  //         border-radius: 0.25em;
  //         background: $light;
  //         box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);
  //         transition: 0.3s;
  //         user-select: none;

  //         span {
  //             font-size: 14px;
  //             margin: 0;
  //         }

  //         &:hover {
  //             background: darken($light, 10%);
  //             color: darken($dark, 10%);
  //             box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
  //         }

  //         &:active {
  //             transform: translateY(2px);
  //             box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0px -1px 0 rgba(0, 0, 0, 0.22);
  //         }

  //         @media (max-width: $small) {
  //             padding: 0em 1em 3px;
  //             margin: 0.25em;
  //         }
  //     }

  //     #yes-button:checked+.button-label {
  //         background: $green;
  //         color: $light;

  //         &:hover {
  //             background: darken($green, 5%);
  //             color: darken($light, 5%);
  //         }
  //     }

  //     #no-button:checked+.button-label {
  //         background: $red;
  //         color: $light;

  //         &:hover {
  //             background: darken($red, 5%);
  //             color: darken($light, 5%);
  //         }
  //     }

  //     #maybe-button:checked+.button-label {
  //         background: $blue;
  //         color: $light;

  //         &:hover {
  //             background: darken($blue, 5%);
  //             color: darken($light, 5%);
  //         }
  //     }

  //     .hidden {
  //         display: none;
  //     }

  //     @media (max-width:480px) {
  //         padding: 0px;

  //         .form_container {

  //             .all_invited_Guests {

  //                 li {

  //                     flex-direction: column;
  //                     align-items: start;
  //                     width: 100%;

  //                     input {
  //                         width: 100% !important;

  //                     }

  //                 }
  //             }

  //             .event_list {
  //                 .guest_selction {

  //                     table {

  //                         th.name_list {
  //                             width: 25% !important;
  //                             font-size: 14px;

  //                         }

  //                         .text-row {
  //                             font-size: 14px;

  //                         }

  //                         tr {

  //                             td {

  //                                 span {

  //                                     font-size: 12px;
  //                                 }

  //                                 .button-wrap {
  //                                     margin-top: 0;

  //                                     .button-label {
  //                                         width: 50px;

  //                                         span {
  //                                             font-size: 12px;
  //                                         }
  //                                     }
  //                                 }
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         }
  //     }

  //     @media (min-width:481px) and (max-width:812px) {
  //         padding: 0px;

  //         .form_container {

  //             .all_invited_Guests {

  //                 li {

  //                     flex-direction: column;
  //                     align-items: start;
  //                     width: 100%;

  //                     input {
  //                         width: 100% !important;

  //                     }

  //                 }
  //             }

  //             .event_list {
  //                 .guest_selction {

  //                     table {

  //                         th.name_list {
  //                             width: 25% !important;
  //                             font-size: 15px;

  //                         }

  //                         .text-row {
  //                             font-size: 16px;

  //                         }

  //                         tr {

  //                             td {

  //                                 span {

  //                                     font-size: 16px;
  //                                 }

  //                                 .button-wrap {
  //                                     margin-top: 0;

  //                                     .button-label {
  //                                         width: 60px;

  //                                         span {
  //                                             font-size: 14px;
  //                                         }
  //                                     }
  //                                 }
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         }
  //     }
  // }

  .modal {
    color: #583a20;

    td {
      padding-bottom: 10px;
      padding-right: 10px;
    }

    input {
      width: 300px;
    }

    textarea {
      width: 100%;
    }

    textarea {
      padding: 0 10px;
      width: 100%;
      height: 80px;
      padding: 12px;
      background-color: transparent;
      border: 1px solid #583a20;
      font-size: 13px;
      border-radius: 3px;

      &:focus {
        outline: none;
      }
    }

    a.rsvp {
      border-color: #583a20;
      color: #583a20;

      &:focus,
      &:hover {
        border-color: #583a20;
        color: #583a20;
        background-color: transparent;
      }
    }
  }

  header {
    &.fixed {
      position: fixed;
      background: #fce3ce;
      top: 0;
      left: 0;
      z-index: 99;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
      width: 100%;
    }
  }
}


.header-top-content {
  padding: 10px;

  .popover_action_item {

    padding: 0 !important;

    &.drop_down_footer {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
  }


  .toggle_btn-panel {

    padding-bottom: 12px;
    border-bottom: var(--border);
  }

  span.header-img {

    justify-content: space-between;
  }
  }

@media (max-width:567px) {
  right: 0 !important;
  
}

}