/**
 * Mobile Slider Styles
 *
 * Defines styling for unordered lists (ul) and list items (li) mobile slider, ensuring
 * consistent layout and spacing.
 *
 * Base Styles:
 * - Applies flexbox to ul for centered and responsive list layout.
 * - Sets padding and gap for spacing between list items.
 * - Defines li as a flex container with specific dimensions and background.
 * - Styles span and i elements within li for consistent typography and icons.
 *
 * Responsive Styles:
 * - Adjusts layout and dimensions for li elements on smaller screens to ensure
 *   readability and maintain layout integrity.
 *
 * @author: Ganesh Ughade
 * @version: 1.0
 */

 @use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../themes/default/colors' as *;
@use '../abstracts/breakpoints' as *;

.mobile_slider {
  /* Unordered List (ul) */
  ul {
    @include flex-direction(flex, unset, flex-start, row);

    overflow: auto;
    scrollbar-width: none;
    flex-wrap: nowrap;
    gap: var(--s-16);  // Sets the gap between list items.
  }

  /* List Item (li) */
  li {
    @include flex-center;
    @include border-radius(0.5rem);

    flex-direction: column; // Aligns content vertically within each list item.
    flex: 1; // Distributes available space evenly among list items.

    min-width: 150px;
    
    background-color: rgba(
      $primary-color,
      0.2
    ); // Applies a semi-transparent background using primary color.

    padding: var(--s-12);

    /* Inner span element */
    span {
      font-size: var(--text-1);
      text-align: center;
    }

    /* Inner i element */
    i {
      font-size: var(--s-20);
      color: $primary-color; // Applies primary color to icons.
    }
  }
  @include respond-to('laptop') {
    ul{
      padding: var(--s-12);
    }
    li{
      @include box-shadow(0 2px 4px rgba($black, 0.2));

      gap:var(--s-16);
      background-color: $light-pink;
      span{
        color:  $primary-color;
      }
      i{
        font-size: var(--s-24);
      }
    }
  }
}
