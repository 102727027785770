/* Bootstrap scss */
@use '~bootstrap/scss/bootstrap';

@use '../../abstracts/variables' as *;
@use '../../themes/default/variables' as *;
@use '../../abstracts/mixins' as *;
@use '../../themes/default/colors' as *;
@use '../../abstracts/breakpoints' as *;
@use '../../components/buttons' as *;

// common variables related to the video invitation
$banner-index: '../../assets/img/v1/vendor/vendor_index_banner.png';

%vendor_background {
  background-image: url($banner-index);
  background-size: cover;
  background-color: $light-medium-pink;
}

.vendor_home {
  .vendor_banner {
    @extend %vendor_background;

    background-position: left top;
    height: 178px;

    .container {
      @include flex-direction(flex, flex-end, center, row);

      height: 100%;
    }

    .banner_text_container {
      @include flex-direction(flex, flex-start, flex-start, column);

      gap: var(--s-12);
      width: 180px;

      h2 {
        line-height: var(--s-22);
      }
    }

    @include respond-to('laptop') {
      height: 300px;
      background-position: left center;

      .banner_text_container {
        gap: var(--s-16);
        width: 100%;
        align-items: flex-end;
      }
    }
  }
}

// search location and vendor type drop-down
.search_panel_vendor {
  @include flex-direction(flex, space-between, center, row);

  border-radius: var(--s-4);
  gap: var(--s-8);
  width: 100%;
  height: var(--s-40);
  max-width: calc(var(--s-72) * 12);
  margin: var(--s-12) auto;

  .dropdown {
    width: 100%;
    height: 100%;
    position: unset;

    .dropdown-menu {
      width: 100%;

      flex-wrap: wrap;
      gap: var(--s-16);
      list-style: none;
      margin: 0;
      max-height: calc(var(--s-72) * 7);
      overflow: auto;
      padding: var(--s-12);
      transform: unset !important;
      inset: unset !important;
      right: 0 !important;
      top: calc(var(--s-40) + var(--s-4)) !important;

      li {
        flex-basis: calc(var(--s-56) * 4.5);

        .menu_title {
          align-items: center;
          color: $black;
          display: flex;
          font-size: var(--heading-5);
          gap: var(--s-12);
          min-height: var(--s-40);

          img {
            height: auto;
            width: var(--s-28);
          }
        }

        .inside_list {
          list-style: none;
          max-height: calc(var(--s-56) * 4.5);
          overflow: auto;
          padding-left: var(--s-40);

          li {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: var(--heading-6);
            height: var(--s-32);
          }
        }
      }

      &.show {
        display: flex;
      }
    }

    .btn {
      border: 0 !important;
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: 0;
    }

    input {
      border: 0;
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: var(--s-6) var(--s-12);
      font-size: var(--s-14);
      font-weight: 500;
      color: $black;

      &:focus {
        outline: none;
        border: 0;
        box-shadow: none;
      }

      @include respond-to('laptop') {
        font-size: var(--s-16);
      }
    }
  }

  .search_category {
    @include flex-direction(flex, flex-start, center, row);

    border-right: 1px solid $grayLighter;
    flex: 1;
    height: 100%;
    max-height: var(--s-56);
    border: 1px solid $grayLighter;
    border-radius: var(--s-4);
    background-color: $white;

    @media only screen and (max-width: 600px) {
      max-width: 165px;
    }
  }

  .location_container {
    border: 1px solid $grayLighter;
    border-radius: var(--s-4);
    background-color: $white;
    height: 100%;
    max-height: var(--s-56);
    flex: 1;

    .search_location {
      flex: 1;
    }

    .btn {
      border-width: 0 0 0 1px !important;
      border-radius: 0 !important;
      border-color: $grayLighter !important;
    }

    @include respond-to('laptop') {
      .dropdown-menu {
        max-width: 50%;
        right: 0;
      }
    }
  }

  @include respond-to('laptop') {
    @include box-shadow(0 2px var(--s-8) rgba($black, 0.2));
    margin: 0 auto;

    padding: 0 var(--s-8);
    height: var(--s-72);
    margin: var(--s-12) auto 0 auto;
    background-color: $notification;

    .dropdown .dropdown-menu {
      top: calc(var(--s-56) + var(--s-20)) !important;
    }
  }
}



// vendor slider
.category_container {
  @include flex-direction(flex, center, flex-start, row);

  position: relative;

  .top_hover_info {
    @include flex-direction(flex, flex-start, flex-start, column);

    background-color: $white;
    border-radius: 0.5rem;
    gap: var(--s-6);
    width: 100%;
  }
}

.vendor_slider {
  padding: 50px 0;

  .slick-slider {
    margin-top: var(--s-16);
  }

  .slick_slider_align {
    .slick-track {
      display: flex;
      gap: var(--s-8);

      @include respond-to('laptop') {
        gap: var(--s-16);
      }
    }

    .slick-slide {}
  }

  .slick-arrow {
    display: none !important;
    width: var(--s-40);
    height: var(--s-40);
    background-color: rgb(255, 255, 255);
    filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 2px var(--s-8));
    border-radius: 50%;
    z-index: 9;

    &:hover,
    &:focus {
      width: var(--s-40);
      height: var(--s-40);
      background-color: rgb(255, 255, 255);
      filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 2px var(--s-8));
      border-radius: 50%;
      z-index: 99;
    }

    &.slick-prev {
      left: -var(--s-12);
      top: 42%;

      &::before {
        content: '\f053' !important;
        font-family: 'Font Awesome 5 Pro' !important;
        color: $black;
        font-size: 18px;
      }
    }

    &.slick-next {
      right: -var(--s-12);
      top: 42%;

      &::before {
        content: '\f054' !important;
        font-family: 'Font Awesome 5 Pro' !important;
        color: $black;
        font-size: 18px;
      }
    }
  }

  .slick-dots {
    display: none !important;
  }

  &.category_slider {
    padding: 0;

    .slick-list {
      padding-bottom: var(--s-12);
    }

    .slick-track {
      padding: var(--s-12) 0 0 0;
      position: relative;
      top: -var(--s-12);
    }

    .slick-slide {
      @include box-shadow(0 2px var(--s-8) rgba($black, 0.2));

      border-radius: var(--s-8);
      overflow: hidden;
    }

    .slide {
      display: inline-flex !important;
      flex-direction: column;
      gap: var(--s-12);
      padding: var(--s-12);
      background-color: $white;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .slide_item {
        @include flex-direction(flex, flex-start, flex-start, column);

        gap: var(--s-8);
        flex: 1;
        font-size: 0.9rem;

        h4,
        h6 {
          color: $gray-midium;
          font-weight: normal;
        }

        @include respond-to('laptop') {
          font-size: 0.9rem;
        }
      }

      img {
        width: auto;
        max-width: unset;
        height: 220px;
        width: 100%;
        border-radius: 5px;
        object-fit: cover;
        object-position: center;
      }
    }

    &.grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--s-8);
      grid-row-gap: var(--s-8);
      margin-top: 20px;

      .no_vendor_found {
        @include flex-direction(flex, flex-start, center, column);

        gap: var(--s-8);
        margin: 0 auto;
      }

      @include respond-to('laptop') {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: var(--s-16);
        grid-row-gap: var(--s-16);
      }

      &.no_result_container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}