.email_verification {
    max-width: 600px;
    width: 100%;
    text-align: center;
    padding: 0 20px;
    .email-verification-id {

        display: block;
        font-weight: 400;
        font-size: 20px;
    }

    p {
        margin-bottom: 25px;
        font-size: 20px;
    }
}

.email_verification_vendor {
    max-width: 600px;
    width: 100%;
    padding: 0 20px;
    .email-verification-id {

        display: block;
        font-weight: 400;
        font-size: 20px;
    }

    p {
        margin-bottom: 18px;
        font-size: 18px;
    }
}