@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

$blog_banner: '../../assets/img/v1/blog/blog_banner.png';

.blog_banner_container_full {
  .blog_banner_container {
    position: relative;
    background-image: url($blog_banner) !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 300px;
    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.5;
    }
  }
}

/**
  * Single Post Page
  */

.related-articles {
  h2 {
    margin-bottom: 20px;
    text-align: center;
    font-weight: normal;
  }

  .articles-container {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--s-24);
    padding: var(--s-16) 0;

    @include respond-to('tablet-portrait') {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--s-24);
      padding: var(--s-32) 0;
    }

    @include respond-to('tablet') {
      grid-template-columns: repeat(3, 1fr);
    }

    .article-box {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: var(--s-12);
      flex: 1;
      text-align: left;

      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 1.25rem;
        margin-bottom: 10px;
      }

      .article-date {
        font-size: 0.875rem;
        color: #999;
        margin-bottom: 10px;
      }

      .article-description {
        font-size: 1rem;
        color: #555;
      }
    }
  }
}

.full-width-section {
  background-color: #966d32;
  padding: var(--s-16) 0;
  color: $white;
  @include respond-to('tablet-portrait') {
    padding: var(--s-32) 0;
  }

  h3 {
    color: $white;
    margin-bottom: var(--s-16);
  }
  h2 {
    color: $white;
  }

  .articles-container {
    &.with_two_column {
      display: grid;
      grid-template-columns: 100%;
      justify-items: center;
      align-items: start;

      gap: var(--s-24);
      @include respond-to('tablet-portrait') {
        grid-template-columns: 50% 50%;
        gap: var(--s-16);
      }
      img {
        border-radius: var(--s-12);
      }
    }
    &.with_three_column {
      display: grid;
      grid-template-columns: 100%;

      gap: var(--s-24);
      @include respond-to('tablet-portrait') {
        grid-template-columns: 50% 25% 25%;
        gap: var(--s-16);
      }
      img {
        border-radius: var(--s-12);
      }
      p {
        font-size: 1rem;
      }
    }
  }

  p {
    margin: 0;
    font-size: 1.25rem;
  }
}

.featured-categories {
  display: grid;
  gap: var(--s-24);
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  padding: var(--s-16) 0;
  @include respond-to('tablet-portrait') {
    grid-template-columns: repeat(2, 1fr);
    padding: var(--s-32) 0;
  }

  .box_item {
    background-color: #f2e9e9;
    padding: var(--s-16);
    border-style: none;
    h3 {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      color: #555;
    }

    .thumbnail {
      width: 100%;
      height: auto;
      border-radius: 10px;
      margin-bottom: 15px;
    }

    .title {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .date {
      font-size: 0.875rem;
      color: #999;
      margin-bottom: 10px;
    }

    .small-content {
      font-size: 1rem;
      color: #555;
    }
  }
}

.two-column-section {
  display: grid;
  grid-template-columns: 100%;
  align-items: start;
  gap: var(--s-24);
  padding: var(--s-16) 0;
  margin-bottom: var(--s-16);
  @include respond-to('tablet-portrait') {
    grid-template-columns: 30% 60%;
    padding: var(--s-32) 0;
    margin-bottom: var(--s-32);
  }

  .left-column {
    padding: 10px;
    span {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 2;
    }
    h2 {
      margin: 15px 0px;
    }
    p {
      width: 75%;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  .right-column {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .right-column-content {
      display: grid;
      gap: var(--s-16);
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      @include respond-to('tablet-portrait') {
        grid-template-columns: repeat(2, 1fr);
      }
      img {
        border-radius: var(--s-12);
      }
      .article-description {
        p {
          font-size: 1rem;
        }
      }
    }
  }
}

.three-column-section {
  display: grid;
  gap: var(--s-24);
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  @include respond-to('tablet-portrait') {
    grid-template-columns: repeat(3, 1fr);
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    color: #1e1e1e;
  }
  .column {
    display: grid;
    gap: var(--s-16);
    grid-template-columns: repeat(1, 1fr);
    .column-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--s-8);

      h6 {
        text-align: left;
      }

      a {
        text-align: right;
        color: $primary-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .article-row {
      display: flex;
      gap: var(--s-12);

      .thumbnail {
        width: 30%;
        height: auto;
        border-radius: var(--s-12);
      }

      .article-info {
        flex: 1;

        h4 {
          margin: 0 0 5px;
        }
      }
    }
  }
}

.category-chicklet {
  display: inline-block;
  background-color: transparent;
  border: 1px solid $primary-color;
  color: $primary-color;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.875rem;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 10px;
  &.white {
    border: 1px solid $white;
    color: $white;
  }
}

.subscribe-section {
  color: #fff;

  .subscribe-widget {
    display: grid;
    gap: var(--s-24);
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    background-color: #966d32;
    border-radius: var(--s-16);
    margin: var(--s-16) 0;
    padding: 16px;
    @include respond-to('tablet-portrait') {
      grid-template-columns: repeat(2, 1fr);
      padding: 40px;
      margin: var(--s-32) 0;
    }

    .subscribe-widget-heading {
      h3 {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        @include respond-to('tablet-portrait') {
          font-size: 1.75rem;
        }
      }
    }
    .subscribe-form {
      position: relative;
      overflow: hidden;
      margin-top: var(--s-20);
      width: 100%;

      input {
        width: 100%;
        height: 50px;
        background: $white;
        border: 1px solid #ccc;
        color: $black;
      }

      button {
        position: absolute;
        right: 0;
        background: $white;
        padding: 13px var(--s-20);
        border: 1px solid $primary-color;
        top: 0;
        height: 50px;
        display: inline-flex;
        align-items: center;

        i {
          color: $primary-color;
          font-size: var(--s-20);
          transform: rotate(-6deg);
        }
      }
    }
  }
}

.post-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-size: contain;

  .post-date,
  .post-title,
  .post-author {
    p {
      margin: 0;
      font-size: 1rem;
      color: #555;
    }
  }
  .post-date {
    padding-top: 48px;
  }
  .post-title {
    h1 {
      font-size: 2rem;
      margin: 16px 0px;
      font-weight: 300;
    }
  }

  .post-image {
    padding-top: 24px;
  }

  .social-icons {
    display: flex;
    justify-content: flex-end;

    margin: 20px auto;
    padding-right: 15px;
    font-size: 24px;
    gap: 12px;

    a {
      margin-left: 10px;
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .text-view {
    p {
      margin: 0 0 20px;
      font-size: 1rem;
      color: #555;
    }

    h2,
    h3 {
      margin: 20px 0;
      font-size: 1.5rem;
    }

    .section-number,
    h3 {
      font-size: 1.25rem;
      color: #bc8129;
      margin: 40px 0 20px;
      font-weight: normal;
    }

    img {
      margin-bottom: 20px;
    }
  }
  ol,
  ul {
    list-style: disc;
    margin-left: 32px;
    li {
      margin-bottom: 0;
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
}
