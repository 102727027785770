@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

$guest-celebration-bg: '../../assets/img/guest_celebration.jpg';

%wedding-web--bg {
  background-image: url($guest-celebration-bg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center top;
  -webkit-background-size: cover;
}

.section-bg-pink {
  background: $extra-light-pink;
}

.vt-section-wrapper {
  display: flex;
  align-items: center;
  gap: var(--s-36);
  padding-top: var(--s-24);
  padding-bottom: var(--s-24);
  text-align: center;

  @include respond-to('tablet-portrait') {
    text-align: left;
    padding: var(--s-56) 0;
  }
}

.vt-column-reverse {
  flex-direction: column-reverse;
  @include respond-to('tablet-portrait') {
    flex-direction: row;
    gap: var(--s-72) !important;
  }
}

.vt-row-reverse {
  @include respond-to('tablet-portrait') {
    flex-direction: row-reverse;
  }
}

.vt-text-align-left {
  @include respond-to('tablet-portrait') {
    text-align: left;
  }
}

.vt-gap-0 {
  gap: 0;
}

.website-creation {
  background-image: none;
  @include respond-to('tablet-portrait') {
    background-image: url(../../assets/img/flat-bkg.png), url(../../assets/img/flat-bkg-bottom.png);
    background-size: cover;
    background-position: left -200px top, right bottom;
    background-repeat: no-repeat;
  }
}

.pre-footer {
  @extend %wedding-web--bg;
  position: relative;
  padding: var(--s-72) 0;
  text-align: center;
  @include respond-to('tablet-portrait') {
    padding: calc(var(--s-56) * 2) 0;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($black, 0.6);
    top: 0;
    left: 0;
    z-index: 9;
  }

  h2 {
    color: $white;
  }

  .z-index-99 {
    z-index: 99;
  }
  .pb-16 {
    padding-bottom: var(--s-16);
  }
}
