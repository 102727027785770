@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

.tab-view-btn-wrapper {
  flex-direction: row;
  @include respond-to('tablet-portrait') {
    flex-direction: column;
  }

  .btn {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    @include respond-to('tablet-portrait') {
      width: 100%;
      border-radius: 0;
      &:first-child {
        border-top-left-radius: var(--s-6);
        border-top-right-radius: var(--s-6);
      }
      &:last-child {
        border-bottom-left-radius: var(--s-6);
        border-bottom-right-radius: var(--s-6);
      }
    }
  }
  .nav-link {
    color: $darker-gray;
  }
}

.form-wrapper {
  @include border-radius(0.5rem);
  @include box-shadow(0 0 12px rgba($black, 0.1));
  padding: var(--s-24);
  gap: var(--s-24);
  border: solid 1px #d2cbc3;
  background: #ffffff8f;
  margin: var(--s-16);
  @include respond-to('tablet-portrait') {
    margin: 0;
  }
}

.formfields {
  display: grid;
  gap: var(--s-16);
  grid-template-columns: repeat(1, 2fr);
  @include respond-to('tablet-portrait') {
    grid-template-columns: repeat(2, 2fr);
  }
}

.border_bottom {
  border-bottom: 1px solid #d1d1d1;
}
