.user-drop-down {
  position: relative;

  .user_icon {
    width: 36px;
    height: 36px;
    border: var(--border);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .fa-chevron-down {
    font-size: 12px;
  }

  .user_popover-menu {
    position: absolute;
    right: 0 !important;
    top: 60px;
    border-radius: 5px;
    z-index: -9999;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    transition: all 0.2s ease-out;

    background-color: #fff;

    .popup-header {
      border-bottom: var(--border);
      background-image: url('../assets/img/decoration.png');

      .user_icon {
        width: 50px;
        height: 50px;
      }
    }

    .popover_action_item {
      padding: 0 16px;
      border-bottom: var(--border);

      .nav-link {
        height: 36px;
        display: flex;
        align-items: center;
        gap: 12px;
        color: var(--textGlobalColor);
        padding: 0;

        i {
          width: 20px;
        }

        &:hover {
          color: var(--orange);
        }
      }
    }

    .drop_down_footer {
      .nav-link {
        height: 40px;
      }
    }
  }

  &:hover {
    .user_popover-menu {
      opacity: 1;
      z-index: 9;
      visibility: visible;
      pointer-events: unset;
      top: 46px;
      width: auto;
      transition: all 0.2s ease-out;
    }
  }
  &.website_setting_drop_down{
    .toggle_btn-panel{
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
    }
  }
}
