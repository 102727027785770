/* Footer */
.footer {
  /* @extend %flex-center;
  flex-direction: column;

  border-top: var(--border);
  background-color: var(--heroPanelBG);
  padding: 24px; */
  .hide_on_mobile{
    @media (max-width: 700px) {
      display: none;
    }

  }
  .brd-btm {
    border-bottom: 1px solid #444;

    @media (max-width: 700px) {
      border-bottom: none;
    }
  }

  p {
    color: #fff;
    font-size: 16px;
    padding-top: 12px;
    font-weight: 300;
  }

  .link_panel {
    display: inline-flex;
    gap: 16px;

    a {
      color: #fff;
    }
  }

  &.footer-section {
    background-color: #1e1e1e;
    position: relative;
    overflow: hidden;
    z-index: 9;
  }

  .footer-wrap {
    .left-row {
      border-right: 1px solid #444;

      .footer-about {
        display: flex;
        gap: 50px;
        padding: 50px 0;
        border-bottom: 1px solid #444;
      }

      .quick_links {
        display: flex;
        color: #fff;
        gap: 90px;
        padding: 40px 0;

        .footer_links {
          span {
            font-size: 24px;
            margin-bottom: 15px;
            display: block;
          }

          ul {
            list-style: none;
            padding-left: 0;

            li {
              a {
                font-size: 14px;
                color: #fff;
                padding: 6px 0;
                font-weight: 100;

                &:hover {
                  color:  var(--orange);
                }
              }
            }
          }
        }
      }
    }

    .right-row {
      display: flex;
      justify-content: center;
      align-items: center;

      .footer-widget {
        .footer-widget-heading {
          margin-bottom: 30px;

          h3 {
            color: #fff;
            font-size: 32px;
            font-weight: 100;
            margin-bottom: 14px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: -15px;
              height: 2px;
              width: 50px;
              background:  var(--orange);
            }
          }
        }

        ul {
          li {
            display: inline-block;

            width: 50%;
            margin-bottom: 12px;

            a {
              color: #fff;
              text-transform: capitalize;

              &:hover {
                color:  var(--orange);
              }
            }
          }
        }
      }

      .subscribe-form {
        position: relative;
        overflow: hidden;
        margin-top: 20px;

        input {
          width: 100%;
          height: 50px;
          background: #fff;
          border: 1px solid #ccc;
          color: #000;
        }

        button {
          position: absolute;
          right: 0;
          background:  var(--orange);
          padding: 13px 20px;
          border: 1px solid  var(--orange);
          top: 0;
          height: 50px;
          display: inline-flex;
          align-items: center;

          i {
            color: #fff;
            font-size: 22px;
            transform: rotate(-6deg);
          }
        }
      }
    }

    .copyright-area {
      display: flex;
    }

    .copyright-text {
      p {
        margin: 0;
        font-size: 14px;
        color: #fff;
        margin-bottom: 0;

        a {
          color:  var(--orange);
        }
      }
    }

    .footer-menu {
      ul {
        padding-left: 0;

        li {
          display: inline-block;
          margin-left: 20px;

          &:hover {
            a {
              color:  var(--orange);
            }
          }

          a {
            font-size: 14px;
            color: #fff;
            font-weight: 100;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .footer-wrap {
      .left-row {
        padding-right: 10px;

        .quick_links {
          gap: 12px !important;
          padding: 20px 0 !important;
        }
      }

      .right-row {
        .footer-widget {
          padding: 10px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    .footer-wrap {
      .left-row {
        border-right: none;

        .footer-about {
          gap: 20px;
          padding: 20px 0;
          flex-direction: column;
        }

        .quick_links {
          gap: 40px;
          padding: 20px 0;

          .footer_links {
            span {
              font-size: 18px;
            }
          }
        }
      }

      .right-row {
        border-bottom: 1px solid #444;
        border-top: 1px solid #444;
        padding-top: 25px;
        padding-bottom: 25px;
        justify-content: flex-start;
        .footer-widget {
          padding-top: 0px;

          .footer-widget-heading {
            h3 {
              font-size: 28px;
            }
          }
        }
      }

      .copyright-area {
        flex-direction: column-reverse;
        gap: 15px;

        .copyright-text {
          p {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }

        .footer-menu {
          display: flex;
          justify-content: center;
          width: 100%;

          ul {
            text-align: center !important;

            li {
              margin-left: 0px;

              a {
                display: inline;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .footer-wrap {
      .left-row {
        .footer-about {
          flex-direction: column;
          gap: 0;
          padding: 20px;
        }

        .quick_links {
          gap: 26px;
          

          .footer_links {
            a {
              font-size: 12px !important;
            }

            span {
              font-size: 16px;
            }
          }
        }
      }

      .right-row {
        padding: 40px;

        h3 {
          font-size: 28px !important;
        }
      }

      .copyright-area {
        flex-direction: column-reverse;
        gap: 15px;
        align-items: center;

        .copyright-text {
          p {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        }

        .footer-menu {
          display: flex;
          justify-content: center;
          width: 100%;

          ul {
            text-align: center !important;

            li {
              margin-left: 0px;

              a {
                display: inline;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .footer-wrap {
      .left-row {
        .footer-about {
          gap: 0;
          padding: 20px;
        }

        .quick_links {
          gap: 50px;
          padding: 20px;

          .footer_links {
            span {
              font-size: 18px;
            }
          }
        }
      }

      .right-row {
        padding: 40px;

        h3 {
          font-size: 32px !important;
        }
      }

      .copyright-area {
        .footer-menu {
          ul {
            li {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .footer-wrap {
      .left-row {
        .footer-about {
          gap: 0;
          padding: 20px;
        }

        .quick_links {
          gap: 60px;
          padding: 20px;

          .footer_links {
            span {
              font-size: 24px;
            }
          }
        }
      }

      .right-row {
        padding: 40px;

        h3 {
          font-size: 32px !important;
        }
      }
    }
  }
}
