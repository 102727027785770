/**
 * Typography Styles
 *
 * Defines foundational typography styles for the application, ensuring
 * consistent text rendering and readability across different elements,
 * screen sizes, and devices.
 *
 * Base Styles:
 * - Sets base font size to 16px (100%).
 * - Applies "Arima", system-ui as the default font family.
 * - Establishes a consistent line height of 1.5 for improved readability.
 * - Defines hierarchical styles for headings (h1-h6) with specific margins,
 *   font weights, and line heights.
 * - Sets default margin for paragraphs (p) and defines link styles.
 * - Enhances readability with strong and emphasized text styles.
 *
 * Responsive Styles:
 * - Adjusts heading sizes for better readability on smaller screens using
 *   @media queries, ensuring a cohesive design across different devices.
 *
 * @author: Ganesh Ughade
 * @version: 1.0
 */

/* Fonts sizes variables */
@use '../abstracts/variables' as *;
@use '../abstracts/breakpoints' as *;

/**
 *
 * UI Fonts Import
 * - Arima
 * 
 */

// Arima fonts (Google Font) and all video related fonts
@import url('https://fonts.googleapis.com/css2?family=Arima:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=DM+Serif+Text:ital@0;1&family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=DM+Serif+Text:ital@0;1&family=Sofia&family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Galada&family=Lobster&display=swap');

/* Base font size and family */
html {
  font-size: 100%;
  /* 16px by default */
}

body {
  font-family: 'Arima', system-ui;
  font-size: 1rem;
  /* 16px */
  line-height: 1.5;
  color: #333;
}

/* Heading styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  /* Bottom margin for spacing between headings and content */
  font-weight: 600;
  /* Default font weight for headings */
  line-height: 1.2;

  /* Adjusted line height for headings */
  @include respond-to('laptop') {
    margin-bottom: var(--s-4);
  }
}

h1 {
  font-size: var(--heading-1);
}

h2 {
  font-size: var(--heading-2);
}

h3 {
  font-size: var(--heading-3);
}

h4 {
  font-size: var(--heading-4);
}

h5 {
  font-size: var(--heading-5);
}

h6 {
  font-size: var(--heading-6);
}

// anchor font <a>
a {
  font-size: var(--heading-6);
}

/* Paragraph and link styles */
p {
  margin: 0;
  /* Bottom margin for paragraphs */
  font-size: var(--text-1);

  @include respond-to('laptop') {
    line-height: var(--s-28);
  }
}

a {
  color: #007bff;
  /* Link color */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    /* Underline link on hover */
  }
}

/* Strong and emphasized text */
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}
