.host-rsvp {
    padding-bottom: 60px;
    .event_rsvp_container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    
       
        .event_list {
            padding: 10px;
           background-color: #fbf5eb;
            flex-direction: column;
            border: 1px dashed #000;

            .event_name_day {

                h3 {
                    color: var(--orange);
                    font-size: 20px;

                   
                }
            }

            .guest_selction {

                table {
                    border-top: 1px dashed  #000;
                    width: 100%;

                    tr {
                        display: flex;
                        justify-content: space-between;
                        @media (max-width:480px) {

                          flex-direction: column;
                            
                        }


                        td {

                            span {

                                font-size: 18px;

                                @media (max-width:480px) {

                                    font-size: 16px;
                                    
                                }
                            }
                            @media (max-width:480px) {

                                span {
                                    margin-bottom: 15px;
                                    display: block;
                                }

                             
                                  
                              }
                        }
                    }
                }
            }
            .btn_rsvp_group {

                justify-content: flex-end !important;

                @media (max-width:480px) {
                    justify-content: flex-start !important;

                    
                }

             a {
                color: var(--orange);
                &:nth-child(1) {

                    display: none;
                }

             }  

           
            }


            h3 {
                text-align: left;
                margin-bottom: 8px;
                color:  #000;
            }


            p {
                font-weight: 400;
            }   

          
            $dark: #292929;
            $light: #efefef;
            $red: #D91E18;
            $green: #2ECC71;
            $blue: #4183D7;
            $small: 40em;

            .button-label {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: unset;
                padding-left: 0;
                width: 60px;
                height: 40px;
                margin-right: 10px;
                cursor: pointer;
                color: $dark;
                border-radius: 0.25em;
                font-size: 13px;
                background: $light;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);
                transition: 0.3s;
                user-select: none;
                &::before{
                    opacity: 0;
                }

                span {
                    font-size: 14px;
                    margin: 0;
                }

                &:hover {
                    background: darken($light, 10%);
                    color: darken($dark, 10%);
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
                }

                &:active {
                    transform: translateY(2px);
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0px -1px 0 rgba(0, 0, 0, 0.22);
                }

            }

            .yes-button:checked+.button-label {
                background: $green;
                color: #fff;

                &:hover {
                    background: darken($green, 5%);
                    color: darken($light, 5%);
                }
            }

            .no-button:checked+.button-label {
                background: $red;
                color: #fff;

                &:hover {
                    background: darken($red, 5%);
                    color: darken($light, 5%);
                }
            }

            .maybe-button:checked+.button-label {
                background: $blue;
                color: #fff;

                &:hover {
                    background: darken($blue, 5%);
                    color: darken($light, 5%);
                }
            }

            .hidden {
                display: none;
            }

            &.horizontal {
                display: flex;
                align-items: center;
                gap: 24px;
                .guest_selction {
                    border-left: 1px dashed #000;
                    padding-left: 24px;
                    flex: 1.5;
                }

                .button-label {
                    width: 40px;
                    border-radius: 50%;
                    font-size: 0px;
                }

                .event_name_day {
                    flex: 1;

                    h3 {

                        color: #000;
                    }
                }
               

                .yes-button+.button-label {
                    &::after {
                        content: '\f164';
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        font-size: 18px;
                    }
                }

                .no-button+.button-label {
                    &::after {
                        content: '\f165';
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        font-size: 18px;
                    }
                }

                .maybe-button+.button-label {
                    &::after {
                        content: '\f06a';
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

