body {
  // font-family: 'Kanit', sans-serif;
  color: var(--textGlobalColor);
  overflow-x: hidden;
  font-family:"Arima", system-ui;
  *{
    font-weight: 500;
  }
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.root_container {
  flex: 1;
  // display: flex;
  // flex-direction: column;
}

.container {
  max-width: 1400px;
  padding: 0 72px;

  /* &.width_1600 {
    max-width: 1600px;
  }
  &.width_1296 {
    max-width: 1296px;
    padding: 0 20px;

  } */
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* custom slider */
.custom-slider {
  .btn {
    border: 0 !important;
    min-width: unset;

    i {
      color: #000;
    }
  }

  .swiper-slide {
    background-color: var(--sliderCardBKG);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    img {
      align-self: flex-start;
      height: auto;
      width: 100%;
    }

    .small_text {
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #6e6a93;
    }

    .title_mediumLight {
      + p {
        font-size: 14px;
        font-weight: 300;
      }
    }

    .image-shape {
      width: 100%;
      height: auto;
      position: relative;
      display: flex;
      justify-content: flex-end;

      img {
        width: auto;
        height: 160px;
        align-self: flex-start;
      }
    }
  }
}

.unauthorized_container {
  display: flex;
}

/* Login full page */
.login_fullPage_container {
  // width: 100vw;
  // height: 100vh;
  // background-color: #fffaf6;
  // position: absolute;
  display: flex;
}

// action button css
.ant-space-compact {
  :where(.css-dev-only-do-not-override-htwhyh).ant-btn-default:not(:disabled):hover {
    color: var(--orange);
    border-color: var(--orange);
  }
}

.ant-dropdown {
  .ant-dropdown-menu-item-only-child {
    .ant-dropdown-menu-title-content {
      .anticon {
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
}
