@media (min-width: 800px) {
  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }
}

@media (min-width: 576px) {
  .w-smm-50 {
    width: 50% !important;
  }
}

/* Event Page */
.mobile_banner {
  display: none;

  @media (max-width: 600px) {
    display: flex;
    margin: 16px 0;
  }
}

// .data_only_for_desktop {
//   @media (max-width: 600px) {
//     display: none;
//   }
// }

.data_only_for_mobile {
  display: none;

  @media (max-width: 600px) {
    display: block;
    margin-bottom: 10px;
    color: #a3a3a3;
    font-size: 16px;
  }
}

// .event_banner {
//   padding: 12px 0;
//   /* background-image: url('../assets/img/event-bkg.jpg'); */
//   background-repeat: no-repeat;
//   background-position: left top -100px;
//   background-size: cover;
//   background-color: #f9f5f0;
//   .container {
//     display: flex;
//     align-items: center;
//     gap: 32px;

//     @media (max-width: 767px) {
//       gap: 0px;

//     }

//     .event_slogan {
//       width: 100%;
//       max-width: 500px;

//       h1 {
//         font-size: 22px;
//       }

//       p {
//         font-weight: 300;
//         font-size: 18px;
//       }

//       @media (max-width: 767px) {
//         max-width: unset;

//       }
//     }

//     .action-item-event {
//       flex: 1;
//       display: flex;
//       gap: 12px;
//       justify-content: flex-end;

//     }

//     @media (max-width: 767px) {
//       flex-direction: column;

//       .action-item-event {
//         width: 100%;
//         a {

//           width: 100%;
//         }
//       }
//     }
//   }

//   @media (max-width: 767px) {
//     padding: 24px 0;
//   }

//   // New Invitation Flow Design
//   &.new_invitation_flow {
//     background-color: #fff6ed;
//     padding: 30px 0;

//     .event_slogan {
//       width: 100%;
//       max-width: unset;
//       font-family: 'Arima', system-ui;
//       font-size: 36px;
//       font-weight: 400;
//     }
//   }
// }

// striper panel
.striper_panel {
  width: 100%;
  background-color: #efe1d4;
  padding: 24px 0;

  .striper_row {
    display: flex;
    gap: 48px;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 16px;

      p {
        text-align: center;
        font-size: 18px;

        a {
          font-size: 18px;
        }
      }
    }
  }

  p {
    font-family: 'Arima', system-ui;
    font-size: 30px;
    font-weight: 400;
    padding: 0;
    margin: 0;

    a {
      font-size: 20px;
    }
  }

  .invitation_action_panel {
    display: inline-flex;
    gap: 16px;

    button.round {
      align-self: flex-start;
      border-radius: 32px;
      min-width: 100px;
      height: 40px;
      display: inline-flex;
      gap: 8px;
      font-family: 'Arima', system-ui;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

browse design .browse_design_container {
  background-color: #fbf5eb;
  padding: 32px 0;
  min-height: 100vh;

  .title_medium {
    font-family: 'Arima', system-ui;
    font-size: 36px;
    font-weight: 400;
  }

  .template_list_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 32px;
    gap: 24px;

    .template_item {
      width: 240px;

      .action_panel {
        position: relative;
        width: 100%;
        height: 380px;
        border: 3px solid #000;

        img {
          width: auto;
          height: 100%;
        }

        .hover_panel {
          position: absolute;
          opacity: 0;
          z-index: -1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          bottom: 0;
          width: 100%;
          background-color: #fff6ed;
          padding: 8px;
          align-items: center;

          .edit_option {
            width: 200px;
            height: 40px;
            align-items: center;
            justify-content: center;
            border: 1px solid #eadfdf;
            border-radius: 32px;
            gap: 12px;
            display: inline-flex;
            background-color: #fff;

            .color_option {
              width: 16px;
              height: 16px;
              background: rgb(15, 132, 240);
              background: linear-gradient(
                80deg,
                rgba(15, 132, 240, 1) 51%,
                rgba(220, 237, 253, 1) 51%
              );
              border-radius: 50%;

              &:nth-child(2) {
                background: rgb(249, 228, 228);
                background: linear-gradient(
                  80deg,
                  rgba(249, 228, 228, 1) 51%,
                  rgba(181, 40, 40, 1) 51%
                );
              }

              &:nth-child(3) {
                background: rgb(137, 255, 101);
                background: linear-gradient(
                  80deg,
                  rgba(137, 255, 101, 1) 51%,
                  rgba(31, 132, 0, 1) 51%
                );
              }

              &:nth-child(4) {
                background: rgb(15, 132, 240);
                background: linear-gradient(
                  80deg,
                  rgba(15, 132, 240, 1) 51%,
                  rgba(220, 237, 253, 1) 51%
                );
              }
            }
          }

          button.round {
            max-width: fit-content;
            border-radius: 32px;
            min-width: 110px;
          }
        }

        @media (max-width: 600px) {
          height: auto;
        }
      }

      p {
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: 'Arima', system-ui;
        font-size: 20px;
        font-weight: 500;
        padding-top: 16px;
      }

      &:hover {
        .hover_panel {
          opacity: 1;
          z-index: 1;
        }
      }

      @media (max-width: 600px) {
        width: 45%;
      }
    }
  }
}

.tab_panel {
  min-height: var(--header-height);
  /* border-bottom:  var(--border); */
  box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #dfdfdf;
  position: relative;
  display: flex;
  align-items: center;

  .tab_listing {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0;
    margin: 0;
    position: relative;

    @media (max-width: 500px) {
      .exit-500 {
        display: none;
      }
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;

      a {
        display: flex;
        gap: 8px;
        align-items: center;
        text-decoration: none;
        color: var(--textGlobalColor);
      }

      &:hover {
        border-color: var(--textGlobalColor);
        cursor: pointer;
      }

      &.active {
        border-color: var(--orange);

        a {
          color: var(--orange);
        }
      }
    }

    &.mobile_wrap {
      li {
        display: inline-flex;
        gap: 12px;

        &:hover {
          border-color: transparent !important;
        }
      }
    }
  }

  .two_panel_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close,
    .search-icon {
      display: none;
    }
    .popup_items {
      // display: none !important;
      // position: absolute;
      // display: flex;
      flex-direction: column;
      inset: unset !important;
      right: 0 !important;
      top: 44px !important;
      min-width: 250px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 3px;
      z-index: 9;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      padding: 0;
      margin: 0;
      transform: unset !important;

      li {
        border-bottom: 1px solid #ccc;
        &.enter-501 {
          display: none;
        }
        &.disable {
          cursor: default !important;
          a {
            cursor: default;
            pointer-events: none;
          }
        }
        @media (max-width: 500px) {
          &.enter-501 {
            display: block;
          }
        }
        a {
          padding: 0 12px;
          height: 40px;
          display: flex;
          align-items: center;
          width: 100%;
          white-space: nowrap;
          &.active {
            background-color: #fff;
            color: #000;
          }
          &:hover {
            color: #fff;
            border-color: #bc8129 !important;

            background-image: linear-gradient(30deg, #bc8129 50%, #995e09 64%);
            background-size: 500px;
            background-repeat: no-repeat;
            background-position: 0%;
            -webkit-transition: background 200ms ease-in-out;
            transition: all 0.2s ease-out;
            background-position: 85%;
            color: #fff !important;
          }
        }
        &:last-child {
          border-bottom: 0;
        }
        &:hover {
          border-bottom: 1px solid #ccc !important;
        }
      }
      .close {
        display: inline-flex;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 24px;
        width: 31px;
        height: 32px;
        align-items: center;
        justify-content: center;
      }
      &.show {
        display: inline-flex !important;
      }
      span.disable {
        cursor: default !important;
      }
    }
    .filter_icon {
      width: 38px;
      height: 38px;
      border: var(--border);
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      &:hover {
        color: #fff;
        border-color: #bc8129 !important;

        background-image: linear-gradient(30deg, #bc8129 50%, #995e09 64%);
        background-size: 500px;
        background-repeat: no-repeat;
        background-position: 0%;
        -webkit-transition: background 300ms ease-in-out;
        transition: all 0.3s ease-out;
        background-position: 85%;
        color: #fff !important;
      }
    }
    @media (max-width: 1000px) {
      align-items: flex-start;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .mobile_wrap {
        flex: 1;
        width: 100%;
        justify-content: flex-end;

        .secoundry_navigation_mobile {
          position: relative;
          display: flex;

          align-items: flex-end;
          .popup_item_container {
            width: 320px;
            background-color: #fff;
            position: absolute;
            right: 0;
            z-index: 999;
            top: 42px;
            padding: 12px;
            padding-top: 24px;
            border: 1px solid #ccc;
            border-radius: 3px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            .close {
              position: absolute;
              right: 5px;
              top: -5px;
              font-size: 26px;
            }
          }
        }

        li {
          display: flex;
          justify-content: flex-end;
          &.for_only_desktop {
            .normal_search_panel {
              border-left-width: 0;
              .search_field {
                padding: 0;
                width: 0;
              }
              &.mobile_search_box {
                width: 100%;
                border-left-width: 1px;
                position: absolute;
                right: 0;
                background-color: #fff;
                z-index: 9;
                .search_field {
                  padding: 0 8px;
                  width: 100%;
                }
              }
            }
            .search-icon {
              display: inline-flex;
            }
            .with-icon {
              height: 38px;
              width: 38px;
              span {
                display: none;
              }
              i {
                font-size: 16px;
              }
            }
          }

          .normal_search_panel {
            .search_field {
              width: 100%;
              flex: 1;
            }
          }
        }
      }
    }
  }
}

// search input
.tab-row {
  .middle-row {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;

    .tab_listing {
      width: 100%;
    }

    .normal_search_panel {
      width: auto;
    }
  }

  @media (max-width: 1000px) {
    height: 100%;
    padding: 0;

    .middle-row {
      flex-direction: column;
      gap: 20px;

      .tab_listing {
        justify-content: center;
      }
    }
  }
}

.tab_panel_box {
  padding: 16px 0;

  .inner_content_panel {
    display: flex;
    gap: 32px;

    .left_panel {
      width: 100%;
      .events_card_container {
        display: grid;
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 576px) {
          grid-template-columns: repeat(1, 1fr);
        }

        @media (min-width: 577px) and (max-width: 991px) {
          grid-template-columns: repeat(2, 1fr);
        }
        .event_item {
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 16px 0px, rgba(44, 41, 37, 0.06) 0px 7px 27px 0px;
          color: #282828;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          background-image: url(../assets/img/eventDummy-bkg.png);
          background-repeat: no-repeat;
          background-position: right bottom;
          background-size: 100% auto;
          background-blend-mode: hard-light;
          .event_info {
            padding: 16px;
            padding-top: 0;
            flex: 1;
          }
          .e_name {
            font-size: 18px;
            font-weight: 600;
            color: var(--orange);
          }
          .e_details {
            display: flex;
            flex-direction: column;
            padding-top: 8px;
            gap: 8px;
            .with_icon {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
          .g_note {
            padding: 16px;
            padding-top: 0;
            span {
              font-size: 16px;
              font-weight: 600;
              padding-bottom: 8px;
              display: flex;
              width: 100;
            }
          }
          .ant-space-compact {
            justify-content: flex-end;
            padding: 12px;

            .ant-btn {
              border: 0;
              border-radius: 0;
              background-color: transparent;
              &:last-child {
                display: inline-flex;
                justify-content: flex-end;
              }
              span.anticon {
                transform: rotate(90deg);
                svg {
                  transform: scale(2);
                }
              }
            }
          }
        }
      }
    }

    .right_panel {
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      display: none;

      .inner_slider {
        padding: 12px;
        border-radius: 16px;
        background-color: red;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

        .box-desgin {
          line-height: 36px;
        }

        .swiper-slide {
          box-shadow: none !important;
          background-color: transparent;
        }

        .color_container {
          @extend %flex-center;
          justify-content: flex-start;
          background-color: rgba(255, 255, 255, 0.9);
          padding: 24px 0 32px 0;
          position: relative;

          div {
            display: inline-flex;
            gap: 12px;
          }

          span {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

// Dwawer event selection
.event_list_container {
  flex: 1;
  overflow: auto;

  .list_box {
    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
      padding-bottom: 16px;
    }
  }

  .list_text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    top: -4px;

    width: 100%;

    .event_text {
      font-weight: 100;
      font-size: 16px;
    }
  }
}
