.tab_panel_box {
  .inner_content_panel {
    .left_panel {
      .wedding_memories {
        margin-bottom: 30px;
        .wedding_top_details {
          padding: 10px 0;
          border-top: 1px solid #adadad;
          .sender_name {
            color: var(--orange);
            font-weight: 300;
          }
          span {
            color: #ccc;
            font-weight: 100;
          }
        }
        .gallery_memories {
          display: grid;
          width: 100%;
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          grid-gap: 25px;
          grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
          grid-auto-rows: 150px;
          grid-auto-flow: dense;
          position: relative;

          .gallery__item_memories {
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            overflow: hidden;
            border: 1px solid #adadad;

            &:nth-child(2) {
              grid-column: span 4;
              grid-row: span 2;
            }
            &:nth-child(3) {
              grid-column: span 2;
              grid-row: span 2;
            }
            &:nth-child(4) {
              grid-column: span 2;
              grid-row: span 2;
            }

            &::nth-child(n + 4) + img {
              height: 100%;
              object-fit: cover;
            }
            @media (max-width: 991px) {
              &:nth-child(2) {
                grid-column: auto;
                grid-row: auto;
              }
              &:nth-child(3) {
                grid-column: auto;
                grid-row: auto;
              }
              &:nth-child(4) {
                grid-column: auto;
                grid-row: auto;
              }
            }
          }
          .gallery__content_memories {
            position: absolute;
            inset: 0;
            margin: auto;
            top: 235px;
            display: flex;
            justify-content: center;
            z-index: 99;

            span.border-circle {
              background: #fff;
              border-radius: 8px;
              width: 500px;
              height: 200px;
              display: flex;
              justify-content: center;
              padding: 10px;
              align-items: center;
              text-align: center;
              font-weight: 300;
              font-size: 20px;
              padding: 15px;
              outline: 10px solid #ffffff;
              border: 5px solid var(--orange);
              background: #fffcf7;
              overflow-y: scroll;
            }
            @media (max-width: 479px) {
              position: static;
              width: 100% !important;
              height: 100%;
              .border-circle {
                width: 100% !important;
                height: 100% !important;
              }
            }
            @media (min-width: 480px) and (max-width: 991px) {
              position: absolute;
              top: 112px;
              .border-circle {
                width: 300px !important;
                height: 100px !important;
                font-size: 16px !important;
                padding: 10px !important;
              }
            }
          }
        }
      }
      .view_btn {
        display: flex;
        justify-content: center;
      }
    }
  }
}
