/**
 * Color Variables
 *
 * Defines color variables to maintain consistency and flexibility in the
 * application's default color scheme. Organized into primary, additional, and neutral
 * colors for easy reference and usage throughout the stylesheet.
 *
 * Primary Colors:
 * - $primary-color: Main color used for primary actions or emphasis.
 * - $secondary-color: Secondary color used for complementary elements.
 *
 * Additional Colors:
 * - $success-color: Color indicating success or positive actions.
 * - $danger-color: Color indicating danger or error situations.
 * - $warning-color: Color indicating warnings or alerts.
 * - $info-color: Color indicating informational messages or actions.
 *
 * Neutral Colors:
 * - $white: Pure white color.
 * - $black: Pure black color.
 * - $gray: Neutral gray color for general use.
 * - $light-gray: Light gray color for backgrounds or subtle elements.
 * - $dark-gray: Dark gray color for text or borders.
 * - $transparent: Fully transparent color.
 *
 * @author: Ganesh Ughade
 * @version: 1.0
 */

// Primary colors
$primary-color: #bc8129 !default;     // Main color used for primary actions or emphasis
$secondary-color: #6c757d !default;    // Secondary color used for complementary elements

// Additional colors
$success-color: #28a745 !default;     // Color indicating success or positive actions
$danger-color: #dc3545 !default;       // Color indicating danger or error situations
$warning-color: #ffc107 !default;      // Color indicating warnings or alerts
$info-color: #17a2b8 !default;         // Color indicating informational messages or actions

// Neutral colors
$white: #ffffff !default;             // Pure white color
$black: #000000 !default;             // Pure black color
$gray: #6c757d !default;               // Neutral gray color for general use
$light-gray: #f8f9fa !default;
$grayLighter: #e5e5e5 !default;           // Light gray color for backgrounds or subtle elements
$gray-midium: #393655 !default;   
$dark-gray: #343a40 !default;
$darker-gray: #1e1e1e !default; 
$extra-light-pink: #f9f5f0 !default;        // Dark gray color for text or borders
$light-pink: #fff7ec !default;
$light-medium-pink: #fffaf6 !default;
$notification: #fef8eb;

$transparent: transparent;     // Fully transparent color
