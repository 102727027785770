@use '../../abstracts/variables' as *;
@use '../../themes/default/variables' as *;
@use '../../abstracts/mixins' as *;
@use '../../themes/default/colors' as *;
@use '../../abstracts/breakpoints' as *;
@use '../../components/buttons' as *;

.video_invitation-preview {
  &.mobile_screen {
    .video {
      @include border-radius(0.5rem);
      width: auto;
      height: 470px;

      margin: 0 auto 16px auto;
    }
    .template_description {
      @include flex-direction(flex, flex-start, flex-start, column);
      gap: var(--s-12);
      &.additional {
        margin-top: var(--s-16);
      }
    }
    .template_name {
      @include flex-direction(flex, center, center, row);

      gap: var(--s-12);
      width: 100%;
      .price {
        font-weight: 600;
      }
    }
    .about_invite {
      width: 100%;
      overflow: hidden;
      .toggle-text_panel {
        display: flex;
        gap: var(--s-4);
        &.expanded {
          display: unset;
        }
      }
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &.expanded {
          overflow: unset;
          white-space: normal;
        }
      }
    }
    .button_container {
      @include flex-direction(flex, center, center, row);
      width: 100%;

      gap: var(--s-8);
      margin-bottom: var(--s-16);
    }
    .video_payment_method {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          font-size: var(--text-2);
        }
      }
    }
    .tags {
      span {
        font-size: var(--text-2);
      }
    }
    .additional_information {
      td {
        font-size: var(--text-2);
      }
    }
  }
  &.large_screen {
    ul {
      @include flex-direction(flex, flex-start, flex-start, column);

      gap: var(--s-6);
      li {
        font-size: var(--text-1);
      }
    }
    td {
      padding-right: var(--s-6);
      font-size: var(--text-1);
      padding-bottom: var(--s-6);
    }
    .box_direction {
      @include flex-direction(flex, flex-start, flex-start, row);
      gap: var(--s-40);
    }
    .video_container_large {
      width: 100%;
      max-width: 530px;

      .video {
        @include border-radius(1rem);
        width: 100%;
        height: auto;
      }
    }
    .about_video_description {
      @include flex-direction(flex, flex-start, flex-start, column);

      gap: var(--s-24);
      flex: 1;
      .about_invite {
        .button_container {
          display: flex;
          gap: var(--s-12);
          margin-top: var(--s-12);
        }
      }
    }
    .template_description.additional {
      @include flex-direction(flex, flex-start, flex-start, column);

      gap: var(--s-24);
      .video_payment_method {
        ul li {
          position: relative;
          padding-left: var(--s-24);
          &::before {
            background-color: #bc8129;
            border-radius: 15px;
            content: '';
            height: 15px;
            left: 0;
            position: absolute;
            top: 4px;
            width: 15px;
          }
          &::after {
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            content: '';
            height: 7px;
            left: 6px;
            position: absolute;
            top: 7px;
            transform: rotate(45deg);
            width: 4px;
          }
        }
        &.delivery {
          ul li {
            padding-left: 0 !important;
            &::after,
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
.video_invitations_faq {
  margin-bottom: var(--s-16);
  background-color: transparent;
  .accordion-button {
    font-size: 0.875rem;
  }
  .accordion-body {
    font-size: var(--text-2);
  }
  @include respond-to('laptop') {
    padding-bottom: 0;
    .accordion-button,
    .accordion-body {
      font-size: 1rem;
    }
  }
}
