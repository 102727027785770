.card_link_box {
  display: flex;
  flex-direction: column;
  border: var(--border);
  padding: 8px;
  border-radius: 3px;
  background-color: #fff;
  gap: 8px;
  max-width: 400px;
  // background-color: var(--sliderCardBKG);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: 100%;
  h1 {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
  }
  .box-align {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;

    a {
      color: #000;
    }
  }
}