@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../themes/default/colors' as *;
@use '../abstracts/breakpoints' as *;

$dropdown_background: '../../assets/img/v1/video-invite/invitation-bkg.png';
%dropdown_background {
  background-image: url($dropdown_background);
  background-size: cover;
  background-color: $light-medium-pink;
  background-position: left top;
  background-attachment: fixed;
}

.navbar {
  height: var(--s-56);
  border-bottom: 1px solid #ccc;
  @include respond-to('laptop') {
    border-bottom: 0;
  }
  &.is-sticky {
    background-color: $white;
    border-radius: 0;
    left: 0;
    z-index: $zindex-sticky;
    max-width: 100%;
    position: sticky;
    top: 0;
    transform: none;
    border-bottom: 1px solid #ccc;
  }
  .btn_group {
    @include flex-direction(inline-flex, center, center, row);
    gap: var(--s-8);
    .divider {
      width: 1px;
      height: var(--s-24);
      background-color: #acacac;
    }
  }
  /* Notification button */
  .notification_icon-button {
    @include flex-direction(inline-flex, center, center, row);

    position: relative;
    width: var(--s-36);
    height: var(--s-36);
    color: #333333;
    background: #ededed;
    border: var(--border);
    outline: none;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background: #cccccc;
    }
    &.shopping_cart {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white;
      margin-left: var(--s-16);
    }
  }

  .notification_icon-button__badge {
    @include flex-direction(inline-flex, center, center, row);

    position: absolute;
    top: 0;
    right: 0;
    width: var(--s-10);
    height: var(--s-10);
    background: $primary-color;
    color: $white;
    border-radius: 50%;
    font-size: var(--s-12);
    font-weight: 300;
  }
}

//Mobile first view
.header_user_action {
  @media screen and (max-width: 600px) {
    .user_dropdown {
      .user_name {
        display: none;
      }
    }
    .shopping_cart {
      margin-left: var(--s-6) !important;
    }
  }
}
.navbar-nav {
  flex: 1;
  justify-content: center;
  gap: var(--s-8);
  .nav-link {
    @include flex-direction(flex, center, center, row);

    gap: var(--s-8);
    color: inherit;
  }
  .menu_group {
    @include flex-direction(flex, center, center, row);

    gap: var(--s-8);
    br {
      display: none;
    }
  }
  .dropdown-toggle::after {
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    border: 0;
    vertical-align: unset;
    margin: 0;
  }
  .dropdown-menu {
    &.show {
      @include box-shadow(0 3px 4px rgba($black, 0.3));
      @include flex-direction(flex, flex-start, flex-start, column);

      gap: var(--s-12);
      padding: 0;
      top: 50px;
      li {
        width: 100%;
      }
    }
    .dropdown-item {
      height: 100%;
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        background-color: #fbf5eb;
        color: $black;
      }
      &.active {
        background-color: #fbf5eb;
        color: $black;
      }
      div {
        font-size: var(--s-18);
        line-height: unset;
        font-weight: 500;
        color: #414448;
        + span {
          font-size: var(--s-14);
          color: #414448;
        }
      }
    }
  }
}
.mobile_navigation {
  @include flex-direction(flex, center, center, row);
  @include box-shadow(0 var(--s-4) var(--s-12) rgba($black, 0.2));

  flex-direction: row !important;
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: var(--s-72);
  background-color: $white;
  z-index: $zindex-fixed;
  .float_overflow {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    bottom: var(--s-72);
    left: 0;
    display: none !important;
  }
  .nav-item {
    flex: 1;
    height: 100%;
    .nav-link {
      &.active {
        //background-color: lighten($primary-color, 40%);
        align-items: flex-start !important;
        color: $primary-color !important;
        height: 100%;
      }
    }

    .menu_group {
      @include flex-direction(flex, center, center, column);

      gap: var(--s-8);
      width: 100%;
      font-size: 11px;
      text-align: center;
      br {
        display: block;
      }
      .icon-mobile {
        font-size: var(--s-24);
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu {
      @extend %dropdown_background;

      position: fixed !important;
      transform: unset !important;
      inset: unset !important;

      inset: auto auto 0px 0px;
      margin: 0px;
      width: 100%;
      height: auto;
      border: 0;
      border-radius: 0;
      &.show {
        @include box-shadow(0 0 var(--s-18) rgba($black, 0.3));

        display: flex !important;
        flex-direction: column;
        bottom: var(--s-72) !important;
        right: 0 !important;
        overflow: hidden;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0;
        padding-bottom: var(--s-12);
        overflow-y: auto;
        scrollbar-width: none;
        + .float_overflow {
          display: block !important;
        }
      }

      .dropdown-item {
        height: 100%;
        padding-left: var(--s-12);
        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          background-color: #fbf5eb;
          color: $black;
        }
        &.active {
          background-color: #fbf5eb;
          color: $black;
        }
      }
      .menu_header_title {
        @include flex-direction(flex, flex-start, center, row);

        width: 100%;
        height: var(--s-40);
        border-bottom: 1px dashed $primary-color;
        background-color: $white;
        padding-left: var(--s-12);
      }
    }
  }
}
