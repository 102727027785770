@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

$vendor-banner-img: '../../assets/img/home_invitation_section.png';
$video-invitation-img: '../../assets/img/new_features.png';
$implify-wedding-img: '../../assets/img/l-t.png';
$items-bg: '../../assets/img/weding_card_graphic.png';
$why-viteplan: '../../assets/img/bubble-couple.png';
$Customize-your-wedding-bg: '../../assets/img/beach-2.jpg';

%wedding-invitation-process-bg {
  background-image: url($implify-wedding-img);
  background-size: cover, 200px auto;
  background-color: $light-pink;
  background-position: center center;
  background-repeat: no-repeat;
}

%items-bg {
  background-image: url($items-bg);
  background-color: $white;
  background-position: bottom center;
  background-repeat: no-repeat;
}

%why-viteplan-bg {
  background-image: url($why-viteplan);
  background-color: $light-pink;
  background-position: center;
  background-repeat: no-repeat;
}

%Customize-wedding-bg {
  background-image: url($Customize-your-wedding-bg);
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

// for banner page

.video-overly {
  &::after {
    content: '';
    background: #00000054;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.text-slider {
  position: absolute;
  max-width: calc(var(--s-56) * 10);
  left: var(--s-16);
  right: var(--s-16);
  z-index: 9;
  @include respond-to('tablet-portrait') {
    left: auto;
    right: auto;
  }
}

.hero-text-panel {
  text-align: center;
  h1,
  h3 {
    color: $white;
  }
}

.slick_slider_align .slick-arrow,
.slick_slider_align .slick-dots {
  display: none !important;
}

.sl-discription {
  margin: var(--s-8);
  @include respond-to('tablet') {
    margin: var(--s-24);
  }
}

// for Make your wedding invitation process easier. card
.wedding-invitation-process {
  padding: var(--s-56) 0;
  @extend %wedding-invitation-process-bg;
  .wedding_card_new_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--s-24);

    @include respond-to('tablet-portrait') {
      grid-template-columns: repeat(2, 1fr);
      gap: var(--s-24);
    }

    @include respond-to('tablet') {
      grid-template-columns: repeat(3, 1fr);
    }

    .item {
      padding: var(--s-16);
      @include border-radius(1rem);
      @include box-shadow(0 2px 4px rgba($black, 0.1));
      @include flex-direction(flex, flex-start, center, column);
      @extend %items-bg;
      flex-basis: calc(100% / 3 - 16px);
      transform: scale(1);
      gap: var(--s-16);
      transition: all 0.5s ease-out;

      img {
        scale: 0.8;
        opacity: 0;
        animation: fade-in linear forwards;
        animation-timeline: view();
        animation-range: contain;
      }
      @keyframes fade-in {
        to {
          scale: 1;
          opacity: 1;
        }
      }

      a {
        color: var(--textGlobalColor);
        text-decoration: none;
        display: flex;
        flex-direction: column;
        gap: var(--s-24);
      }

      .item_title {
        display: flex;
        justify-content: space-between;
        min-height: var(--s-24);
        font-size: 1.2rem;
        font-weight: 500;
        color: $primary-color;
      }

      p {
        font-size: 1.125rem;
        font-weight: 400;
      }

      &:hover {
        @include box-shadow(0px 0 12px rgba($black, 0.5));
        transform: scale(1.01);
        transition: all 0.5s ease-out;
        i {
          color: $danger-color;
        }
      }
    }
  }
}

.video_on_youtube {
  padding: var(--s-56) 0;
  background-color: $white;
  font-family: 'Arima', system-ui;

  iframe {
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 100%;
  }
}

//for You chose wisely before; Now choose Viteplan as your assistant. box
section.why_viteplan {
  @extend %why-viteplan-bg;
  padding: var(--s-56) 0;
  text-align: center;
  .tag_line {
    margin-top: var(--s-16);
    margin-bottom: var(--s-24);
    font-weight: 600;
    font-size: 1.625rem;
  }
  p {
    font-size: 1rem;
  }
  .slider_with_image {
    .slider_container {
      display: flex;
      gap: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
    }

    .vertical_slider {
      .slider_item {
        width: 100%;

        overflow: hidden !important;
        display: block;
        border: 2px solid #e6e6e6;
        background: #fff;
        border-radius: 15px;
        cursor: pointer;
        margin: 0;
        padding: 20px;
        color: #000;
        position: relative;
        transition: all 0.3s ease;
        width: 100%;
        max-width: 600px;
        float: right;
        text-align: left;
      }
      .slick-current {
        .slider_item {
          border-color: #bc8129 !important;
          box-shadow: inset;
        }
      }
      &.only_text {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        width: 100%;
        .slick-slider {
          width: 100%;
          max-width: 400px;
          img {
            width: 90%;
            height: auto;
            margin: 0 auto;
          }
          &.card_box {
            width: 100%;
            max-width: 100%;
          }
          @include respond-to('tablet-portrait') {
            max-width: 600px;
            img {
              margin: unset;
              width: 85%;
            }
            &.card_box {
              width: 100%;
              max-width: 600px;
            }
          }
        }

        @include respond-to('tablet-portrait') {
          flex-direction: row;
        }
      }
      .slick-dots {
        li {
          button {
            width: var(--s-16);
            height: var(--s-16);
            &::before {
              color: #7d7878;
              font-size: 12px;
            }
          }
          &.slick-active {
            button {
              &::before {
                color: #bc8129;
              }
            }
          }
        }
      }
    }
  }
}

//Select and Customize Your Wedding Invitations
.home_customize_wedding {
  @extend %Customize-wedding-bg;
  padding: var(--s-72) 0;
  font-family: 'Arima', system-ui;
  position: relative;

  .box-mm {
    @include border-radius(1rem);
    background-color: rgba(255, 255, 255, 0.8);
    padding: var(--s-24);
    margin: var(--s-24);
    width: calc(100% - var(--s-16) * 3);
    @include respond-to('tablet-portrait') {
      max-width: 600px;
      padding: var(--s-56);
    }
  }

  p {
    font-size: 1.375rem;
    font-weight: 400;
  }

  h1 {
    color: $primary-color;
  }

  a.round {
    @include border-radius(0.25rem);
    align-self: flex-start;
    display: inline-flex;
    gap: var(--s-8);
    font-family: 'Arima', system-ui;
    font-size: 1.125rem;
    font-weight: 500;
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color !important;
  }
}

.vendor,
.invitations {
  padding: var(--s-36) 0;
  @include respond-to('tablet-portrait') {
    padding: var(--s-72) 0;
  }
}
