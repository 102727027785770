* {
  margin: 0;
  padding: 0;
}

.website_outer_container {
  p {
    line-height: 28px;
    padding-top: 16px;
    text-align: center;
    font-weight: unset;
  }

  h1,
  h2,
  h3,
  h4,
  h6 {
    margin: 0;
    text-align: center;
  }

  h1 {
    font-size: 45px;
    font-weight: 500;
    margin: 20px 0;
  }

  h2 {
    font-size: 35px;
    font-weight: 600;
    margin: 20px 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h4 {
    font-weight: 400;
    font-size: 20px;
    margin: 20px 0;
  }

  h6 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  @media (max-width: 991px) {
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
    }

    h3 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
}

.wedding_date {
  display: none;
}
.event_date_only {
  display: none;
}
.website_outer_container {
  #wedding_website_view {
    &:has(.invitation_user_view) {
      padding-top: 0 !important;
    }
  }
}

.website_outer_container {
  #wedding_website_view {
    &:has(.header-top) {
      header {
        &:has(nav) {
          margin: 0 !important;
        }
      }
    }
  }
}

.login_fullPage_container {
  width: 100% !important;
  height: unset !important;
  min-height: 100vh;
}

.accordion-body,
.website_outer_container {
  width: 100%;
  #wedding_website_view {
    font-size: 16px;
    overflow-x: hidden;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 0 15px;
    header {
      margin-bottom: 20px !important;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      @media (max-width: 991px) {
        margin-top: 50px;
      }
    }

    .header-top {
      h1.name-heading {
        font-size: 50px;
      }
      img {
        width: 150px;
        margin-bottom: 0;
      }
    }

    .left-right-wrap {
      overflow-wrap: break-word;
      justify-content: center !important;
      @media (max-width: 950px) {
        flex-direction: column !important;
      }

      .left,
      .right {
        width: 100%;
        max-width: 500px;
        p {
          font-size: 16px !important;
          white-space: pre-line;
        }
        h1 {
          font-size: 30px;
        }
        @media (max-width: 950px) {
          width: 100%;
          max-width: 100% !important;
        }
        @media (min-width: 951px) and (max-width: 1185px) {
          width: 100%;
          max-width: 370px;
        }
      }
    }

    .header-top {
      margin-top: 50px;
      img {
        width: 150px;
        margin-bottom: 0;
      }
    }
  }

  .website_top_venue {
    line-height: 28px;
    color: #fff !important;
  }

  .couple_banner {
    // border-radius: 10px;
    //  box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
    margin: 40px auto;

    @media (min-width: 768px) and (max-width: 1378px) {
      max-width: 650px !important;
      width: 100%;
    }
  }

  img {
    margin: 24px auto;
    display: inherit;
  }

  .invitation_user_view {
    h6,
    span {
      font-size: 16px;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 24px;
    }

    .invitation-banner {
      background-color: transparent;
      padding: 40px 0 0;

      .btn-style {
        font-size: 18px;
        margin-top: 20px;
      }

      .envolape {
        display: none;
      }

      .invitation-image {
        position: relative;
        width: 670px;
        width: 100%;
        height: auto;
        max-width: 700px;

        .invitation_card_view {
          position: relative;
          right: -125px;
          top: 0;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

          @media (max-width: 991px) {
            right: unset !important;
          }

          @media (max-width: 991px) {
            box-shadow: none !important;
          }

          .invitation_wraaper_box {
            @media (max-width: 991px) {
              width: 100% !important;
              background-position: center;
              margin: auto;
              padding: 20px;
              height: 100% !important;
              p {
                width: auto;
              }

              .invitation_title {
                font-size: 25px !important;
              }
            }
          }
        }
      }
    }

    .invitation_title {
      font-size: 48px;

      @media (max-width: 776px) {
        font-size: 25px !important;
      }
    }

    .btn-style {
      height: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      min-width: 200px;
      text-decoration: none;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin-top: 30px;
    }

    .events-rsvp_main {
      padding: 0 15px;
      position: relative;
    }

    @media only screen and (max-width: 991px) {
      .invitation-banner {
        padding: 40px 0 0;

        .container {
          margin: 0;
          padding: 0;
        }

        .invitation-image {
          width: 100%;
          padding-top: 0;
          background-image: none !important;

          .envolape {
            display: none;
          }

          .invitation_card_view {
            position: relative;
            width: 100%;

            #mycanvas {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  // Guest Invitation style end

  @media only screen and (min-width: 992px) {
    .offcanvas {
      visibility: visible !important;
    }
  }

  @media only screen and (max-width: 991px) {
    .navbar {
      width: 100%;
      position: fixed !important;
      top: 0 !important;
      height: 65px;
      z-index: 999;
    }

    #wedding_website_view {
      .offcanvas {
        visibility: hidden;
        width: 100%;

        .offcanvas-header {
          position: absolute;
          right: 8px;
          top: 0;
          display: block;
          width: 40px;
          height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          button {
            background-image: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            filter: unset;
            width: 40px;
            height: 40px;
            margin: 0;
            padding: 0;
            opacity: 1;

            &::before {
              content: '\f00d';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 900;
              color: #000;
              position: absolute;

              font-size: 24px;
            }
          }
        }
      }

      @media (max-width: 991px) {
        img {
          width: auto !important;
        }

        .header-top {
          img {
            width: 60px !important;
          }

          .name-heading {
            font-size: 30px !important;
          }
        }
      }
    }

    header {
      justify-content: center !important;
      width: 100%;
      margin-bottom: 40px;
    }

    // .navbar-toggler {
    //   height: 40px;
    //   width: 40px;
    //   position: fixed;
    //   top: 7px;
    //   right: 30px;
    //   border: 0;
    //   z-index: 999;

    //   &::before {
    //     content: '\f0c9';
    //     font-family: 'Font Awesome 5 Pro';

    //     position: absolute;
    //     font-size: 20px;
    //     padding: 7px;
    //   }

    //   &:focus {
    //     border: none;
    //     outline: none;
    //     box-shadow: none;
    //   }
    // }

    .modal-backdrop {
      opacity: 0.9;
      background-color: #fff;
      z-index: 999;
    }

    .navbar-nav {
      gap: 10px !important;
    }
  }

  .rsvp_container_main {
    @media only screen and (max-width: 991px) {
      padding: 0 10px !important;
      margin-bottom: 16px;

      .rsvp_form_container .all_invited_Guests li {
        flex-direction: column;
        align-items: flex-start;

        span {
          flex: unset !important;
          width: 100%;

          &.field_container {
            width: 100% !important;
          }
        }

        &::before {
          display: none !important;
        }

        .btn {
          background-color: transparent;
        }
      }
    }
    @media only screen and (max-width: 576px) {
      .guest_selction {
        tr {
          display: flex;
          flex-direction: column;
        }
      }

      .event_rsvp_container {
        .event_list {
          flex-direction: column !important;
        }
      }
    }
  }

  .event_container {
    margin: 0 10px;

    @media only screen and (max-width: 900px) {
      &.vertical {
        display: flex;
        flex-direction: column;
        gap: 25px !important;
        align-items: center;
      }
    }
  }

  // This will be changed based on theme
  .page_title {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-bottom: 60px;
  }

  // Nav bar Css
  .offcanvas-body {
    overflow: unset !important;
    padding: 5px;
  }

  .offcanvas-end {
    border: 0;
  }

  .navbar {
    .offcanvas-body {
      overflow-y: unset !important;

      .navbar-nav {
        gap: 48px;
        margin-bottom: 20px;

        .nav-item {
          position: relative;
          border-bottom: 3px solid transparent;

          .nav-link {
            background-color: transparent;
            white-space: nowrap;
          }

          .dropdown-menu {
            position: absolute !important;

            border-radius: 5px;
            list-style: none;
            padding: 0;
            margin: 0;
            max-width: fit-content;
            right: 0px;
            box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 8px 0px;
            left: unset;
            transition: none !important;

            li {
              min-height: 40px;
              display: flex;
              align-items: center;
              padding: 0 12px;
              cursor: pointer !important;
              font-size: 14px;
            }
          }

          // .submenu_container {
          //   position: absolute;
          //   border-radius: 5px;
          //   list-style: none;
          //   padding: 0;
          //   margin: 0;
          //   min-width: 200px;
          //   left: 0px;
          //   box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 8px 0px;
          //   // z-index: -9;
          //   // opacity: 0;
          //   transition: all 0.5s ease-out;
          //   color: #fff;

          //   li {
          //     min-height: 40px;
          //     display: flex;
          //     align-items: center;
          //     padding: 0 12px;
          //     cursor: pointer;
          //     font-size: 14px;
          //   }
          // }

          // &:hover {
          //   .submenu_container {
          //     opacity: 1;
          //     z-index: 9;
          //     transition: all 1s ease-out;
          //   }
          // }
        }
      }
    }
  }

  .event_container {
    text-align: center;
    position: relative;

    &.vertical {
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: center;
    }

    &.horizontal {
      display: flex;
      gap: 24px;
      justify-content: center;

      .event_card {
        margin: 0;
      }
    }

    // This will be changed based on theme
    .event_card {
      text-align: center;
      padding: 40px;
      width: 100%;
      max-width: 700px;
      border-radius: 0px;
      margin: auto;
      font-size: 16px;
      margin-bottom: 0px;
      border-radius: 0;
      position: relative;
      z-index: 99;
      overflow: hidden;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 20px;

      .event_wrap {
        padding: 20px;

        @media (max-width: 767px) {
          padding: 20px 20px 20px;
          border-radius: 10px;
        }
      }

      h5.event_column {
        margin-bottom: 0px;
        margin-top: 20px;
        display: inline-block;
        padding-bottom: 5px !important;
        border-radius: 0px;
        background-color: transparent;
        padding: 0;
      }

      .guest-note {
        strong {
          color: #5d074e !important;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      h3 {
        margin-bottom: 16px;
        margin-top: 0;
      }

      p {
        padding: 0px;
        margin: 0;
        line-height: 1.5;
      }

      .table {
        margin: 20px 0;

        tr {
          display: flex;
          justify-content: center;
          text-align: left;
          border-bottom: none;
          padding: 10px;

          th,
          td {
            font-size: 15px;
            padding: 2px;
            font-weight: 600;
            width: 33%;
            overflow-wrap: break-word;
          }
        }
      }

      .event-name {
        font-size: 40px !important;
        font-weight: 400;
      }

      .event-notes {
        font-size: 18px !important;
        white-space: pre-line;
        line-height: 1.3;

        .couple_name {
          font-size: 30px;
        }
      }

      .event-shedule {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        background-size: 70px;
        max-width: 450px !important;
        padding: 30px;

        .table {
          tr {
            flex-direction: column;

            th,
            td {
              width: 100%;
              overflow-wrap: break-word;
            }
          }
        }

        .event_column {
          font-size: 16px !important;
        }

        p {
          font-size: 20px;
        }

        .event-name {
          font-size: 35px !important;

          &::before {
            width: 60px;
            height: 50px;
          }
        }

        .event-notes {
          font-size: 15px !important;
        }

        .event-shedule {
          font-size: 15px !important;
        }

        .event-location {
          font-size: 15px !important;
        }
      }

      @media (min-width: 578px) and (max-width: 767px) {
        max-width: 650px;

        p {
          font-size: 20px !important;
        }

        .event-name {
          font-size: 35px !important;

          &::before {
            width: 60px;
            height: 50px;
          }
        }

        .event-notes {
          font-size: 16px !important;
        }

        .event-shedule {
          font-size: 20px !important;
        }

        .event-location {
          font-size: 20px !important;
        }
      }

      @media (min-width: 768px) and (max-width: 1125px) {
        max-width: 670px;

        p {
          font-size: 20px !important;
        }

        .event-name {
          font-size: 35px !important;

          &::before {
            width: 60px;
            height: 50px;
          }
        }

        .event-notes {
          font-size: 16px !important;
        }

        .event-shedule {
          font-size: 20px !important;
        }

        .event-location {
          font-size: 20px !important;
        }
      }
    }
  }

  .rsvp_container_main {
    position: relative;
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 10px;
    margin: 32px auto;
    margin: auto;

    padding: 0 30px;

    * {
      margin: 0;
      border: 0;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -o-box-sizing: border-box;
      -ms-box-sizing: border-box;
    }

    .guest_selction {
      td {
        padding-bottom: 8px;
      }
    }

    .rsvp_form_container {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      padding: 16px;

      p {
        text-align: left;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .all_invited_Guests {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-bottom: 16px;
        margin-bottom: 16px;

        li {
          span {
            input {
              background-color: #fff !important;
              border: none;
              color: #000 !important;
              font-weight: 400 !important;

              &::placeholder {
                font-weight: 400 !important;
              }
            }

            .phone_input_country {
              background: #fff !important;
              height: 100%;
              gap: 4px;
              padding: 0 10px 0;
              height: 36px;
              .PhoneInputCountry {
                width: 28px;
                min-width: 28px;
              }

              input.PhoneInputInput {
                font-weight: 400 !important;
                height: 100%;
                background: transparent;
                color: #000 !important;
                width: 100%;

                .PhoneInputCountryIconImg {
                  margin: 0 !important;
                }

                &:read-only {
                  background-color: #f7f7f7 !important;
                  border-color: #ccc;
                  color: #9f9797;
                  cursor: default;
                }

                .phone_input_country {
                  background: #fff;
                  height: 100%;
                  gap: 4px;
                  padding: 0 10px 0;
                  height: 36px;

                  .PhoneInputCountry {
                    width: 28px;
                    min-width: 28px;
                  }

                  input.PhoneInputInput {
                    height: 100%;

                    width: 100%;

                    &:focus-visible {
                      outline: none;
                    }
                  }
                }
              }
            }
          }

          &::before {
            content: none !important;
          }
        }

        .add-more-guest {
          display: flex;
          justify-content: center;
          gap: 12px;
          margin: 15px 0 !important;

          .add-guest-btn {
            font-size: 18px !important;
            padding: 8px 14px;
            border-radius: 8px;
            cursor: pointer !important;
            border: none;

            i {
              font-size: 25px !important;
            }

            &:disabled {
              color: #000;
              cursor: not-allowed !important;
              opacity: 0.6;
            }
          }

          .update-guest-btn {
            padding: 8px 14px;
            border-radius: 8px;
            cursor: pointer !important;
            color: #fff;
          }

          @media (max-width: 480px) {
            flex-direction: column;
          }
        }

        .mailing_address-container {
          .mailing_address {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 10px;
            width: 100%;

            span.input_fields_row {
              width: 48% !important;

              label.field_title {
                padding-bottom: 5px !important;
              }

              input {
                font-size: 15px;
                font-weight: 400;
              }

              @media (max-width: 567px) {
                &.w-mobile-100 {
                  width: 100% !important;
                }
              }
            }

            .input_fields_row {
              input,
              .select-dropdown-container {
                font-weight: 400;
              }
            }
          }
        }

        li {
          position: relative;
          display: flex;
          align-items: center;
          font-size: 16px;
          padding: 0 0 10px 0;
          gap: 10px;

          .btn {
            top: -10px;
            margin-top: 20px !important;
            margin: auto;

            @media (max-width: 991px) {
              top: 0 !important;
            }
          }

          span {
            flex: 1;

            height: 36px;
            font-size: 13px;
            border-radius: 3px;

            .field_error {
              border: none !important;
              font-size: 12px;
            }

            input.input_field {
              padding: 0 10px;
              border: 0;
              height: 100%;
              background-color: transparent;
              font-weight: 600;

              &::placeholder {
                opacity: 1;
                /* Firefox */
              }

              &:focus {
                outline: none;
              }
            }

            @media (max-width: 991px) {
              height: unset;

              input.input_field {
                height: 36px;
              }
            }
          }

          &:before {
            content: attr(count);
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            border-radius: 50%;
            border: 1px solid #c8ac54;
          }
        }
      }
    }

    .event_rsvp_container {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .event_list {
        padding: 10px;
        // background-color: #5e858e;
        flex-direction: column;

        h3 {
          text-align: left;
          margin-bottom: 8px;
        }

        p {
          font-weight: 400;
        }

        .guest_selction {
          table {
            width: 100%;

            tr {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }

        $dark: #292929;
        $light: #efefef;
        $red: #d91e18;
        $green: #2ecc71;
        $blue: #4183d7;
        $small: 40em;

        .button-label {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: unset;
          padding-left: 0;
          width: 60px;
          height: 40px;
          margin-right: 10px;
          cursor: pointer;
          color: $dark;
          border-radius: 0.25em;
          font-size: 13px;
          background: $light;
          box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.22);
          transition: 0.3s;
          user-select: none;

          &::before {
            opacity: 0;
          }

          span {
            font-size: 14px;
            margin: 0;
          }

          &:hover {
            background: darken($light, 10%);
            color: darken($dark, 10%);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.32);
          }

          &:active {
            transform: translateY(2px);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), inset 0px -1px 0 rgba(0, 0, 0, 0.22);
          }
        }

        .yes-button:checked + .button-label {
          background: $green;
          color: #fff;

          &:hover {
            background: darken($green, 5%);
            color: darken($light, 5%);
          }
        }

        .no-button:checked + .button-label {
          background: $red;
          color: #fff;

          &:hover {
            background: darken($red, 5%);
            color: darken($light, 5%);
          }
        }

        .maybe-button:checked + .button-label {
          background: $blue;
          color: #fff;

          &:hover {
            background: darken($blue, 5%);
            color: darken($light, 5%);
          }
        }

        .hidden {
          display: none;
        }

        &.horizontal {
          display: flex;
          align-items: center;
          gap: 24px;

          .guest_selction {
            padding-left: 24px;
            flex: 1.5;
          }

          .button-label {
            width: 40px;
            border-radius: 50%;
            font-size: 0px;
          }

          .event_name_day {
            flex: 1;
          }

          .yes-button + .button-label {
            &::after {
              content: '\f164';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 300;
              font-size: 18px;
            }
          }

          .no-button + .button-label {
            &::after {
              content: '\f165';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 300;
              font-size: 18px;
            }
          }

          .maybe-button + .button-label {
            &::after {
              content: '\f06a';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 300;
              font-size: 18px;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      margin-top: 16px;
      justify-content: center;
      padding: 16px 16px 0 16px;

      button {
        width: 250px;
        font-size: 14px;

        font-weight: 600;
        text-align: center;

        background: transparent;
        text-decoration: none;
        display: inline-block;
        letter-spacing: 1px;
        border-radius: 3px;
        text-transform: uppercase;
        padding: 8px 25px;
      }
    }
  }
}

.unauthorized_container {
  font-size: 16px;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .unauthorized_content {
    z-index: 9;

    h1 {
      @media (max-width: 767px) {
        text-align: center !important;
      }
    }

    h3 {
      font-size: 50px !important;
    }

    p {
      @media (max-width: 767px) {
        text-align: center;
      }
    }

    form {
      .enter-email {
        input {
          height: 40px !important;
          border-radius: 30px;
          font-weight: 400;

          &::placeholder {
            opacity: 1;
            /* Firefox */
          }
        }
      }

      .btn {
        &.active {
          border-radius: 30px;
          width: 100%;
          font-weight: 400;
        }
      }
    }

    .btn-otp {
      .submit-btn {
        border-radius: 30px;
        padding: 12px;
        border: none;
        width: 100px;
      }

      .resend-btn.active {
        background: transparent;
        border: none;
        font-size: 14px;

        font-weight: 600;
        cursor: pointer !important;
        text-decoration: underline;
        margin-left: 5px;
      }
    }
  }
}

.website_outer_container {
  .btn-style {
    a {
      height: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      min-width: 200px;
      text-decoration: none;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
  }
}
