.message_drawer {
  .ant-drawer-header {
    padding: 12px;
  }

  .ant-drawer-body {
    padding: 0;
    background-color: #eaedf2;
    display: flex;
    height: calc(100vh - 56px);
    overflow: hidden;
    @extend .message_list_container;
  }
}

// Create new Invitation popup
.create_invitation-new {
  .create_invitation {
    padding: 0;

    .new_invitaion_list {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;

      .invitation-box {
        height: 100%;
        max-width: 48%;
        transition: transform 0.2s;
        .invitation_card_show {
          height: 100%;
          box-sizing: border-box;

          img {
            height: 100%;
            min-height: 200px;
          }

          .content-details {
            .btn {
              font-size: 12px;
            }
          }
        }

        .card_text_panel {
          h2 {
            font-size: 13px !important;
          }
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
.ant-drawer-content-wrapper,
.individual_message_box {
  .message_list_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .top_header {
      width: 100%;
      height: 56px;
      min-height: 56px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 12px;
      gap: 12px;
      border-bottom: var(--border);

      .btn {
        background-color: #fff;
      }
    }

    .chatbox_container {
      width: 100%;
      display: flex;
      overflow: hidden;
      height: 100%;
      padding: 16px;
      gap: 16px;

      .user_avtar {
        width: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border: var(--border);
        border-radius: 50%;
        overflow: hidden;
        margin-top: 0px;

        i {
          font-size: 24px;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }

      .circle_btn {
        width: 32px;
        height: 32px;
        background-color: var(--orange);
        color: #fff;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.file {
          background-color: #fff;
          color: #000;
          border: var(--border);
        }
      }

      .left_panel {
        max-width: 360px;
        min-width: 320px;
        border-radius: 12px;
        overflow: hidden;
        height: auto;
        background-color: #fff;
        border: var(--border);
        display: flex;
        flex-direction: column;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .header {
          width: calc(100% - 24px);
          height: 40px;
          min-height: 40px;
          border: var(--border);
          border-radius: 3px;
          overflow: hidden;
          background-color: #f2f2f2;
          margin: 12px;
          z-index: 9;
          .normal_search_panel {
            border: 0;
          }
        }

        .message_list {
          width: 100%;
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          border-top: var(--border);

          .user_container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            padding: 5px 12px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            padding: 8px;

            cursor: pointer !important;

            .fa-check-double,
            .number {
              display: none;
            }

            .user_info {
              width: 100%;
              overflow: hidden;
              flex-direction: column;

              .user_name_time {
                display: flex;
                width: 100%;
                overflow: hidden;
                justify-content: space-between;
                gap: 8px;
                align-items: center;

                .name {
                  font-size: 16px;
                  color: #7a7a7a;
                  width: 100%;
                  white-space: nowrap;
                  align-items: center;
                  gap: 4px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  .truncate {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                &.with_message_status {
                  .text {
                    font-size: 14px;
                    color: #7a7a7a;
                    font-weight: 300;
                  }

                  .number {
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 50%;
                    font-size: 12px;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    background-color: var(--orange);
                  }
                }
              }
            }

            &.unseen {
              .number {
                display: inline-flex;
              }
            }

            &.unread {
              .fa-check-double {
                color: #34b7f1;
                display: inline;
              }
            }

            &.read {
              .fa-check-double {
                color: inherit;
                display: inline;
              }
            }

            &.active {
              background-color: #e7e7e7;
              border-top: 1px solid #ccc;
              border-bottom: 1px solid #ccc;

              .user_avtar {
                border-color: #000;
                color: #000;
              }

              .user_name_time {
                color: #000;

                .name {
                  color: #000 !important;
                }
              }
            }
            .time {
              font-size: 12px;
              color: #8d8d8d;
            }
          }
        }

        .left_footer {
          width: 100%;
          height: 56px;
          min-height: 56px;
          border-top: var(--border);
          background-color: #fff;
          padding: 12px;
          display: flex;
          align-items: center;
        }
        @media (max-width: 767px) {
          width: 100%;
          max-width: unset !important;
          min-width: unset !important;
          border-radius: 0 !important;
        }
      }

      .right_panel {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        overflow: hidden;
        border: var(--border);
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .top_header {
          width: 100%;
          height: 66px;
          min-height: 66px;
          border-bottom: var(--border);
          background-color: #fff;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .arrow-back {
            display: none;
          }

          .user_avtar {
            margin-top: 0 !important;
          }

          .user_description {
            .user_name {
              font-size: 18px;
            }
          }
        }

        .show_individual_message_box {
          flex: 1;
          height: 100%;
          overflow: auto;
          background-color: #fff;
          padding: 12px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          overflow-y: scroll;
          height: 100%;

          .user_status_chat {
            min-height: 48px;
            display: inline-flex;
            align-items: center;
            gap: 12px;

            .day_and_time {
              color: #ccc;
            }
          }

          .chatbox_text {
            max-width: 400px;
            min-height: 50px;
            border: var(--border);
            border-radius: 0 6px 6px 6px;
            padding: 12px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          }

          .sender {
            display: inline-flex;
          }

          .receiver {
            display: inline-flex;
            justify-content: flex-start;
            flex-direction: row-reverse;

            /* .fit-to-content {
            width: 100%;
            justify-content: flex-end;
            align-items: end;
          } */

            .chatbox_text {
              border-radius: 6px 0px 6px 6px;
              background-color: #ffe8e0;
            }

            .user_status_chat {
              flex-direction: row-reverse;
            }
          }
        }

        .typebox_container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          padding: 12px;
          height: 56px;
          min-height: 56px;
          border-top: var(--border);
          background-color: #fff;

          .textbox_field {
            width: 100%;
            height: 100%;
            border: none;
            outline: none !important;
            background-color: transparent;

            &:focus {
              border: none;
              outline: none;
            }
          }
        }

        &.hide {
          display: none;
        }

        &.show {
          display: flex;
        }

        &.new_messages {
          .event_text {
            display: flex;
            gap: 12px;
            align-items: center;
            font-size: 15px;
            height: 40px;
            font-weight: 300;

            i {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              border: var(--border);
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 13px;
              color: #393655;
            }
          }
        }
      }
      @media (max-width: 767px) {
        padding: 0 !important;
      }
    }
  }
  @media (max-width: 767px) {
    width: 100% !important;
    .right_panel {
      &.chat-mobile {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10001;
        transform: translateX(100%);
        display: none;
        transition: transform 0.3s ease-in-out 0.1s;
      }
      &.chat-show {
        display: block;
        transform: translateX(0%);
        border-radius: 0 !important;
      }
      .top_header {
        .arrow-back {
          display: block !important;
          i {
            font-size: 25px;
            color: var(--textGlobalColor);
          }
        }
      }
    }
  }
}

.root_container {
  .message_list_container .chatbox_container .right_panel,
  .message_drawer .ant-drawer-body .chatbox_container .right_panel {
    height: unset;
    flex: 1;
    @media (max-width: 767px) {
      height: 100% !important;
    }
  }

  .message_list_container {
    .chatbox_container {
      height: 88vh;
    }
  }
}

.individual_message_box {
  padding: 50px 0 0;

  h1 {
    font-weight: 100;
    span.user_name {
      color: var(--orange);
      border-radius: 8px;
      padding: 5px;
      font-weight: 600;
    }
  }

  .top_nav_message {
    .nav {
      .nav-item {
        .nav-link {
          color: #bdbdbd;
          position: relative;
          padding-bottom: 0;
          &.active {
            color: #000;
            &::after {
              content: '';
              width: 100%;
              height: 4px;
              background-color: var(--orange);
              display: block;
             
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .nav {
        .nav-item {
          .nav-link {
            padding: 6px !important;
            font-size: 15px;
          }
        }
      }
    }
  }

  .general_info_box {
    h2 {
      margin: 30px 0 20px 0;
    }
    .card {
      padding: 20px;
      border-radius: 10px;

      span.left_img {
        border-radius: 50%;
        width: 80px;
        display: block;
        overflow: hidden;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card-body {
        .card-text {
          color: #8d8d8d;
        }
        .contact_box {
          display: flex;
          gap: 20px;

          span {
            font-size: 16px;
            display: flex;
            align-items: center;

            i {
              color: #f96328;
              font-size: 18px;
              margin-right: 6px;
            }
            a {
              color: #8d8d8d;
              text-decoration: none;
              font-weight: 100;
            }
          }
        }
      }
    }
  }

  .invitations_box {
    h2 {
      margin: 30px 0 20px 0;
    }
    .card {
      border-radius: 10px;

      .card-body {
        a {
          font-style: italic;
        }
      }
      .card-footer {
        border-top: none;
      }
    }
  }
  @media (max-width: 567px) {
    padding: 20px 0 0;
    h1 {
      font-size: 16px;
    }
  }
}

.content-container {
  .individual_message_box {
    padding-top: 0 !important;
  }

  .slam_book {
    padding-top: 0 !important;
  }
  .top_content {
    h1 {
      &::after {
        content: none;
      }
    }
  }
}
