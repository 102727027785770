@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

.guest_grid_table {
  @include flex-direction(flex, unset, unset, row);

  flex-wrap: wrap;
  gap: var(--s-20);

  height: 100vh;
  max-height: 1000px;
  overflow: auto;
  align-content: flex-start;
  scrollbar-width: none;
  @include respond-to('laptop') {
    padding: var(--s-16);
    scrollbar-width: unset;
  }
  .guest_grid_box {
    border-radius: 8px;
    border: 2px solid #e3e3e3;
    overflow: hidden;
    width: 100%;
    .guest_grid_top {
      background: #f4f4f4;
      padding: 12px;
      padding-bottom: 20px;
      @include flex-direction(flex, space-between, center, row);
      .guest_grid_left {
        @include flex-direction(flex, space-between, flex-start, row);

        align-items: flex-start;
        gap: var(--s-14);
        .guest_new_information {
          .guest_name_panel {
            .guest_name {
              color: #393655;
              font-weight: 600;
            }
            .guest_email,
            .guest_phone {
              color: #393655;
              font-weight: 400;
            }
          }
        }
      }
      .guest_grid_right {
        .new_guest_information {
          .guest_name_panel {
            .number-guest {
              @include flex-direction(flex, center, center, row);

              position: relative;
              .total-guest {
                @include flex-direction(inline-flex, center, center, row);
                background-color: #fff;
                color: #d03d00;
                width: var(--s-28);
                height: var(--s-28);
                border-radius: 50%;
                position: absolute;
                top: -5px;
                left: -12px;
                z-index: 1;
                font-weight: bold;
                font-size: 12px;
                border: 1px solid #d03d00;
              }
            }
            .guest_rsvp_button {
              .rsvp_guest_btn {
                border-radius: 4px;
                padding: 3px 8px;
                width: max-content;
                height: var(--s-32);
                display: flex;
                gap: var(--s-6);
                &.rsvp_btn_yes {
                  background-color: #3f954412;
                  border: 1px solid #3f9544;
                  color: #3f9544;
                  &::after {
                    content: '\f00c';
                    display: block;

                    font-family: 'Font Awesome 5 Pro';
                    font-size: 14px;
                  }
                }
                &.rsvp_btn_no {
                  background-color: #d03d0012;
                  border: 1px solid #d03d00;
                  color: #d03d00;
                  &::after {
                    content: '\f00d';
                    display: block;
                    font-family: 'Font Awesome 5 Pro';
                    font-size: 14px;
                  }
                }
                &.rsvp_btn_maybe {
                  background-color: #f0a90012;
                  border: 1px solid #e5a50e;
                  color: #e5a50e;
                  &::after {
                    content: '\f4a9';
                    display: block;
                    font-family: 'Font Awesome 5 Pro';
                    font-size: 14px;
                  }
                }
              }
            }
            .new_edit_button {
              @include flex-direction(flex, center, center, row);
              border: 1px solid #393655;
              width: var(--s-28);
              height: var(--s-32);
              border-radius: 4px;
            }
          }
        }
      }
      .guest_icon {
        @include flex-direction(inline-flex, flex-start, center, row);
        position: relative;
        width: 30px;
        img {
          position: relative;
          height: var(--s-32);
          width: var(--s-32);
          border-radius: 50%;
          // border: 1px dotted var(--orange);
          // background-color: #72bac0;
          // left: -8px;
          // &:nth-child(2) {
          //   left: -15px;
          //   z-index: 9;
          //   background-color: #c14141;
          // }
          // &:nth-child(3) {
          //   left: -22px;
          //   background-color: #72bac0;
          // }
          // &:nth-child(4) {
          //   left: -30px;
          //   z-index: 9;
          //   background-color: #c14141;
          // }
        }
      }
    }
    .guest_grid_bottom {
      padding: 12px;
      @include flex-direction(flex, space-between, center, row);

      .guest_name_panel {
        .title_small {
          color: #393655;
          font-weight: 600;
        }
        .events_name_new {
          flex-wrap: wrap;
        }
      }
    }
  }

  .new_guest_details {
    display: flex;
    padding: var(--s-12) !important;
    flex-direction: row;
    border-top: 1px solid #ccc;
    gap: 16px;
  }
  .event_name_tag {
    border-radius: 12px;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    width: max-content;
    gap: var(--s-6);
    background-color: #efefef;
    border: 1px solid #ccc;
    &.rsvp_yes {
      background-color: #3f954412;
      border: 1px solid #3f9544;
      color: #3f9544;
      &::after {
        content: '\f00c';
        display: block;
        font-family: 'Font Awesome 5 Pro';
      }
    }
    &.rsvp_no {
      background-color: #d03d0012;
      border: 1px solid #d03d00;
      color: #d03d00;
      &::after {
        content: '\f00d';
        display: block;
        font-family: 'Font Awesome 5 Pro';
      }
    }
    &.rsvp_maybe {
      background-color: #f0a90012;
      border: 1px solid #e5a50e;
      color: #e5a50e;
      &::after {
        content: '\f4a9';
        display: block;
        font-family: 'Font Awesome 5 Pro';
      }
    }
  }
  // only for less then 768px
  // @media screen and (max-width: 768px) {
  //   .expend_data_child_row {
  //     position: fixed;
  //     bottom: 0;
  //     background-color: #fff;
  //     z-index: $zindex-tooltip;
  //     left: 0;
  //     border-radius: var(--s-12) var(--s-12) 0 0;
  //     overflow: hidden;
  //     width: 100%;
  //     .mobile_header {
  //       @include flex-direction(flex, space-between, center, row);

  //       padding: var(--s-16);
  //       height: 44px;
  //       background-color: #efefef;
  //       position: relative;
  //     }
  //     .new_guest_details {
  //       flex-direction: column;
  //       gap: 8px;
  //       position: relative;
  //       background-color: #fff;
  //     }
  //     &::before {
  //       position: fixed;
  //       width: 100%;
  //       height: 100vh;
  //       content: '';
  //       background-color: rgba(0, 0, 0, 0.3);
  //       top: 0;
  //     }
  //   }
  // }
}
