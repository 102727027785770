.apply_filter_container {
  .website_template_container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;

    .category_list-right_panel {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 32px;

      .task_planing_container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .task_title {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin: 0;

          span {
            font-size: 13px;
            color: #444;
          }
        }

        .task_box {
          border: var(--border);
          width: 100%;
          border-radius: 3px;
          display: flex;

          .text-off-white {
            color: #999;
            margin-bottom: 0;
            font-weight: 300;
          }

          .status_panel {
            display: flex;
            align-items: center;
            padding: 16px;
            flex: 1;
            gap: 16px;

            .far {
              font-size: 24px;
            }

            .title_medium {
              font-size: 24px;
            }
          }

          .task_action_panel {
            padding: 16px;
            justify-content: space-around;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          &.done {
            border-color: #009e0f;

            .status_panel {
              .far {
                color: #009e0f;
              }
            }
          }

          &.add_new_item {
            margin-bottom: 32px;

            .status_panel {
              .far {
                font-size: 32px;
                cursor: pointer;
              }

              .title_medium {
                font-size: 20px;
              }
            }

            .new-item-panel {
              .fa-minus-circle {
                display: none;
              }

              &.show {
                .fa-plus-circle {
                  display: none;
                }

                .fa-minus-circle {
                  display: inline-block;
                }

                + .new_item_field_container {
                  display: flex;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  .select {
                    box-shadow: none;
                  }
                  .selectBtn {
                    border: var(--border);
                  }
                }
              }
            }

            .new_item_field_container {
              border-top: var(--border);
              padding-top: 16px;
              width: 100%;
              display: none;
              align-items: center;
              gap: 16px;
            }
          }
        }
      }
    }
  }
}

/* Checklist popup inner */
.item_box_checklist-popup {
  border-top: var(--border);
  padding-top: 16px;
  margin-top: 16px;
  .img-box {
    width: 100px;
    height: 100px;
    border: var(--border);
    border-radius: 3px;
    background-color: #eeeeee;
  }
}
