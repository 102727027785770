/* Payment package */
.package_selection {
  padding: 32px 0;
}

.payment_package {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  justify-content: center;
  margin: 50px 0;

  .package_box {
    border: 1px solid;
    padding: 30px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    border-radius: 15px;
    position: relative;

    a {
      color: #fff;
      text-decoration: none;
    }

    span {
      position: absolute;
      top: 14px;

      i {
        font-size: 24px;
      }
    }

    .count_guests {
      border: 1px solid;
      padding: 10px;
    }

    ul {
      text-align: left;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;

      li {
        font-size: 22px;
        padding-left: 20px;

        &::before {
          font-family: 'Font Awesome 5 Pro';
          content: '\f00c';
          margin: 0 11px 0 -23px;
        }
      }
    }
  }
}
/* Payment Method */
.payment_method {
  .back_btn {
    margin: 50px 0;
  }

  .last_payment_section {
    display: flex;
    gap: 20px;
    margin: 50px 0;

    .preview_card {
      width: 100%;
      display: flex;
      border: 1px solid #cacaca;
      border-radius: 30px;
      padding: 20px 40px;
      justify-content: space-around;

      div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }

      ul {
        list-style: none;
        margin-top: 90px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .payment_card {
      width: 100%;
      display: flex;
      border: 1px solid #cacaca;
      border-radius: 30px;
      padding: 20px;
      flex-direction: column;

      .amount_section {
        background: #f7f6f9;
        padding: 20px;
        margin-bottom: 30px;
        border: 1px solid #c7c7c7;

        .details_amount {
          display: flex;
          margin: 20px 0;
          justify-content: space-between;
        }

        ul {
          list-style: none;
          margin-bottom: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            &::before {
              font-family: 'Font Awesome 5 Pro';
              content: '\f00c';
              margin: 0 11px 0 -23px;
            }
          }
        }
      }

      form {
        width: 100%;

        .tab_payment {
          padding: 20px;
          border: 1px solid #eee;

          .nav-pills {
            .nav-item {
              width: 25%;
              display: flex;
              border: 1px solid;
              justify-content: center;
              border: 1px solid #cfcfcf;

              .nav-link {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;

                color: var(--textGlobalColor);

                width: 100%;

                i {
                  font-size: 20px;
                }

                &.active {
                  background-color: transparent;
                  color: var(--textGlobalColor);
                  width: 100%;
                  background: #eee;
                  padding: 1.5rem 1rem;
                }
              }
            }
          }
        }
      }

      .box {
        label {
          display: inline-block;
          margin-bottom: 10px;
        }
        input {
          height: auto;
          padding: 15px 10px;

          &:focus {
            box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
          }
        }
        .group_field_container {
          display: flex;
          flex-wrap: wrap;
          gap: 14px;
          flex-direction: column;

          .multi_field_container {
            display: flex;
            gap: 16px;
            align-items: flex-end;
          }
        }
      }
      .paypal {
        .form-sizing {
          display: flex;
          flex-direction: column;
          max-width: 500px;
          width: 100%;
          margin: 0 auto;
          padding: 20px 40px;
          border: 1px solid #eee;
          img {
            margin-bottom: 20px;
            margin: 0 auto;
          }
        }
      }
      .netbanking {
        .form-sizing {
          display: flex;
          flex-direction: column;
          max-width: 500px;
          width: 100%;
          margin: 0 auto;
          padding: 20px 40px;
          border: 1px solid #eee;
        }
      }
      .upi {
        .form-sizing {
          display: flex;
          flex-direction: column;
          max-width: 500px;
          width: 100%;
          margin: 0 auto;
          gap: 20px;
          padding: 20px 40px;
          border: 1px solid #eee;
        }
      }

      .credit {
        .form-sizing {
          display: flex;
          flex-direction: column;
          max-width: 500px;
          width: 100%;
          margin: 0 auto;
          gap: 20px;
          padding: 20px 40px;
          border: 1px solid #eee;
          .payment_icon {
            text-align: center;
          }
        }
      }
    }
  }
}

.phonepe_section {
  height: calc(100vh - 80px);
  background-image: url('../assets/img/form-bkg.png');
  background-repeat: repeat;
  background-position: left center;
  background-size: contain;
  background-color: #fffaf6;
  h2 {
    font-size: 18px;
  }

  .phonepe_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    .phonepe-border {
      padding: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: #fff;
      max-width: 530px;
      width: 100%;
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px 0px;
      border-radius: 12px;
      align-items: center;
    }

    .iframe_phonepe {
      iframe {
        min-height: 500px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
