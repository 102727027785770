@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

.invitation_list_item_row {
  .container {
    flex-direction: row;
    justify-content: space-around;
  }

  .invitation_list_view {
    @include border-radius(0.5rem);
    @include box-shadow(0 0 12px rgba($black, 0.1));
    padding: var(--s-24);
    margin: var(--s-16) 0;
    gap: var(--s-24);
    border: solid 1px #d2cbc3;
    background: #ffffff8f;
    flex-direction: column;
    @include respond-to('tablet-portrait') {
      flex-direction: row;
    }

    .invitation_card_view {
      width: 130px;
      img {
        height: auto;
        @include box-shadow(0 4px 12px rgba($black, 0.1));
      }
    }

    .invitation_details_panel {
      flex: 1;

      table {
        th {
          vertical-align: top;
        }

        th,
        td {
          padding-right: var(--s-24);
          color: $black;
          font-weight: 600;
        }

        td {
          vertical-align: middle;
          color: $gray-midium;
          font-weight: 400;
        }
      }
    }

    .invitation_action_panel {
      button.round {
        @include border-radius(2rem);
        align-self: flex-start;
        padding: var(--s-6) var(--s-24);
      }
    }
  }
}

.sav_the_dat_preview {
  flex-direction: column;
  align-items: center;
  h1,
  p {
    text-align: center;
  }
  @include respond-to('tablet-portrait') {
    flex-direction: row;
    padding: calc(var(--s-24) * 2) var(--s-24);
    h1,
    p {
      text-align: left;
    }
  }
}

.action-item-event {
  margin-top: var(--s-16);
  gap: var(--s-16);
}

.tab_panel {
  @include box-shadow(0 4px 12px rgba($black, 0.1));
}

.tab_listing {
  display: flex;
  gap: var(--s-24);
  padding: var(--s-16) 0;
}

.tab_panel_box {
  margin: var(--s-24) 0;
}

.all_invitation_container {
  flex-direction: column;
}

.craft-invitation {
  flex-direction: column;
  gap: var(--s-24);
  margin: var(--s-24) !important;
}

.grid-wrapper {
  flex-direction: column;
  @include respond-to('tablet-portrait') {
    flex-direction: row;
  }
}

.save-date-grid-template {
  margin-right: var(--s-24);
}
