@use '../../abstracts/variables' as *;
@use '../../themes/default/variables' as *;
@use '../../abstracts/mixins' as *;
@use '../../themes/default/colors' as *;
@use '../../abstracts/breakpoints' as *;
@use '../../components/buttons' as *;

// common variables related to the video invitation
$banner-bkg: '../../assets/img/v1/video-invite/banner.png';
$leaf-bkg: '../../assets/img/v1/video-invite/leaf-bkg.png';
$invitation-bkg: '../../assets/img/v1/video-invite/invitation-bkg.png';

%leaf_background {
    background-image: url($leaf-bkg);
    background-size: contain;
    background-color: $light-medium-pink;
    background-position: left center;
}

%invitation_background {
    background-image: url($invitation-bkg);
    background-size: cover;
    background-color: $light-medium-pink;
    background-position: left top;
    background-attachment: fixed;
}

.box_model {
  @include box-shadow(0 2px 4px rgba($black, 0.1));
  @include border-radius(0.5rem);

  padding: var(--s-12);
  @include respond-to('laptop') {
    border-radius: 0;
    box-shadow: none !important;
  }
}

.slogan_panel {
  background-color: #ccc;
  background-image: url($banner-bkg);
  background-size: cover;
  h1,
  p {
    text-align: center;
  }
  .slogan_text_container {
    @include flex-direction(flex, center, center, column);

    gap: var(--s-12);
    max-width: 420px; // TODO: need to be updated
    margin: 0 auto;
    @include respond-to('laptop') {
      max-width: 100%;
    }
  }
}
.invitation_card_container {
   @extend %invitation_background;
   
  .video-card-container {
    @include respond-to('laptop') {
      @include flex-direction(flex, center, flex-start, row);

      flex-wrap: wrap;
      gap: var(--s-24);
      padding-top: var(--s-32);
      .video-card {
        flex-basis: 250px; // TODO: need to be updated
        padding: var(--s-16);
        background-color: #fff5e6;
        .content {
          text-align: center;
        }
        .price-container {
          justify-content: center;
          gap: var(--s-16);
          margin-bottom: var(--s-24);

          font-size: 0.8rem;
        }
      }
    }
  }
}

.custom_design_container {
  .vt_card {
    .btn_container {
      text-align: left;
      a {
        @include flex-direction(inline-flex, center, center, row);

        border-radius: $button-border-radius;
        padding: 0 var(--s-12);
        min-width: 80px; // TODO: need to be updated
        font-size: 1rem;
        height: 28px; // TODO: need to be updated
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
        padding: 3px 8px; // TODO: need to be updated
        font-size: 0.8rem;
        cursor: pointer;
        margin-top: var(--s-12);
        transition: all 0.3s ease;
        background-color: $button-primary-color;
        color: $white;
        border: $button-border-width solid $button-primary-color;
        &:hover {
          transform: translateY(-2px); // Example hover effect
        }

        &:active {
          transform: translateY(2px); // Example active effect
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 3px rgba($button-primary-color, 0.3); // Example focus style
        }
      }
    }
    @include respond-to('laptop') {
      @include flex-direction(flex, flex-start, center, row-reverse);

      box-shadow: none;
      overflow: visible;
      img {
        border-radius: var(--s-16);
        width: 900px;
        height: 300px;
        background-color: #fff9f4;
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
        overflow: hidden;
      }
      .card-body {
        @include flex-direction(flex, center, flex-start, column);
        @include box-shadow(0 5px 12px rgba($black, 0.2));

        border-radius: var(--s-16);
        padding: var(--s-16);
        height: 232px;// TODO: need to be updated
        width: 100%;
        max-width: 478px;// TODO: need to be updated
        position: absolute;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        top: 50%;
        transform: translateY(-50%);
        margin-left: 20px;
        .btn_container {
          text-align: left;
          a {
            @include flex-direction(inline-flex, center, center, row);

            border-radius: $button-border-radius;
            padding: 0 var(--s-12);
            min-width: 80px;
            font-size: 1rem;
            height: 2.375rem;
            box-sizing: border-box;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            transition: all 0.3s ease;
            background-color: $button-primary-color;
            color: $white;
            border: $button-border-width solid $button-primary-color;
            &:hover {
              transform: translateY(-2px); // Example hover effect
            }

            &:active {
              transform: translateY(2px); // Example active effect
            }

            &:focus {
              outline: none;
              box-shadow: 0 0 0 3px rgba($button-primary-color, 0.3); // Example focus style
            }
          }
        }
        .card-body_inner{
            gap: var(--s-16);
        }
      }
    }
  }
}
.how_to_order_container {
  .order_list_container {
    @include flex-direction(flex, flex-start, flex-start, column);

    gap: var(--s-8);
    .order_item {
      @include flex-direction(flex, flex-start, flex-start, row);

      gap: var(--s-16);
      .breadcrumb_list {
        @include flex-direction(inline-flex, flex-start, center, row);

        gap: var(--s-6);
        font-size: var(--heading-5);
        color: $primary-color;
        min-width: var(--s-28);
      }
      .text_datail {
        @include flex-direction(flex, flex-start, flex-start, column);

        gap: var(--s-4);
      }
    }
    @include respond-to('laptop') {
        flex-direction: row;
        .order_item{
            @include flex-direction(flex, flex-start, center, column);
            position: relative;

            flex-basis: 350px; // TODO: need to be updated
            .breadcrumb_list{
                @include flex-direction(flex, flex-start, center, column);
                span{
                    @include flex-direction(inline-flex, center, center, row);

                    position: relative;
                    z-index: $zindex-dropdown;

                    width: var(--s-32);
                    height: var(--s-32);
                    border-radius: 50%;
                    border: 1px solid $primary-color;
                    background-color: $white;
                }
                i{
                    font-size: var(--s-24);
                }
            }
            .text_datail{
                text-align: center;
                align-items: center;
            }
            &::after{
                content: "";
                display: block;
                width: 89%;
                height: 2px;
                background-color: $primary-color;
                position: absolute;
                top: 20px; // TODO: need to be updated
                left: 55.8%;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
  }
}

.about_video_invitaton {
  p {
    margin-bottom: var(--s-8);
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include respond-to('laptop') {
    background-color:  #fff9f4;
    .box_model{
        @include box-shadow(0 0 15px 0 rgba($black, 0.2));

        padding: var(--s-32);
        background-color: $white;
        border-radius: var(--s-12);
        p{
            text-align: center;
            padding-bottom: var(--s-16);
        }
    }
  }
 
}

.textimonial_container {
    @extend %leaf_background;
  .slick-dots {
    display: none !important;
  }
  .slick-track {
    display: flex;
    gap: var(--s-8);
  }
  .card {
    @include box-shadow(0 2px 4px rgba($black, 0.1));
    @include border-radius(0.5rem);

    border-color: #f1f1f1;

    height: 100%;
    height: 248px; // TODO: need to be updated
    .card-body{
        @include flex-direction(flex, flex-start, center, column);
     } 
    .card-text {
      z-index: $zindex-fixed;
      position: relative;
      text-align: center;
      flex: 1;
    }
    .profile_name {
      @include flex-direction(flex, flex-start, center, column);

      gap: var(--s-12);
      img {
        width: var(--s-40);
        height: var(--s-40);
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
        
      }
    }
    @include respond-to('laptop') {
         height: 280px; 
         .profile_name {
         img{
            width: var(--s-72);
            height: var(--s-72);
         } 
        }
    }
  }
}

.video_invitations_faq{
    .accordion-item{
        .accordion-button{
            background-color: $light-pink;
          font-weight: 500;
            &:focus,&:active{
                outline: none;
                box-shadow: none;
            }
        }
    }
}
