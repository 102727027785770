.vendor_public_wrapper {
  .vendor_public_main {
    display: flex;
    justify-content: space-between;
    gap: 80px;

    .vendor_public_name {
      width: 100%;

      h3.box_title {
        margin-top: 10px;
        font-size: 20px;
        color: var(--purple);
        font-weight: 500;
      }

      .vendor_about {
        .vendor_information {
          ul {
            li {
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .vendor_price_details_right {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      min-width: 422px;
      position: sticky;
      top: 5rem;
      right: 0;
      z-index: 2;
      overflow-y: auto;
      border-radius: 10px;

      @media (max-width: 767px) {
        min-width: unset;
      }

      .vendor_price_box {
        .vendor_price {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          border-bottom: 1px solid #eee;
          align-items: center;

          @media (max-width: 767px) {
            border: none !important;
          }
          span {
            color: var(--orange);
            font-size: 15px;
          }

          p {
            margin-bottom: 0;
            font-size: 15px;
          }
        }

        .accordion-item {
          border: 0;

          .accordion-header {
            border: 0;

            .accordion-button {
              padding: 10px 0;
              background: transparent;

              &::after {
                background-image: none;
                content: '\f078';
                font-family: 'Font Awesome 5 Pro';
              }

              &:not(.collapsed) {
                &::after {
                  content: '\f077';
                  transform: none;
                }
              }

              &:focus {
                box-shadow: none;
              }

              &:not(.collapsed) {
                color: unset;
                box-shadow: none;
              }
            }
          }

          .accordion-body {
            padding: 10px;
            background: #fbfbfb;
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          min-width: 300px;

          h6 {
            font-size: 18px !important;
          }

          .accordion {
            .accordion-body {
              .faqs_list {
                span {
                  font-size: 14px;
                }

                p {
                  font-size: 12px;
                }
              }
            }

            .vendor_price {
              span {
                font-size: 18px;
              }

              p {
                font-size: 15px;
              }
            }
          }
        }
      }

      @media (min-width: 768px) {
        form {
          border: none !important;
        }
      }

      @media (max-width: 767px) {
        .vendor_price_box {
          border: none !important;
          border: none !important;
          padding: 0 !important;
          min-width: unset;

          h6 {
            display: none;
          }

          .accordion {
            display: none;
          }
        }
      }

      form {
        @media (max-width: 480px) {
          p {
            font-size: 12px !important;
            margin-top: 22px !important;
          }

          .group_field_container {
            .multi_field_container {
              margin-top: 0 !important;
              flex-direction: column;
              gap: 10px !important;

              span {
                text-align: left !important;
                width: 100% !important;
              }
            }
          }
        }

        @media (max-width: 767px) {
          border-radius: 10px;
          background: #fff;
          padding: 15px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          z-index: 999;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .vendor_top_menu {
    a {
      color: unset;
      cursor: pointer;
    }
  }

  .vendor_social {
    a {
      text-decoration: none;
      cursor: pointer !important;

      span {
        cursor: pointer !important;
        border-radius: 50%;
        padding: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff9f4;

        i {
          color: var(--orange);
          font-size: 18px;
        }

        img {
          width: 20px;
          min-width: 20px;
          max-width: 20px;
          height: auto;
        }
      }
    }
  }

  @media (max-width: 991px) {
    min-width: 100%;
    max-width: 100%;
  }

  .vendor_portfolio {
    .vendor_gallery {
      display: grid;
      margin: 10px 0;
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      grid-template-rows: 1fr;
      grid-gap: 1em;
      grid-auto-flow: dense;

      figure {
        margin-bottom: 0;

        img {
          object-fit: cover;
          height: 140px;
        }
      }
    }
  }
}

.feedback_user {
  .value {
    display: inline-flex;
    cursor: pointer;
    gap: 5px;

    .star {
      display: inline-flex;
      align-items: center;
      gap: 3px;

      i {
        font-size: 14px;
      }
    }

    .fas {
      color: #bc8129;
    }

    .num {
      font-size: 14px;
      font-weight: 600;
    }

    i {
      font-size: 18px;
    }
  }
}

.vendor_review {
  .vendor_rating_left {
    .vendor_review_rating {
      .vendor_review_row {
        .vendor_fix_left {
          span {
            font-size: 15px;
          }

          i {
            font-size: 12px;
          }
        }

        .vendor_rectangle-bar {
          flex: 1 1;
          height: 12px;
          background-color: #f2f2f2;
          border-radius: 15px;

          .rating-5 {
            height: 12px;
            background-color: #57e32c;
          }

          .rating-4 {
            height: 12px;
            background-color: #b7dd29;
          }

          .rating-3 {
            height: 12px;
            background-color: #ffe234;
          }

          .rating-2 {
            height: 12px;
            background-color: #ffa534;
          }

          .rating-1 {
            height: 12px;
            background-color: #ff4545;
          }
        }

        .vendor_review_read {
          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  .vendor_rating_right {
    padding: 20px;
  }
}

.faqs_list {
  span {
    font-weight: 600;
    font-size: 15px;
  }

  p {
    color: #909090;
    font-size: 15px;
    margin-bottom: 0;
  }
}

.vendor_profile_gallery {
  display: grid;
  width: 100%;
  max-height: 412px;
  overflow: hidden;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
  border-radius: 10px;
  position: relative;

  .gallery_item {
    &:nth-child(1) {
      grid-column: span 3;
      grid-row: span 2;
      
    }

    &:nth-child(2n) {
      grid-column: span 2;
    }

    &:nth-child(4n) {
      grid-column: span 3;
      grid-row: span 1;
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .view_more_images {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  .modal {
    .modal-body {
      .vendor_body {
        max-width: 800px;
        margin: auto;

        .full_view_vendor_images {
          display: grid;
          width: 100%;
          max-height: max-content;
          overflow: hidden;
          overflow-y: auto;
          grid-gap: 10px;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          grid-auto-rows: 200px;
          grid-auto-flow: dense;
          border-radius: 10px;

          .gallery_item {

            &:nth-child(1) {
        
              @media (max-width:576px) {
                grid-column: span 1 !important;
                grid-row: span 1 !important;

                
              }
            }

            &:nth-child(2n) {
              grid-column: span 2;
              grid-row: span 2;
              @media (max-width:576px) {
                grid-column: span 1;
                grid-row: span 1;

                
              }
            }

            &:nth-child(4n) {
              grid-column: span 1;
              grid-row: span 1;
              
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .vendor_mobile_back {
  

    &:has(form.vendor_form_pop) {
      &::before {
        content: '';
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        position: absolute;
        z-index: 999;
        height: 100vh;
      }
    }

    &.send_message_popup_close {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      z-index: 9999;
      justify-content: center;
      position: relative;
      padding: 20px;
      &::before {
        content: '';
        display: block !important;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        position: absolute;
        z-index: 999;
        height: 100vh;
      }
    }
  }
}

.vendor_mobile_price {
  @media (max-width: 320px) {
    padding: 0 10px !important;

    .vendor_price {
      align-items: flex-start !important;
      display: flex;
      gap: 0 !important;
      flex-direction: column;
      padding: 5px 0 !important;

      span {
        font-size: 15px !important;
      }

      p {
        font-size: 13px !important;
      }
    }
  }

  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index: 99;
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #ccc;
    padding: 10px 12px;
    gap: 8px;
    align-items: center;

    .vendor_bottom_price {
      .vendor_price {
        display: flex;
        gap: 15px;
        align-items: center;
        padding: 5px 0 !important;

        span {
          color: var(--orange);
        }

        p {
          font-size: 12px;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .fixed-bottom-component {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    transform: translateY(220%);
    /* Hide by default */
  }

  .fixed-bottom-component.visible {
    transform: translateY(0);
    /* Slide up when visible */
  }
}
