/* Filter panel container */
.apply_filter_container {
  display: flex;
  align-items: flex-start !important;

  .filter_container {
    width: 150px;
    display: flex;
    flex-direction: column;
    gap: 72px;

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      list-style: none;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 12px;

      .color-box {
        width: 24px;
        height: 24px;
        display: inline-flex;
        border-radius: 50%;
      }
    }
  }

  .website_template_container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
  }
}
.left_right_content {
  padding-bottom: 40px;

  .container {
    &.head_content {
      display: flex;
      gap: 40px;
    }

    .left_content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 40px;

      .left_box_column {
        padding: 20px;
        border: 1px solid var(--grayLight);
        height: auto;
      }
    }

    .right_content {
      width: 50%;
      flex-direction: column;
      display: flex;
      gap: 40px;

      .right_box_column {
        padding: 20px;
        border: 1px solid var(--grayLight);
        height: auto;
      }
    }
  }
}
