.questionnairs-wedding {
  .group_field_container {
    max-width: 700px !important;
  }
  .questionnaire-row {
    padding: 20px;
    background: #fffcf7;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    .text-area {
      textarea {
        height: auto !important;
      }
    }
    .hover-text {
      display: inline-block;
      margin: 0 5px;
      border-radius: 50%;
      border: none;
      background: #f96328;
      width: 20px;
      height: 20px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      position: unset;
      @media (min-width: 1200px) {
        position: relative;
      }

      .tooltip-text {
        visibility: hidden;
        position: absolute;
        z-index: 1;
        width: 100%;
        min-width: 500px;
        color: #333;
        font-size: 16px;
        background-color: #f1f1f1;
        border-radius: 10px;
        padding: 10px 15px 10px 15px;
        text-align: left;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        @media (max-width: 1200px) {
          min-width: 260px;
        }

        &#bottom {
          top: 0;
          left: 0;
          @media (min-width: 1200px) {
            top: 22px;
            left: -150px;
          }
        }

        #left {
          top: -8px;
          right: 120%;
        }

        #right {
          top: -8px;
          left: 120%;
        }
      }
    }
    .hover-text:hover .tooltip-text {
      visibility: visible;
    }

    button.tooltip-icon {
      border-radius: 50%;
      border: none;
      background: #f96328;
      width: 20px;
      height: 20px;
      font-size: 12px;
      margin-left: 4px;
    }

   
  }
}

.radio-btn {
  width: 24px;
  height: 24px;
  position: relative;

  > * {
    position: absolute;
  }

  &-visible {
    border: 1px solid gray;
    width: 24px;
    height: 24px;
    margin: 2px;
    background: #fff;
    border: var(--border);
    border-radius: 3px;
  }

  > input {
    z-index: 1;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translatex(-50%) translatey(-50%);
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  > input:checked + .Checkbox-visible {
    background: var(--orange);
    border-color: var(--orange);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTQnIGhlaWdodD0nMTQnIHZpZXdCb3g9JzAgMCAxNCAxNCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48dGl0bGU+d2hpdGU8L3RpdGxlPjxwYXRoIGQ9J001LjM3OCAxMS4zN0wyIDcuNTlsMS40MzgtMS4yODZMNS4zNzQgOC40N2w1LjE4NS01Ljg0TDEyIDMuOTFsLTYuNjIyIDcuNDYnIGZpbGw9JyNmZmYnLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
  }

  > input:hover + .Checkbox-visible {
    border-color: var(--orange);
  }

  > input:hover:checked + .Checkbox-visible {
    border-color: var(--orange);
  }
}
