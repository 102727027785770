
.compose_drawer {

  
.form_input {

    .group_field_container {

        .field_container {

            input,textarea {

                background-color: #fff;
            }
        }
    }
}

}


.bottom_save_button  {
    padding-top: 20px !important;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    z-index: 99;
    background-color: #fff;
    width: 100%;
    height: 70px;
    align-items: center;
    border-top: 1px solid #ccc;
    padding: 0 12px;
    gap: 8px;
}