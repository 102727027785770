.unauthorized_wrap {
  padding: 50px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .unauthorized_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 30px;
    margin: auto;
    width: 100%;

    h1 {
      color: var(--orange);
      font-size: 32px;
    }

    p {
      color: var(--textGlobalColor);
      padding-top: 0;
      margin: 0;
    }

    img {
      width: 250px;
    }

    form {
      width: 100%;
      max-width: 550px;

      .enter-email {
        display: flex;
        flex-direction: column;
        width: 100%;
        input {
          height: 50px;
        }

        .btn {
          height: 50px;
          display: inline-flex;
          align-items: center;
          border-radius: 0;

          a {
            color: #fff !important;
          }
        }
      }
    }
  }
}
