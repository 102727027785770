.form_close_panel {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
}

.signup-start {
  .half-fluid {
    .left-half {
      .image {
        background-image: url('../assets/img/customised-template_Image.png');
        background-size: contain;
        background-position: center top;
        min-height: 1020px;
        height: 100%;
        background-repeat: no-repeat;
      }
    }
    .right-half {
      left: 50%;
      margin-left: -50vw;
      max-width: 100vw;
      position: relative;
      right: 50%;
      width: 100vw;
      padding: 0px;
    }
  }

  .text-area {
    textarea {
      height: auto !important;
    }
  }

  display: flex;

  @media (max-width: 1400px) {
    .register-logo {
      img {
        width: 60px !important;
      }
    }

    h1 {
      font-size: 24px;
    }
    .group_field_container {
      max-width: 640px;
      label {
        font-size: 14px;
      }
    }
  }

  @media (min-width: 1361px) and (max-width: 1500px) {
    .title_medium {
      font-size: 24px;
      line-height: 34px;
    }
    .register-logo {
      img {
        width: 50px !important;
      }
    }
    p {
      font-size: 12px;
    }
  }

  @media (max-width: 567px) {
    .left-half {
      .image {
        min-height: 370px !important;
      }
    }
  }

  @media (min-width: 568px) and (max-width: 991px) {
    .left-half {
      .image {
        min-height: 370px !important;
      }
    }
  }

  @media (min-width: 992px) {
    .half-fluid {
      .left-half {
        padding-left: 0 !important;
        position: absolute;
        left: 0;
        right: 50%;
        margin-left: auto;
        width: auto;
      }

      .right-half {
        padding-right: 0 !important;
        position: absolute;
        right: 0;
        left: 50%;
        margin-left: auto;
        width: auto;
      }
    }
  }
}

.form_left-slider-panel {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  border-right: 1px solid #ccc;

  .overflow_panel {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px;

    gap: 32px;

    .title {
      font-weight: 100;
      font-size: 32px;
      color: #fff;
      line-height: 40px;
    }
  }

  .slider_img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  + .content_panel {
    background-image: url('../assets/img/form-bkg.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    background-color: #fffaf6;
    padding: 20px 0;
    .group_field_container {
      max-width: 600px;
      width: 100%;
      align-items: center;

      .multi_field_container {
        align-items: center;
        width: 100%;

        form {
          width: inherit;
        }
      }

      @media (max-width: 1360px) {
        margin: 0 auto;
      }
    }

    @media (max-width: 1360px) {
      .signup-start {
        display: block;
        height: 100vh;
        overflow: auto;
        padding: 35px !important;

        .title_medium {
          padding: 0 36px !important;
        }

        .multi_field_container {
          flex-direction: column;

          // .field_container {
          //   padding-left: 36px;
          //   padding-right: 36px;
          // }

          .w-50 {
            width: 100% !important;
          }
        }
      }
    }
    @media (max-width: 580px) {
      .signup-start {
        .title_medium {
          font-size: 20px !important;
        }
      }
    }
  }

  @media (max-width: 1360px) {
    display: none;
  }
  @media (max-width: 1400px) {
    max-width: 600px;
  }
}

@media (max-width: 1400px) {
.login_wrap {
  h1 {
    img {

      width: 60px !important;
    }
  }
}
}

.login_fullPage_container {

  &:has(.login_wrap) {

    height: 100vh !important;

  }
}