/* guests Page */
.guests_banner {
  padding: 12px 0;
  /* background-image: url('../assets/img/guest-list-L.png'); */
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  // background-color: #f9f5f0;

  .container {
    display: flex;
    align-items: center;
    gap: 32px;

    form {
      display: flex;

      .btn {
        white-space: nowrap;
        min-width: unset;
      }
    }

    .event_slogan {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      h1 {
        font-size: 22px;
      }

      p {
        font-size: 18px;
      }

      .copy {
        margin-top: 20px;

        form {
          position: relative;
          width: 100%;
          display: flex;
          gap: 8px;

          input {
            display: block;
            outline: 0;
            background: #eee;
            font-size: 15px;
            padding: 10px 10px;
            margin-bottom: 0px;
            width: 60%;
            color: #212529;
          }

          .btn {
            width: 38%;
            padding: 0 0.75rem;
          }
        }

        @media (max-width: 767px) {
          form {
            flex-direction: column;

            input {
              width: 100% !important;
            }

            .btn {
              width: 100% !important;
              padding: 10px 15px !important;
            }
          }
        }

        @media (min-width: 768px) and (max-width: 991px) {
          input {
            width: 52% !important;
          }

          .btn {
            width: 46% !important;
            padding: 4px 15px !important;
          }
        }
      }
    }

    .action-item-event {
      flex: 1;
      display: flex;
      gap: 12px;
      justify-content: flex-end;

      a {
        // width: 100% !important;
      }

      @media (max-width: 580px) {
        align-items: flex-start;
      }

      .card_link_box {
        max-width: unset !important;
      }
    }

    @media (max-width: 767px) {
      .copy {
        form {
          flex-direction: column;

          .btn {
            position: static !important;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 12px 0 !important;

    .container {
      gap: 12px !important;

      .action-item-event,
      .event_slogan {
        align-self: flex-start !important;
      }
    }
  }
}

/* guests Page - table section */
.tab_panel_box {
  .inner_content_panel {
    .left_panel {
      .info_buttons {
        display: flex;
        gap: 15px;

        .info_btn {
          background: #fff;
          padding: 10px 35px;
          border: 1px solid var(--grayLight);

          span {
            display: block;
            text-align: center;
          }
        }
      }

      .bellow-rw {
        form {
          margin-bottom: 15px;

          .form-control {
            border-radius: 0;

            &:focus {
              border-color: #ced4da;
              box-shadow: none;
              border-radius: 0px;
            }
          }

          .btn {
            border-radius: 0;
          }
        }
      }
    }

    .table_grid {
      .tbl-content {
        .icon_action {
          display: block;

          i {
            font-size: 14px;
            margin: 5px;
          }
        }

        td {
          .wedding_day {
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

/* guests Page - Add guests pop up*/
.model_popover_panel {
  .tab_container {
    flex: 1;
    overflow: auto;

    .nav-tabs {
      .nav-item {
        position: relative;

        .nav-link {
          color: #6e6a93 !important;
          list-style: none;
          display: flex;
          align-items: center;
          border-bottom: 5px solid transparent !important;
          border-bottom-color: transparent;
          height: 100% !important;
          border: none;
          padding: 5px 0px;
          margin-right: 30px;

          &.active {
            border-color: var(--orange) !important;
          }

          &:hover {
            color: #6e6a93 !important;
          }
        }
      }
    }

    .group_field_container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 600px;

      .form_group_title {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: var(--border);
        margin: 16px 0;
      }
    }

    .field_container {
      &.title_action_panel {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        .field_title {
          margin-bottom: 0;
        }
      }

      .field_title {
        font-size: 14px;
        margin-bottom: 8px;
      }

      .element_repeat {
        display: flex;
        align-items: center;
        gap: 16px;

        .fa-trash {
          color: #ccc;
        }
      }

      .note_content {
        width: 50%;
        padding: 10px;
        border: 1px solid #eee;
        display: flex;
        gap: 8px;

        p {
          font-size: 11px;
          margin: 0;
        }
      }
    }

    .multi_field_container {
      display: flex;
      gap: 16px;
      align-items: flex-end;

      &.new_guest_add {
        &:not(:first-child) {
          border-top: var(--border);
          padding-top: 16px;
          margin-top: 16px;
        }
      }
    }

    @extend .content_list;
    // .content_list {
    //   margin: 25px 0;

    //   .box-content {
    //     padding: 20px;
    //     border: 1px solid #8a8a8a;
    //     border-radius: 20px;

    //     .fnt-sz {
    //       font-size: 14px;
    //       line-height: 20px;
    //     }

    //     .copy {
    //       margin-top: 20px;
    //       display: flex;
    //       flex-wrap: wrap;
    //       justify-content: center;
    //       position: relative;
    //       form {
    //         position: relative;
    //         width: 80%;
    //         display: flex;
    //         gap: 8px;

    //         input {
    //           padding-right: 90px;
    //           display: block;
    //           width: 100%;
    //           outline: 0;
    //           background: #eee;
    //           font-size: 15px;
    //           padding: 10px 10px;
    //           margin-bottom: 0px;

    //           width: 100%;
    //           height: 50px;
    //           background: #fff;
    //           border: 1px solid #ccc;
    //           color: #000;
    //           border-radius: 0;

    //         }

    //         .btn {

    //           position: absolute;
    //           right: 0;
    //           background: #f05537;
    //           padding: 0px 15px;
    //           border: 1px solid #f05537;
    //           top: 0;
    //           height: 50px;
    //           display: inline-flex;
    //           align-items: center;
    //           border-radius: 0;
    //         }
    //       }
    //     }

    //     .both_content {
    //       display: flex;
    //       flex-wrap: wrap;
    //       gap: 20px;

    //       .left_content {
    //         width: 30%;

    //         .upload_img {
    //           .border_dotted {
    //             border: 1px dashed #ccc;
    //             padding: 10px;
    //             display: flex;
    //             flex-wrap: wrap;
    //             justify-content: space-between;
    //             border-radius: 18px;
    //             align-items: center;

    //             .icon-img {
    //               width: 20%;

    //               img {

    //                 width: 100%;
    //                 height: auto;
    //               }
    //             }

    //             .file_upload {

    //               input[type="file"] {
    //                 display: none;
    //               }

    //               .custom-file-upload {
    //                 border: 1px solid #ccc;
    //                 display: inline-block;
    //                 padding: 6px 12px;
    //                 cursor: pointer;
    //                 background-color: var(--orange);
    //                 color: #fff;

    //                 .upload_field {
    //                   padding: 10px;

    //                 }
    //               }
    //             }
    //           }
    //         }

    //         .field_container {
    //           display: block;

    //           .field_title {
    //             margin-bottom: 10px;
    //           }
    //         }

    //         .group_field_container {

    //           gap: 20px;

    //           &.wedding_date_calender {
    //             display: flex;
    //             flex-direction: row !important;

    //             .btn_calender {
    //               form {
    //                 width: auto;
    //               }
    //             }
    //           }
    //         }

    //         .btn.big {
    //           font-size: 16px;
    //           border-radius: 3px;
    //           padding: 6px 12px;
    //           margin-top: 20px;
    //         }
    //       }

    //       .right_content {
    //         width: 67%;
    //         display: flex;
    //         border: 1px solid #ebe6e6;
    //         overflow: hidden;
    //         text-align: center;

    //         .card_content {
    //           width: 45%;
    //           background: #fff;
    //           padding: 10px;

    //           background-image: url(../assets/img/left-banner.png);
    //           background-repeat: no-repeat;
    //           background-size: contain;
    //           background-position: top;
    //           position: relative;
    //           box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
    //           border-right: 1px solid #ccc;

    //           p {
    //             display: inline-block;
    //             width: 100%;
    //             overflow-wrap: break-word;
    //             background-image: linear-gradient(6deg, rgb(255, 250, 246) 0%, rgb(255, 223, 205) 100%);
    //             opacity: 0.8;
    //             padding: 10px;
    //             border: 1px solid rgb(238, 238, 238);
    //             margin-bottom: 30px;
    //             white-space: pre-line;
    //             border-radius: 10px;
    //           }

    //           .wedding_date {
    //             padding: 10px;
    //             font-size: 20px;
    //             color: #f05537;
    //             font-weight: 100;
    //             display: inline-block;
    //             background: #fff9f4;
    //           }

    //           img {
    //             padding-bottom: 20px;
    //             width: 100%;
    //           }

    //         }

    //         .form_content {
    //           width: 55%;
    //           display: flex;
    //           justify-content: center;
    //           align-items: center;
    //           background: #fffaf6;
    //           background-image: url(../assets/img/form-bkg.png);
    //           background-repeat: no-repeat;
    //           background-position: 100%;
    //           background-size: cover;
    //           background-color: #fffaf6;

    //           img {

    //             width: 100% !important;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    .table-list {
      overflow-x: scroll;

      table {
        tr {
          th {
            text-align: center;
          }

          td {
            text-align: center;
          }
        }
      }
    }

    .tab_flex {
      form {
        margin-bottom: 30px;
      }
    }
  }
}
.upload_help {
  text-align: center;
  margin: 50px 0;

  p {
    padding-top: 10px;
  }

  .upload_field {
    border: 1px solid #cbcbcb;
    padding: 10px;
    background: #fff;
    width: 100%;
  }

  [type='file']::-webkit-file-upload-button {
    background: var(--orange);
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 10px 20px;
    margin-right: 20px;
    border: none;
  }
}

/* guests Page - Message Guests pop up*/
.message_popover_panel {
  .popover_body {
    header {
      padding: 0 50px;

      h1 {
        font-size: 24px;
        display: flex;
        align-items: center;

        span {
          i {
            margin-right: 10px;
          }
        }
      }
    }

    .group_manager {
      padding: 32px 0;

      .gc--2-of-3 {
        @extend %flex-center;
        place-items: center;
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 10px 40px;
      }

      ul {
        &.nacc {
          @extend %flex-center;
          width: 100%;
          height: 100% !important;
          position: relative;
          height: 0px;
          list-style: none;
          margin: 0;
          padding: 0;
          transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;

          li {
            opacity: 0;
            transform: translateX(50px);
            position: absolute;
            list-style: none;
            transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
            width: 100%;

            .buttn {
              position: absolute;
              top: -60px;
              right: 0;
            }

            .email_container {
              display: flex;
              justify-content: center;

              .email_sender {
                max-width: 800px;
                border: 3px solid #939393;
                background: #eee;
                width: 100%;
                position: relative;
                margin-top: 80px;

                .email_title {
                  padding: 5px;
                  border-bottom: 1px solid #cacaca;
                }

                .from_send {
                  padding: 5px 15px;
                  text-align: left;
                  border-bottom: 1px solid #cacaca;

                  div {
                    font-size: 15px;
                  }

                  span {
                    color: #979797;
                  }
                }
              }

              .email_row {
                display: flex;
                justify-content: center;
              }

              .email_content {
                padding: 30px 50px;
                background: #fff;
                max-width: 460px;
                border-radius: 20px;
                margin: 30px;
                border: 1px solid #a09f9f;

                h3 {
                  margin-bottom: 15px;
                }
              }

              p {
                margin-bottom: 15px;
              }
            }

            &.active {
              transition-delay: 0.3s;
              z-index: 2;
              opacity: 1;
              transform: translateX(0px);
              text-align: center;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

/* guests Page - Compose pop up*/
.message_compose_panel {
  .new_email_container {
    display: flex;
    height: 100%;

    .left_email {
      width: 100%;
      border-right: var(--border);
      height: 100%;

      .header_left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;
        width: 100%;
        min-height: var(--header-height);
        border-bottom: var(--border);
        box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
        border-bottom: var(--border);

        span {
          cursor: pointer;
          display: flex;
          align-items: center;

          i {
            margin-right: 10px;
          }
        }
      }

      .email_container {
        display: flex;
        justify-content: center;
        padding: 50px;

        .email_sender {
          max-width: 800px;
          border: 3px solid #939393;
          background: #eee;
          width: 100%;
          text-align: center;

          .email_title {
            padding: 10px 0;
            border-bottom: 1px solid #cacaca;
          }

          .from_send {
            padding: 5px 15px;
            text-align: left;
            border-bottom: 1px solid #cacaca;

            div {
              font-size: 15px;
              padding: 3px 0;
            }

            span {
              color: #979797;
            }
          }
        }

        .email_row {
          display: flex;
          justify-content: center;
        }

        .email_content {
          padding: 30px 50px;
          background: #fff;
          max-width: 460px;
          border-radius: 20px;
          margin: 50px;
          border: 1px solid #a09f9f;

          h3 {
            margin-bottom: 15px;
          }
        }

        p {
          margin-bottom: 15px;
        }
      }
    }

    .right_email {
      width: 100%;
      flex-direction: column;
      display: flex;
      max-width: 400px;
      flex-wrap: wrap;
      position: relative;

      .nw_email {
        width: 100%;
        flex-direction: column;
        display: flex;
        max-width: 400px;
        height: 100%;
        position: absolute;
        background: #fff;

        .header_right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          width: 100%;
          min-height: var(--header-height);
          border-bottom: var(--border);
          box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
          border-bottom: var(--border);

          span {
            display: flex;
            gap: 6px;
            align-items: center;
            cursor: pointer;

            i {
              font-size: 28px;
            }
          }
        }

        .form_input {
          flex: 1;
          overflow: auto;
          padding: 16px;

          .select {
            position: relative;
            margin-bottom: 15px;
            border: var(--border);
            box-shadow: none;
            width: 100%;
          }
        }

        .group_field_container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          max-width: 600px;

          .form_group_title {
            height: 40px;
            display: flex;
            align-items: center;
            border-bottom: var(--border);
            margin: 16px 0;
          }

          .line_contact {
            display: flex;
            justify-content: center;
            background: #eee;
            padding: 10px;
            border-radius: 20px;
            margin: 10px 0;
          }

          .form-check {
            margin: 5px 0;

            label {
              &::before {
                content: none;
              }
            }
          }
        }

        .field_container {
          &.title_action_panel {
            display: flex;
            align-items: center;
            gap: 16px;

            .field_title {
              margin-bottom: 0;
            }
          }

          .field_title {
            font-size: 14px;
            margin-bottom: 8px;
          }

          .element_repeat {
            display: flex;
            align-items: center;
            gap: 16px;

            .fa-trash {
              color: #ccc;
            }
          }
        }

        .multi_field_container {
          display: flex;
          gap: 16px;
          align-items: flex-end;
        }
      }

      .new-email {
        z-index: 999;
      }

      .add-guest {
        z-index: 99;
      }

      .add-recipient {
        z-index: 9;
      }

      &.recipient {
        .add-guest {
          z-index: 9999;
        }
      }

      &.add-new-guest {
        .add-recipient {
          z-index: 99999;
        }
      }

      &.save-btn {
        .add-guest {
          z-index: 999999;
        }
      }
    }
  }
}

// full page - Request info Link
.content_list {
  .box-content {
    padding: 20px;
    border-radius: 10px;
    background: #fafafa;

    @media (max-width: 583px) {
      .right_content {
        display: none !important;
      }
    }

    .copy {
      margin-top: 20px;

      form {
        position: relative;
        width: 100%;
        display: flex;
        gap: 12px;

        input {
          display: block;
          outline: 0;
          background: #eee;
          font-size: 15px;
          padding: 10px 10px;
          margin-bottom: 0px;
          width: 30%;
          color: #212529;
        }

        .btn {
          padding: 0px 10px;
        }
      }

      @media (max-width: 767px) {
        form {
          flex-direction: column;

          input {
            width: 100% !important;
          }

          .btn {
            width: 100% !important;
            padding: 10px 15px !important;
          }
        }
      }

      @media (min-width: 768px) and (max-width: 991px) {
        input {
          width: 52% !important;
        }

        .btn {
          width: 46% !important;
          padding: 4px 15px !important;
        }
      }
    }

    .both_content {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      @media (max-width: 744px) {
        gap: 10px;
      }

      .left_content {
        width: 32%;
        padding: 20px;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #ebe6e6;

        .field_container {
          display: block;

          .field_title {
            margin-bottom: 10px;
          }
        }

        .group_field_container {
          gap: 20px;

          &.wedding_date_calender {
            display: flex;
            flex-direction: row !important;

            .btn_calender {
              form {
                width: auto;
              }
            }
          }
        }

        .btn.big {
          font-size: 16px;
          border-radius: 3px;
          padding: 6px 12px;
          margin-top: 20px;
        }
      }

      .right_content {
        width: 65%;
        display: flex;
        border: 1px solid #ebe6e6;
        overflow: hidden;
        text-align: center;
        padding: 20px;
        border: 1px solid #bc8129;
        background: #fffcf7;

        border-radius: 6px;

        .card_content {
          width: 38%;
          background: #f1e4d5;
          padding: 10px;

          position: relative;
          // box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
          // border-right: 1px solid #ccc;

          p {
            display: inline-block;
            width: 100%;
            overflow-wrap: break-word;
            background-color: #f6ede2;
            opacity: 0.8;
            padding: 10px;

            margin-bottom: 30px;
            white-space: pre-line;
          }

          .wedding_date {
            padding: 10px;
            font-size: 20px;
            color: var(--orange);
            font-weight: 400;
            display: inline-block;
          }

          img {
            padding-bottom: 20px;
            width: 100%;
          }
        }

        .form_content {
          width: 62%;
          display: flex;
          justify-content: center;
          align-items: center;

          background-image: url(../assets/img/form-bkg.png);
          background-repeat: no-repeat;
          background-position: 100%;
          background-size: cover;
          background-color: #f7f4f1;

          img {
            width: 100% !important;
          }
        }
      }

      @media (max-width: 583px) {
        .left_content {
          width: 100%;
        }

        .right_content {
          width: 100%;

          .card_content {
            width: 50% !important;
          }

          .form_content {
            width: 50%;
          }
        }
      }
    }
  }
}

.action-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 8px 15px;
  height: 40px;

  background: linear-gradient(to right, #bc8129 0%, #bc8129 60%, #9d6717 60%, #9d6717 100%);
  border-radius: 3px;

  .active-bn {
    gap: 20px !important;
    color: #fff;

    .ant-space-item {
      display: flex;
      align-items: center;
    }
  }
}

// New Guest Details Design
.guest_list_data_table {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow: auto !important;
  min-height: 0px;
  height: 100vh;
  font-family: 'Arima', system-ui;

  .rdt_TableRow {
    align-items: flex-start;
    padding: 10px 0;
  }

  .expend_data_child_row {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .rdt_ExpanderRow {
    margin: 0 !important;
    width: 100% !important;
    border-left: 0 !important;
    background-color: #fff3ee;
  }

  .rdt_TableHeadRow {
    font-weight: 600;
  }

  @media (max-width: 1400px) {
    max-height: 500px;
  }
}

// .new_guest_details {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;

//   gap: 10px;
//   white-space: normal !important;

//   .no-of-guest {
//     line-height: 0;
//     display: inline-flex;
//     height: 32px;
//     width: 32px;
//     align-items: center;
//     justify-content: center;
//     border-radius: 3px;
//     border: 1px dotted var(--orange);
//     color: var(--orange);
//   }

//   .name_panel {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 6px;
//   }

//   .title_small {
//     font-size: 14px;
//     font-weight: 600;
//     text-align: left;

//     &.with_guest {
//       display: flex;
//       flex-direction: column;
//       gap: 6px;
//     }
//   }

//   h4 {
//     font-weight: 600;
//     font-size: 16px;
//   }

//   .events_name_new {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 8px;
//   }

//   &.invitation {
//     gap: 16px;

//     .rsvp_details_new {
//       display: flex;
//       flex-wrap: wrap;
//       gap: 10px;

//       P {
//         font-weight: 600;
//         font-size: 14px;

//         &.not_submitted {
//           color: red;
//         }

//         &.submitted {
//           color: green;
//         }
//       }
//     }
//   }

//   @media (max-width: 600px) {
//     h4 {
//       font-size: 15px;
//     }
//   }

//   // &.with_rsvp {
//   //   border-bottom: 1px dashed #ccc;
//   //   padding-bottom: 24px;
//   //   gap: 24px;

//   //   .rsvp_panel {
//   //     display: flex;

//   //     h4 {
//   //       font-size: 17px;
//   //       font-weight: 600;
//   //       color: #f05537;
//   //     }

//   //     .rsvp_tag {
//   //       display: inline-flex;
//   //       flex-direction: column;

//   //       span {
//   //         text-align: left;

//   //         &:first-child {
//   //           font-weight: 600;
//   //         }

//   //         &:last-child {
//   //           font-weight: 600;
//   //         }
//   //       }

//   //       &.orange {
//   //         span {
//   //           &:last-child {
//   //             color: red;
//   //           }
//   //         }
//   //       }

//   //       &.green {
//   //         span {
//   //           &:last-child {
//   //             color: green;
//   //           }
//   //         }
//   //       }
//   //     }

//   //     P {
//   //       font-weight: 600;
//   //       font-size: 14px;

//   //       &.not_submitted {
//   //         color: red;
//   //       }

//   //       &.submitted {
//   //         color: green;
//   //       }
//   //     }

//   //     .invited_for {
//   //       @media (max-width: 600px) {
//   //         flex-direction: column;
//   //       }
//   //     }
//   //   }

//   //   &:last-child {
//   //     border: 0;
//   //     padding-bottom: 0;
//   //   }
//   // }
// }

.invited-for {
  .list_box_invite {
    width: 100%;
    border-bottom: 1px solid #b0afaf;
    padding: 0 0 20px 0;

    .event_name_list {
      width: 30%;
    }

    .allowed_guest_panel {
      display: flex;
      gap: 12px;
      justify-content: center;
      width: 15%;
    }

    .guest_child_panel {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      width: 50%;
    }

    @media (max-width: 576px) {
      flex-direction: column;

      .event_name_list {
        width: 100%;
      }

      .allowed_guest_panel {
        width: 100% !important;
        justify-content: flex-start;
      }

      .guest_child_panel {
        width: 100%;
      }
    }
  }
}

.guest_selection_row {
  .event_list_container {
    overflow: unset;

    .group_guest_box {
      position: relative;

      &::before {
        content: '';
        border: 1px solid var(--orange);
        border-radius: 50px;
        height: 100%;
        width: 55px;
        left: 41px;

        position: absolute;
        margin-top: 6px;

        @media (max-width: 576px) {
          width: 40px;
          left: 30px;
        }
      }
    }

    .guest_img {
      width: 40px;
      height: 40px;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      min-width: 40px;
      min-height: 40px;
      flex-shrink: 0;

      img,
      i {
        border-radius: 50%;
      }
    }

    .list_box {
      &:not(:last-child) {
        border: none;
        padding-bottom: 16px;
      }

      &:first-child {
        padding-top: 15px;
        border-top: 1px solid #eee;
      }

      .list_text {
        position: static;

        .event_text {
          font-weight: 400;
        }

        &:first-child {
          border-bottom: 1px solid #eee;
        }
      }
    }

    @media (max-width: 576px) {
      .list_box {
        gap: 10px !important;

        .list_text {
          .event_text {
            font-size: 15px !important;
          }
        }

        .guest_img {
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }
    }
  }
}

.tab-content {
  .content_list {
    .both_content {
      .right_content {
        padding: 10px;

        .card_content {
          width: 45%;
        }
      }
    }

    .box-content {
      p {
        font-size: 16px;
        @media (max-width: 768px) {
          font-size: 10px !important;
        }
      }
    }

    .container {
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
  }
}

.help_center {
  .nav {
    li {
      &:nth-child(3) {
        .nav-link {
          margin: 0 !important;
        }
      }
    }

    .nav-link {
      padding-top: 0 !important;
    }
  }

  .help_row {
    .image_window {
      display: flex;
      height: 100%;
      width: 100%;
      position: relative;
      &::after {
        content: '\f08e';
        position: absolute;
        font-family: 'Font Awesome 5 Pro';
        justify-content: flex-end;
        display: flex;
        width: 100%;
        padding: 10px;
      }
    }

    @media (max-width: 480px) {
      border-bottom: 1px solid #cbcbcb;
      width: 100%;
      margin-bottom: 17px;
    }

    .btn-group {
      button {
        color: #fff;
        background-color: var(--orange) !important;
        margin-right: 5px;
        width: 28px;
        height: 28px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
          display: none;
        }
      }
      .dropdown-menu {
        width: 370px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 15px !important;
        @media (max-width: 480px) {
          width: 280px;
        }
      }
      @media (max-width: 480px) {
        padding-bottom: 15px;
      }
    }
  }
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column-reverse;
  }
}
