@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *; // Import mixins for background properties
@use '../abstracts/functions' as func; // Import functions for height calculations
@use '../abstracts/breakpoints' as *;
@use '../themes/default/colors' as *;

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1296px;
  padding-left: 15px;
  padding-right: 15px;
}

.section_padding {
  padding: var(--s-16) 0;
  @include respond-to('laptop') {
    padding: var(--s-32) 0;
  }
}

.mobile_hide {
  display: none;
  @include respond-to('laptop') {
    display: block;
  }
}

.desktop_hide {
  display: block;
  @include respond-to('laptop') {
    display: none;
  }
}

.text-shadow {
  @include text-shadow(2px 2px 8px rgba($black, 1));
}

.mw-700 {
  max-width: calc(var(--s-72) * 10);
}
.mw-1100 {
  max-width: calc(var(--s-72) * 15);
}

.pt-36 {
  padding-top: var(--s-36);
}

.pt-72 {
  padding-top: var(--s-72);
}

.img-mw-300 {
  max-width: calc(var(--s-72) * 4);
  @include respond-to('tablet-portrait') {
    max-width: calc(var(--s-72) * 6);
  }
}

.mb-negative-56 {
  margin-bottom: 0;

  @include respond-to('tablet-portrait') {
    margin-bottom: -56px;
  }
}

.mb-negative-65 {
  margin-bottom: 0;
  @include respond-to('tablet-portrait') {
    margin-bottom: -65px;
  }
}

.ant-btn-compact-first-item {
  display: none;
}
.ant-btn-compact-last-item {
  @include flex-direction(flex, center, center, row);

  border-radius: var(--s-6) !important;
}

.fix_aside_whatapp {
  @include flex-direction(inline-flex, center, center, row);

  position: fixed;
  z-index: $zindex-popover;
  right: 0;
  top: 50%;
  height: 2.375rem;
  width: 2.375rem;
  border-radius: var(--s-4) 0 0 var(--s-4);
  padding: var(--s-6);
  gap: var(--s-8);
  background-color: #25d366;
  color: $white;
  transition: width 0.5s ease-in-out;
  white-space: nowrap;
  i {
    @include flex-direction(flex, center, center, row);
    font-size: 30px;
    color: $white;
  }
  &:hover {
    text-decoration: none;
  }
}
