/**
 *
 * This File contains the variable for Default Theme 
 *
 * Dependencies:
 * - Relies on color variables imported from './colors' module.
 *
 * @author: Ganesh Ughade
 * @version: 1.0
 */


/**
 * Button Variables
 *
 * Defines variables for customizable button styles, utilizing color variables
 * imported from './colors' for primary and secondary button colors.
 *
 * Button Colors:
 * - Sets primary button color based on 'primary-color' from 'colors' module.
 * - Defines secondary button color based on 'secondary-color' from 'colors' module.
 *
 * Button Styling:
 * - Specifies border radius for rounded corners of buttons.
 * - Defines padding for internal spacing within buttons.
 * - Sets border width for button outlines.
 *
 * Example Usage:
 * - These variables can be used in conjunction with '.btn' class styles to create
 *   consistent and themed button designs throughout the application.
 *
 */

@use './colors' as color; // Import color variables

// Primary button color based on 'primary-color' from 'colors' module
$button-primary-color: color.$primary-color;

// Secondary button color based on 'secondary-color' from 'colors' module
$button-secondary-color: color.$secondary-color;

// Border radius for rounded corners of buttons
$button-border-radius: 4px;

// Padding for internal spacing within buttons
$button-padding: 10px 20px;

// Border width for button outlines
$button-border-width: 2px;