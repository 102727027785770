@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

.contact_us_section {
  padding: 24px;
  @include flex-direction(flex, flex-start, center, row);
  .contact_info_container {
    @include flex-direction(flex, flex-start, center, column-reverse);
    gap: 16px;
    .map {
      background-color: $white;
      padding: var(--s-16);
      border-radius: var(--s-8);
      width: 100%;
      max-width: 500px;
      border: 1px solid #ccc;
      @include respond-to('tablet-portrait') {
        display: block;
      }
      .contact_form_container {
        a,
        button {
          max-width: fit-content;
        }
        .capcha {
          transform: scale(0.8);
          transform-origin: top left;
          @include respond-to('tablet-portrait') {
            transform: unset;
            transform-origin: unset;
          }
        }
      }
    }
    @include respond-to('tablet-portrait') {
      flex-direction: row;
    }
  }
  .text_container {
    @include flex-direction(flex, flex-start, center, column);
    gap: 10px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    p {
      line-height: unset;
      text-align: center;
    }
  }
}
