.order_tracking {
  padding: 24px 0;
}

.order_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 7px 27px rgba(44, 41, 37, 0.06), 0 4px 16px rgba(0, 0, 0, 0.07);
  padding: 12px;
  border-radius: 5px;

  .order-id {
    display: flex;
    gap: 16px;
    align-items: center;
    color: #a39e9e;

    div {
      font-size: 22px;

      span {
        font-weight: 600;
        margin-left: 10px;
        color: #444;
      }
    }

    .tag_container {
      display: inline-flex;
      align-items: center;

      .tag {
        height: 26px;
        box-sizing: border-box;
        padding: 0 12px;
        font-size: 14px;
        border-radius: 16px;
        background-color: #0ab767;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
  }

  .order_time_date {
    display: inline-flex;
    gap: 8px;
    color: #6b6e77;

    .day {
      display: inline-flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      font-weight: unset;
      color: #6b6e77;

      i {
        font-size: 18px;
      }
    }
  }

  .order_description {
    display: flex;
    gap: 16px;
    border-top: 1px dashed #9f9c9c;
    padding-top: 20px;
    margin-top: 10px;

    .order_icon {
      border-radius: 5px;
      width: 100px;
      height: auto;
      overflow: hidden;
    }

    .information {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;

      p {
        padding: 0;
        margin: 0;
        font-size: 18px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .coupon_details {
        display: inline-flex;
        align-items: center;
        font-size: 15px;
        gap: 6px;
        color: #6b6e77;

        span {
          color: #bc8129;
        }
      }
    }
  }
}

//Time-line style
.order_tracking_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;

  .order_tracking_list {
    h2 {
      font-size: 24px;
    }

    .order_tracking_box {
      display: flex;
      padding: 20px;
      border: 1px solid #eee;
      flex-direction: column;
      border-radius: 8px;

      @media (max-width: 480px) {
        padding: 10px;
      }
      .table {
        thead {
          background-color: #f9f5f0;
          tr {
            th {
              font-weight: 600;
            }
          }
        }
        tbody {
          tr {
            th {
              @media (max-width: 480px) {
                font-size: 14px;
              }
            }
          }
          tr {
            td {
              @media (max-width: 480px) {
                font-size: 14px;
              }
            }
          }
        }
        tfoot {
          background-color: #f9f5f0;
          tr {
            td {
              font-weight: 600;
              font-size: 16px;

              @media (max-width: 480px) {
                font-size: 14px;
              }
            }
          }
        }
      }
      .order_details_wrap {
        display: flex;
        gap: 30px;
        .order_details {
          flex-direction: column;
          display: flex;

          &::after {
            content: '';
            width: 1px;
            height: auto;
          }
        }
      }
    }
    .sessions {
      margin: 0;
      border-radius: 12px;
      position: relative;
      padding-left: 0;
    }

    li {
      padding-bottom: 35px;
      border-left: 2px dotted #c1c1c1;
      position: relative;
      padding-left: 20px;
      margin-left: 10px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        border: 0px;
        padding-bottom: 0;
      }

      &:before {
        content: '';
        width: 22px;
        height: 22px;
        border-radius: 50%;
        position: absolute;
        left: -12px;
        top: 0px;
        background-color: #c1c1c1;
      }

      &.active {
        &:before {
          content: '\f00c';
          background: var(--orange);
          font-family: 'Font Awesome 5 Pro';
          font-size: 14px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.present {
          &:before {
            content: '\f2f2';
            background: var(--orange);
            font-family: 'Font Awesome 5 Pro';
            font-size: 14px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .time {
            font-weight: 600;
          }
          p {
            font-weight: 600;
          }
        }

        &.completed {
          span {
            p {
              opacity: 0.5;
            }
          }
        }
      }
      p {
        margin-bottom: 0px;
      }
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .time {
      font-size: 14px;
      color: #bcbcbc;
    }
    .date {
      font-size: 18px;
      color: #777;
    }
  }

  .no_search_by_box {

    padding: 80px;
    background: #fff6ed;
    // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
}
// advertisment box
.advertisment_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .add_box {
    width: 320px;
    height: 320px;
    background-color: #f9f5f0;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
    &:last-child {
      background-color: #f3f1f1;
    }
  }
  @media (max-width: 800px) {
    display: none;
  }
}
