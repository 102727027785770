/**
 * Button Component Styles
 *
 * Defines styles for customizable buttons with primary and secondary variations,
 * leveraging theme variables for consistent design across the application.
 *
 * Base Styles:
 * - Sets display to inline-block for inline placement.
 * - Applies padding and border radius using theme variables for consistency.
 * - Defines font size, text alignment, decoration, and cursor behavior.
 * - Implements smooth transition effects for hover, active, and focus states.
 *
 * Primary Button:
 * - Utilizes primary theme colors for background, text, and border.
 * - Implements hover, active, and focus effects for interactive states.
 *
 * Secondary Button:
 * - Uses secondary theme colors for background, text, and border.
 * - Defines consistent interactive effects for hover, active, and focus states.
 *
 * Example Usage:
 * ```
 * <button class="btn primary">Primary Button</button>
 * <button class="btn secondary">Secondary Button</button>
 * ```
 *
 * Dependencies:
 * - Relies on theme variables imported from '../themes/default/variables'.
 *
 * @author: Ganesh Ughade
 * @version: 1.0
 */

@use '../themes/default/variables' as *; // Import default theme variables
@use '../themes/default/colors' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/breakpoints' as *;

.btn {
  display: inline-block;
  border-radius: $button-border-radius;
  font-size: 1rem;
  height: 2.375rem;
  min-width: var(--s-72);
  box-sizing: border-box;
  padding: var(--s-6) var(--s-12);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: $white;
  &.small {
    font-size: 0.75rem;
    padding: var(--s-6);
    height: unset;
    min-width: var(--s-72);
    @include respond-to('laptop') {
      padding: var(--s-6) var(--s-12);
      height: 2.375rem;
      font-size: 1rem;
      min-width: var(--s-72);
    }
  }
  &.text_link_only {
    padding: 0;
    background-color: transparent;
    color: $primary-color;
    height: unset;
    min-width: unset;
    border: 0 !important;
    font-size: var(--s-12);
    font-weight: 600;
    @include respond-to('laptop') {
      font-size: 1rem;
    }
  }

  &.primary {
    background-color: $button-primary-color;
    color: $white;
    border: $button-border-width solid $button-primary-color;
    border-color: $button-primary-color !important;

    &:hover {
      transform: translateY(-2px); // Example hover effect
    }

    &:active {
      transform: translateY(2px); // Example active effect
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($button-primary-color, 0.3); // Example focus style
    }
  }

  &.secondary {
    background-color: $button-secondary-color;
    color: $white;
    border: $button-border-width solid $button-secondary-color;

    &:hover {
      transform: translateY(-2px); // Example hover effect
    }

    &:active {
      transform: translateY(2px); // Example active effect
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($button-secondary-color, 0.3); // Example focus style
    }
  }
  &.without_bakground {
    border: 0 !important;
    background-color: transparent;
    color: $gray-midium;
  }
  &.with_icon {
    @include flex-direction(inline-flex, center, center, row);

    gap: var(--s-8);
  }
}

.toggle_btn_group {
  .btn {
    &.primary {
      color: $button-primary-color !important;
      &.active {
        color: $white !important;
      }
    }
  }
}
