/* Bootstrap scss */
@use '~bootstrap/scss/bootstrap';

@use '../../abstracts/variables' as *;
@use '../../themes/default/variables' as *;
@use '../../abstracts/mixins' as *;
@use '../../themes/default/colors' as *;
@use '../../abstracts/breakpoints' as *;
@use '../../components/buttons' as *;

.search_by_name_filter {
  @include flex-direction(flex, space-between, center, row);

  .search-button {
    @include flex-direction(inline-flex, flex-start, center, row);

    border: 1px solid $grayLighter;
    border-radius: var(--s-4);
    background-color: $white;
    overflow: hidden;
    width: 200px;

    button,
    input {
      border-radius: 0;
      border: 0;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }

    input {
      font-size: var(--s-14);
    }

    button {
      padding: 0;
      min-width: unset;
      width: 40px;
      height: 40px;
    }

    @include respond-to('laptop') {
      width: 100%;
      max-width: calc(var(--s-72) * 4);
    }
  }
}

.advance_filer_container {
  @include flex-direction(flex, flex-start, center, row);

  flex-wrap: wrap;
  max-width: calc(var(--s-72) * 12);
  gap: var(--s-8);
  margin-top: var(--s-8);

  .details_filter_box {
    @include flex-direction(flex, flex-start, center, row);
    width: 100%;
    gap: var(--s-8);

    @include respond-to('laptop') {
      gap: var(--s-40);
    }
  }

  .search_panel_vendor {
    margin: 0;
    max-width: unset;
  }

  .more-filter_icon {
    @include flex-direction(inline-flex, center, center, row);
    background-color: $white;
    border: 1px solid #d9d9d9;
    border-radius: var(--s-4);
    cursor: pointer;
    font-size: var(--s-14);
    gap: var(--s-8);
    height: 2.5rem;
    width: 2.5rem;
    padding: 0 var(--s-8);
    box-sizing: border-box;

    .text {
      display: none;
    }
  }

  @include respond-to('laptop') {
    .more-filter_icon {
      height: 4.5rem;
      width: auto;
      font-size: var(--s-16);

      .text {
        display: inline-block;
      }
    }
  }
}

.search_tag_container {
  @include flex-direction(flex, flex-start, flex-start, row);

  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
  width: 100%;

  div {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .ant-tag {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 4px;
    background-color: #fff7d1;
  }

  @media (max-width: 1000px) {
    +span {
      display: none !important;
    }
  }
}