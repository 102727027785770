// New FAQ Section
.Communication-wrap {

  padding: 10px 0px;

  @media (max-width:991px) {

    padding: 0px;

  }

  .border_communcation {
    border-top: 1px solid #e4e5e9;

    h2 {
      font-weight: 400;
      text-align: left;
      font-size: 24px;
      color: #bc8129;
    }

    .communcation_left {
      height: 100%;
      width: 100%;
      border-right: 1px solid #e4e5e9;
      display: flex;
      align-items: flex-start;
      padding-top: 15px;

      .nav-item {
        .nav-link {
          padding: 8px;
          display: flex;
          gap: 20px;
          padding-left: 10px;
          border-radius: 0;
          color: rgb(89, 100, 104);
          font-size: 16px;
          flex-direction: column;
          font-weight: 600;
          background-color: rgba(255, 255, 255, 0.8);
          border-left: 4px solid transparent;
          width: 100%;
          border-color: #fff #fff #fff;
          text-align: left;

          &:hover {

            background-color: #fffcf7;
          }

          img {
            width: 60px;
          }

          &.active {
            color: #596468;
            background-color: #fff;
            border-left: 4px solid #bc8129;
            position: relative;
            border-radius: 0;
            border-color: #fff #fff #fff #bc8129;
            background-color: #fffcf7;
          }

          i {
            margin-right: 8px;
          }
        }
      }
    }

    .tab-content {
      width: 100%;
      padding: 10px;
      border-radius: 20px;
      height: 100%;
    }

    .accordion {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-top: 0;

      .accordion-item {
        background-color: #fff;
        padding: 20px;
        border-radius: 20px;
        border: 0;

        .accordion-button {
          background-color: #fff;
          color: #596468;
          padding: 20px 0;
          font-size: 20px;

          &:not(.collapsed) {
            box-shadow: none;
          }

          &::after {
            content: "\f13a";
            font-family: "Font Awesome 5 Pro";
            display: block;
            font-weight: 900;
            background-image: none;
          }

          &:focus {
            box-shadow: none;
          }

          &:not(.collapsed)::after {
            color: #72beff;
          }
        }

        .accordion-collapse {
          .accordion-body {
            padding: 10px 0;
            color: #596468;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .tab-content {
        .tab-pane {
          border-top: 1px solid #d1d1d1;

          &:nth-child(1) {

            border: none;
          }
        }

        &.accordion {
          gap: 0px !important;
        }
      }

      .accordion {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .accordion-button {
          background-color: transparent;
          color: #596468;
          padding: 20px;
          font-size: 20px;
          border-radius: 20px;
          display: flex;
          gap: 20px;

          &:not(.collapsed) {
            box-shadow: none;
          }

          &:focus {
            box-shadow: none;
          }

          &:not(.collapsed)::after {
            color: #72beff;
          }
        }

        .main-accordation {
          img {
            width: 50px;
          }

          .accordion-button {
            background-color: transparent;
            color: #596468;
            padding: 20px;
            font-size: 20px;
            border-radius: 20px;
            display: flex;
            gap: 20px;

            &:not(.collapsed) {
              box-shadow: none;
            }

            &::after {
              background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
              transform: scale(0.7) !important;
            }

            &:not(.collapsed)::after {
              background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
            }

            &:focus {
              box-shadow: none;
            }

            &:not(.collapsed)::after {
              color: #72beff;
            }
          }
        }

        .accordion-collapse {
          &.show {}

          .accordion-body {
            .accordion-item {
              color: #596468;

              >.accordion-item {
                background-color: #ebebeb !important;
              }
            }
          }
        }
      }

      .tab-content {
        width: 100%;
        padding: 0px;
        max-width: 880px;
        background: #fffcf7;
        border-radius: 20px;
      }
    }
    .communication_details {
      .compose_message {
        padding-top: 16px;
        padding-right: 16px;
        display: flex;
        justify-content: flex-end;
        .btn-group{
          button{
            border: 0 !important;
          }
        }
        .dropdown-menu {
          transform: unset !important;
          inset: unset !important;
          right: 0 !important;
          top: 40px !important;
          padding: 0;
          li{
            .dropdown-item{
              display: flex;
              height: 36px;
              align-items: center;
              
              span{
                width: 24px;
                height: 24px;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
              }
            }
            &:not(last-child) {
              border-bottom:1px solid #ccc;
            }
          }
          

        }
      }
    }

    @media (max-width:767px) {
      .communication_tabs {
        max-width: 100% !important;
        width: 100%;
      }
      .communication_details{
        display: none;
      }
    }

    .on_mobile_only {
      display: none;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;

        li {
          button {
            padding: 0;
            font-size: 16px;
            color: #bc8129;

            span {
              width: 24px;
              height: 24px;
              display: inline-flex;
              i{
                font-size: 16px;
              }
            }
          }
        }
      }
    }


    @media (max-width:991px) {
      .communcation_left {
        border: 0;
       
        .nav_panel {
          width: 100%;
          margin: 0 !important;
        }

        .nav-link {
          height: 48px;
          justify-content: center;
          &.active {
            + .on_mobile_only {
              display: flex;
              border-left: 4px solid #bc8129;
              padding-left: 10px;
            }
          }
        }
      }

      .compose_message {
        display: none;
      }

      .right_email {
         position: absolute;
         top: 0;
         right: 0;
         height: 100vh;
         box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),-3px 0 6px -4px rgba(0, 0, 0, 0.12),-9px 0 28px 8px rgba(0, 0, 0, 0.05);
         &.close {
          display: none;
         }
      }
    }
  }

  // faq section
}

section.faq-row {
  background: #1d1a4d;
  padding: 50px 0;

  .nav-pills {
    .nav-link {
      border-radius: 0 !important;
      padding: 10px;
      margin: 10px;
      text-align: left;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 8px !important;

      &.active {
        background-color: #fff;
        color: #000;
      }

      i {
        margin-right: 8px;
      }
    }
  }

  .accordion {
    .accordion-item {
      background-color: transparent;

      .accordion-button {
        background-color: transparent;
        color: #fff;
        padding: 20px 0;

        &:not(.collapsed) {
          box-shadow: none;
        }

        &::after {
          filter: brightness(0) invert(1);
        }

        &:focus {
          box-shadow: none;
        }
      }

      .accordion-collapse {
        .accordion-body {
          padding: 10px 0;
          color: #fff;
        }
      }
    }
  }
}


.group_manager {
  padding: 20px 0 0 0 !important;

  .event_slogan {

    h1 {
      padding-bottom: 5px;
      font-size: 20px !important;
    }
  }

  .container_width_1650px {
    max-width: 1650px;
    margin: auto;

    .email_sender {

      .email_container {
        display: flex;
        justify-content: center;

        .email_sender {
          max-width: 800px;
          border: 3px solid #939393;
          background: #eee;
          width: 100%;
          position: relative;
          margin-top: 10px;

        }
      }
    }
  }
}

.communication_box {
  .ant-drawer-body {
    padding: 16px 24px;

    @media (max-width:991px) {

      padding: 10px;

    }
  }

  

  .left_content {

    .field_container {

      display: flex !important;
    }
  }
}

.tab_container {
  flex: 1;
  overflow: hidden;

  .communication_tab {



    &.nav-tabs {
      .nav-item {
        position: relative;

        .nav-link {
          color: #000 !important;
          list-style: none;
          display: flex;
          align-items: center;
          border-bottom: 5px solid transparent !important;
          border-bottom-color: transparent;
          height: 100% !important;
          font-weight: 600;
          border: none;
          padding: 5px 0px;
          margin-right: 30px;
          text-align: left;

          &.active {
            border-color: var(--orange) !important;
          }

          &:hover {
            color: #6e6a93 !important;
          }
        }
      }
    }
  }

  .group_field_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 600px;

    .form_group_title {
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: var(--border);
      margin: 16px 0;
    }
  }

  .field_container {
    &.title_action_panel {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      .field_title {
        margin-bottom: 0;
      }
    }

    .field_title {
      font-size: 14px;
      margin-bottom: 8px;
    }

    .element_repeat {
      display: flex;
      align-items: center;
      gap: 16px;

      .fa-trash {
        color: #ccc;
      }
    }

    .note_content {
      width: 50%;
      padding: 10px;
      border: 1px solid #eee;
      display: flex;
      gap: 8px;

      p {
        font-size: 11px;
        margin: 0;
      }
    }
  }

  .multi_field_container {
    display: flex;
    gap: 16px;
    align-items: flex-end;

    &.new_guest_add {
      &:not(:first-child) {
        border-top: var(--border);
        padding-top: 16px;
        margin-top: 16px;
      }
    }
  }

  .content_list {
    .both_content {
      margin-top: 20px;

      .left_content {

        .group_field_container {

          .field_container {

            label.field_title {
              font-size: 18px;
            }

            input {

              padding: 10px;
            }
          }
        }
      }
    }

 
  }

  @extend .content_list;

  .table-list {
    overflow-x: scroll;

    table {
      tr {
        th {
          text-align: center;
        }

        td {
          text-align: center;
        }
      }
    }
  }

  .upload_help {
    text-align: center;
    margin: 50px 0;

    p {
      padding-top: 10px;
    }

    .upload_field {
      border: 1px solid #cbcbcb;
      padding: 10px;
      background: #fff;
      width: 100%;
    }

    [type='file']::-webkit-file-upload-button {
      background: var(--orange);
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      outline: none;
      padding: 10px 20px;
      margin-right: 20px;
      border: none;
    }
  }

  .tab_flex {

    form {

      margin-bottom: 30px;
    }
  }
}


.guest_select_div {
  z-index: 9999;
  position: absolute;
  right: 0;
  background: #fff;
  top: 0;
  height: 100%;
   padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  max-width: 550px;
  width: 100%;
  overflow: hidden;
  .header-cross{
    height: 35px;
    min-height: 35px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    .with-icon{
      display: flex;
    align-items: center;
    padding: 0 !important;
    width: 24px !important;
    height: 24px !important;
    justify-content: center;
    }
  }

  .guest_selection_row {
    overflow: auto !important;
    padding: 20px 0;
    height: unset !important;
    flex: 1;
    &::before {
      display: none !important;
    }

    input[type='file'] {
      display: none;
    }

    .upload-guest {
      border: 1px solid #bc8129;
      border-radius: 3px;
      background-color: #fffcf7;
      align-items: center;
      justify-content: center;
      padding-right: 10px;

      a {
        cursor: pointer !important;
        color: #000 !important;
        text-decoration: none;
      }
    }

    .custom-file-upload {
      cursor: pointer !important;

      a {
        cursor: pointer !important;

      }

      .upload_field {
        padding: 10px;
      }
    }

    &::before {

      content: "";
      display: block;
      padding-top: 15px;
      border-top: 1px solid #eee;

    }
  }
  .bottom_save_button{
    padding-left: 12px !important;
    min-height: 56px;
  }

}

.guest_selection_row {
  input[type='file'] {
    display: none;
  }

  .upload-guest {
    border: 1px solid #bc8129;
    border-radius: 3px;
    background-color: #fffcf7;
    align-items: center;
    justify-content: center;
    padding-right: 10px;

    a {
      cursor: pointer !important;
      color: #000 !important;
      text-decoration: none;
    }

    @media (max-width:575px) {

      width: 100%;
      justify-content: space-between !important;

    }
  }

  .custom-file-upload {
    cursor: pointer !important;

    a {
      cursor: pointer !important;

    }

    .upload_field {
      padding: 10px;
    }
  }

  &::before {

    content: "";
    display: block;
    padding-top: 15px;
    border-top: 1px solid #eee;

  }
}


.new_email_container {
  display: flex;
  height: 100%;

  .left_email {
    width: 100%;
    height: 100%;

    .header_left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 50px;
      width: 100%;
      min-height: var(--header-height);
      border-bottom: var(--border);
      box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
      border-bottom: var(--border);

      span {
        cursor: pointer;
        display: flex;
        align-items: center;

        i {
          margin-right: 10px;
        }
      }
    }

    .compose_message {
      display: flex;
      justify-content: flex-end;
      padding: 20px;

      .dropdown-menu {

        li {

          a {

            padding: 8px 20px !important;

            span {
              margin-right: 10px;

              i {

                color: #bc8129;
              }
            }
          }
        }

        @media (min-width:992px) {

          transform: translate(0px, 40px) !important;

        }
      }
    }

    .email_container {
      display: flex;
      justify-content: center;
      padding: 20px;
      flex-direction: column;
      align-items: center;

      .email_sender {
        max-width: 500px;
        border: 1px solid #bc8129;
        background: #fffcf7;
        width: 100%;
        position: relative;
        margin-top: 20px;

        .emailer_template_main {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 20px;

          .emailer_template_wrapper {
            background: #fff;
            width: 100%;
            max-width: 500px;

            .email-header {
              background: #e45b2b;
              color: #fff;
              padding-bottom: 10px;
              margin-bottom: 20px;
              display: flex;
              flex-direction: column;

              h3 {
                color: #fff;
                text-align: center;
              }

              img {
                width: fit-content;
                margin: auto;
              }
            }

            .email-content {
              text-align: center;
              margin: 30px 0 0 0;
              padding: 10px;
            }
          }


          .email_title {
            padding: 5px;
            text-align: center;
            border-bottom: 1px solid #cacaca;
          }

          .from_send {
            padding: 5px 15px;
            text-align: left;
            border-bottom: 1px solid #cacaca;

            div {
              font-size: 15px;
            }

            span {
              color: #979797;
            }
          }


          .email_row {
            display: flex;
            justify-content: center;
          }

          .email_content {
            padding: 30px 50px;
            background: #fff;
            max-width: 460px;
            border-radius: 20px;
            margin: 30px;
            border: 1px solid #a09f9f;

            h3 {
              margin-bottom: 15px;
            }
          }

          p {
            margin-bottom: 15px;
          }
        }

        .email_title {
          padding: 10px 0;
          border-bottom: 1px solid #cacaca;
          text-align: center;
        }

        .from_send {
          padding: 5px 15px;
          text-align: left;
          border-bottom: 1px solid #cacaca;

          div {
            font-size: 15px;
            padding: 3px 0;
          }

          span {
            color: #979797;
          }
        }
      }

      .email_row {
        display: flex;
        justify-content: center;
      }

      .email_content {
        padding: 30px 50px;
        background: #fff;
        max-width: 460px;
        border-radius: 20px;
        margin: 50px;
        border: 1px solid #a09f9f;

        h3 {
          margin-bottom: 15px;
        }
      }

      p {
        margin-bottom: 15px;
        white-space: pre-line;
      }

      .sms_sender {

        max-width: 320px;
        border: 10px solid #e7e7e7;
        background: #ffffff;
        width: 100%;
        position: relative;
        margin-top: 20px;
        border-radius: 30px;
        overflow: hidden;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .header_mobile {
          background: #fffcf7;
          height: 80px;
          margin: 0px;
          border-bottom: 1px solid #bc8129;
          display: flex;
          justify-content: flex-start;
          padding: 10px;
          gap: 5px;
          flex-direction: column;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            background-color: #737373;
            width: 110px;
            height: 17px;
            left: calc(50% - 55px);
            border-radius: 0 0 20px 20px;
            z-index: 2;
          }

          .top_header-icon {

            display: flex;
            justify-content: space-between;



            .header-icons {
              display: flex;
              gap: 4px;

              i {

                font-size: 12px;
              }
            }
          }

          .bottom_header-icon {
            display: flex;
            gap: 6px;
            align-items: center;


          }

          .logo_icon_website {

            width: 35px;
            height: 35px;
            padding: 0px;
            background: #fff;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #eee;

            img {
              width: 22px;
            }
          }

          h4 {

            font-size: 15px;
          }
        }

        .mobile_message {
          padding: 10px;
          background: #fff;
          height: 400px;
          overflow-y: scroll;
          font-size: 13px;
          font-weight: 600;

          .mobile_message_box {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 14px;

            span.messages_bubbles {
              padding: 10px;
              background-color: #bc8129;
              border-radius: 10px 10px 0px 10px;
              color: #fff;
              font-size: 15px;
            }
          }
        }

        .footer_mobile {
          background-color: #fff;
          border-top: 1px solid #c1c1c1;
          height: 60px;
          margin: 0px;
          align-items: center;
          border-radius: 0px;
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          gap: 10px;

          span {
            background-color: #f0f0f0;
            width: 100%;
            border-radius: 50px;
            padding: 10px;
            display: flex;
            justify-content: flex-end;

            i {
              font-size: 18px;
            }
          }

          .camera_icon {


            background: #eee;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
          }
        }
      }

      .whatsapp_sender {

        max-width: 320px;
        border: 10px solid #e7e7e7;
        background: #ffffff;
        width: 100%;
        position: relative;
        margin-top: 20px;
        border-radius: 30px;
        overflow: hidden;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .header_mobile {
          background: #fffcf7;
          margin: 0px;
          border-bottom: 1px solid #bc8129;
          display: flex;
          justify-content: flex-start;
          color: #fff;
          gap: 5px;
          flex-direction: column;
          background-color: #005e54;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            background-color: #737373;
            width: 110px;
            height: 17px;
            left: calc(50% - 55px);
            border-radius: 0 0 20px 20px;
            z-index: 2;
          }

          .top_header-icon {
            background-color: #004e45;
            display: flex;
            justify-content: space-between;
            padding: 10px;

            .header-icons {
              display: flex;
              gap: 4px;

              i {
                color: #fff;
                font-size: 12px;
              }
            }
          }

          .bottom_header-icon {
            display: flex;
            gap: 6px;
            align-items: center;
            background-color: #005e54;
            padding: 10px;
            justify-content: space-between;

            .whatsapp_left {
              display: flex;
              gap: 6px;
              align-items: center;

              h4 {

                span.whatsapp_online {

                  font-size: 12px;
                }
              }
            }

          }

          .logo_icon_website {

            width: 35px;
            height: 35px;
            padding: 0px;
            background: #fff;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #eee;

            img {
              width: 22px;
            }
          }

          h4 {

            font-size: 15px;
          }
        }

        .mobile_message {
          padding: 10px;
          background: #fff;
          height: 400px;
          overflow-y: scroll;
          font-size: 13px;
          font-weight: 600;
          background-image: url(../assets/img/whatsapp_bg.jpg);
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;

          .mobile_message_box {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 14px;

            span.messages_bubbles {
              padding: 10px;
              background-color: #e1ffc7;
              border-radius: 10px 10px 0px 10px;
              color: #000;
              font-size: 15px;
            }
          }
        }

        .footer_mobile {
          background-image: url(../assets/img/whatsapp_bg.jpg);
          background-repeat: no-repeat;
          background-position: top;
          background-size: cover;
          height: 60px;
          margin: 0px;
          align-items: center;
          border-radius: 0px;
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          gap: 10px;

          .whatsapp_footer {
            background: #fff;
            width: 100%;
            border-radius: 50px;
            padding: 10px;
            display: flex;
            justify-content: space-between;

            span {

              display: flex;
              gap: 10px;
            }

            i {
              font-size: 18px;

            }
          }

          .camera_icon {

            background: #005e54;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            i {

              color: #fff;
            }
          }
        }
      }
    }
  }


  @media (max-width:520px) {

    flex-direction: column;

  }
}

.right_email {

  flex-direction: column;
  display: flex;
  max-width: 400px;
  position: relative;
  border-left: 1px solid #eee;
  padding: 0;

  .nw_email {
    width: 100%;
    flex-direction: column;
    display: flex;
    max-width: 400px;
    height: 100%;
    background: #fff;

    .header_right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      width: 100%;
      min-height: var(--header-height);
      border-bottom: 1px solid #eee;

      h1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        span {
          width: 24px;
          height: 24px;
          display: none;
          align-items: center;
          justify-content: center;

          i {
            font-size: 18px;
          }
          @media (max-width:991px) {
            display: inline-flex;
          }
        }
      }

      span {
        display: flex;
        gap: 6px;
        align-items: center;
        cursor: pointer;

        i {
          font-size: 28px;
        }
      }
    }

    .form_input {
      flex: 1;
      overflow: auto;
      padding: 16px;

      .select {
        position: relative;
        margin-bottom: 15px;
        border: var(--border);
        box-shadow: none;
        width: 100%;
      }


    }

    .group_field_container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 600px;

      .form_group_title {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: var(--border);
        margin: 16px 0;
      }

      input,
      textarea {
        background-color: #fff;
      }

      .line_contact {
        display: flex;
        justify-content: center;
        background: #eee;
        padding: 10px;
        border-radius: 20px;
        margin: 10px 0;
      }

      .form-check {
        margin: 5px 0;

        label {
          &::before {
            content: none;
          }
        }
      }
    }

    .field_container {
      &.title_action_panel {
        display: flex;
        align-items: center;
        gap: 16px;

        .field_title {
          margin-bottom: 0;
        }
      }

      .field_title {
        font-size: 14px;
        margin-bottom: 0;
      }

      .element_repeat {
        display: flex;
        align-items: center;
        gap: 16px;

        .fa-trash {
          color: #ccc;
        }
      }
    }

    .multi_field_container {
      display: flex;
      gap: 16px;
      align-items: flex-end;
    }
  }

  .new-email {
    z-index: 999;

    footer {

      padding-top: 30px;

      .compose_close_action {

        display: flex;
        gap: 10px
      }
    }
  }

  .add-guest {
    z-index: 99;
  }

  .add-recipient {
    z-index: 9;
  }

  &.recipient {
    .add-guest {
      z-index: 9999;
    }
  }

  &.add-new-guest {
    .add-recipient {
      z-index: 99999;
    }
  }

  &.save-btn {
    .add-guest {
      z-index: 999999;
    }
  }
}

.communication_loader {
  .img_loader {

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 99;

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9;
      width: 100vw;
      height: 100vh;
      background-color: #000;
      opacity: 0.4;
      content: "";
    }

    svg {
      z-index: 99;
    }
  }
}
