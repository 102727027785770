.business_vendor {
  background-color: #f9f5f0;
  padding: 60px 0;

  .hero-slider-panel {
    flex-basis: 640px;
  }

  ul {
    li {
      padding: 4px 0;
      font-size: 18px;
    }
  }
}

.grow-vendor {
  background-color: #efe1d4;
  text-align: center;
  padding: 50px 0;

  h2 {
    line-height: 40px !important;
    color: #000 !important;
  }

  @media (max-width: 767px) {
    padding: 20px 0;

    .input_dropdown_category {
      max-height: 300px !important;
    }
  }
}

.vendor_filter_slider_container {
  background-image: url('../assets/img/form-bkg.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  background-color: #fffaf6;
}

.vendor_list_all_category {
  // display: flex;
  // width: 100%;
  // flex-direction: column;
  // gap: 32px;
  .vendor_list_by_category {
    padding: 32px 0;
    .title_medium {
      font-size: 24px;
      line-height: unset;
      color: #000;
      @media (max-width: 700px) {
        font-size: 20px !important;
      }
    }

    &:nth-child(even) {
      border-top: 1px dashed #ccc;
    }
    @media (max-width: 700px) {
      padding-top: 16px;
    }
  }
}



.vendor-categories {
  padding: 50px 0;
  background-color: #fff;

  

  .vendor-wrap {
    // display: flex;
    // gap: 20px;
    // margin-top: 20px;
    // flex-wrap: wrap;
    // justify-content: center;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 20px;

    .vendor-list {
      width: 100%;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 12px;
      justify-content: space-between;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px;

      .middle-cont {
        width: 100%;
        justify-content: space-between;

        span {
          font-family: 'Arima', system-ui;
          width: 160px;
          line-height: 20px;
          font-weight: 600;
        }

        i {
          font-size: 22px;
        }
      }

      .explore-btn {
        border: 1px solid #555;
        background: transparent;
        padding: 0 16px;
        font-family: 'Arima', system-ui;
        cursor: pointer !important;
        color: #000;
        text-decoration: none;
        height: 36px;
        border-radius: 3px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: var(--orange);
          color: #fff;
          border: 1px solid var(--orange);
        }
      }

      @media (max-width: 567px) {
        max-width: fit-content;
      }
    }
  }

  
  .filter_listing_container {
    display: flex;
    grid-auto-flow: column;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-bottom: 24px;
    position: sticky;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px;
    top: 55px;
    height: auto;
    z-index: 99;
    @media (max-width: 767px) {
      grid-auto-flow: row;
    }

    @media (max-width: 1000px) {
      .vendor_filter_panel {
        width: 100%;
        max-width: 320px;

        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 99;
        height: 100%;
        display: none;
        &.active {
          display: inline-flex;
          z-index: 999;
          position: fixed;
          flex-direction: column;
          height: 100vh;
          overflow: auto;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          .title_small {
            height: 60px;
            padding: 12px;
            box-sizing: border-box;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: 7px;
            justify-content: flex-end;
            // .filter_btn_action{
            //   position: absolute;
            //   bottom: 0;
            //   height: 56px;
            // }
          }
        }
      }
    }
    &.search_location_filter {
      margin-bottom: 0;
      padding: 16px;
      margin-bottom: 0;
      border-top: 1px solid rgb(226, 224, 224);
      border-bottom: 1px solid rgb(226, 224, 224);
      .container {
        display: flex;
        align-items: center;
        .more-filter_icon {
          height: 100%;
          background-color: #fff;
          cursor: pointer;
          border-radius: 4px;
          margin-left: 10px;
          border: 1px solid #d9d9d9;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 19px;
          gap: 8px;
          padding: 0 8px;
          .text {
            font-size: 16px;
          }
          @media (max-width: 1000px) {
            display: none;
          }
        }
      }
      @media (max-width: 700px) {
        padding: 8px;
        .container {
          padding: 0;
        }
      }
    }
  }

  .vendor_filter_panel {
    // width: 320px;

    // position: -webkit-sticky;
    // position: sticky;
    // top: 5rem;
    // right: 0;
    // z-index: 2;
    // height: calc(100vh - 7rem);

    width: 100%;

    .title_small {
      color: #000;
      font-size: 20px;
      height: 38px;
      margin-bottom: 20px;
      .name {
        display: inline-flex;
        flex: 1;
        align-items: center;
        gap: 5px;
      }
      .close_icon {
        display: none;
        width: 24px;
        height: 24px;
        @media (max-width: 1000px) {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .filter_area_container {
      // box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px;
      // background-color: #fff9f4;
      height: 100%;
      // overflow: auto;
      border-radius: 5px;

      .multi_field_container {
        width: 100%;
        display: flex;
        .field_panel {
          flex: 1;
          display: flex;
          gap: 8px;
        }

        .field_container {
          // border-bottom: 1px solid #e7e7e7;
          padding: 0px;
          position: relative;
          flex: 1;
        }

        .field_title {
          font-weight: 600;
          width: 100%;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 5px;
          font-size: 15px;
        }
      }
      .price_panel {
        position: absolute;
        background-color: #fff;
        border-radius: 4px;
        padding: 8px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px;
        width: 300px;
        top: 40px;

        &:has(.single_checkbox) {
          max-height: 300px;
          overflow: auto;
        }
      }
      .single_checkbox {
        display: block;
        margin-bottom: 15px;
        input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer;
          &:checked {
            & + label {
              &:after {
                content: '';
                display: block;
                position: absolute;
                top: 5px;
                left: 6px;
                width: 6px;
                height: 11px;
                border: solid #000;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }
          }
        }
        label {
          position: relative;
          cursor: pointer;
          font-size: 14px;
          color: #000;
          font-weight: 600;
          &:before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 1px solid #444;
            border-radius: 2px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
              inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            width: 18px;
            height: 18px;
            padding: 0;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .all_vendor_listing {
    flex: 1;
    padding-top: 32px;
    .search_filter_listing {
      display: flex;
      justify-content: space-between;
      
    }

    .tille_on_mobile {
      display: none;
    }
    .toggle_filter_mobile {
      display: none !important;
      width: auto;
      display: inline-flex;
      gap: 8px;
      height: 32px;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      min-height: 36px;
      padding: 0 8px;
      @media (max-width: 1000px) {
        display: inline-flex !important;
        background-color: #bc8129;
        color: #fff;
        border-radius: 3px;

        + span {
          display: none;
        }
      }
    }
    @media (max-width: 1000px) {
      .tille_on_mobile {
        display: inline-flex;
        color: #000;
        font-size: 20px;
      }
    }

    .search_tag_container {
      .clear_filter {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
      }
    }

    .img_loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 686px);
    }
  }
}

.top-vendors {
  padding: 50px 0;
  background-color: #efe1d4;

  .event_slogan {
    h1 {
      text-align: left;
    }
  }

  @media (max-width: 567px) {
    h1 {
      text-align: center !important;
    }
  }
}

.vendor_reach {
  padding: 50px 0;

  .vendor_access {
    display: flex;
    text-align: center;
    gap: 75px;

    .block_vendor {
      i {
        font-size: 40px;
        color: var(--orange);
        padding-bottom: 20px;
      }

      h3 {
        font-size: 20px;
        color: var(--textGlobalColor);
        padding-bottom: 10px;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 767px) {
    .vendor_access {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.vendor_wrapper {
  background: #f9f5f0;

  .vendor_showcase {
    padding: 50px 0 0;

    h2 {
      text-align: center;
    }

    .list_points {
      display: flex;
      gap: 21px;
      align-items: flex-start;
      font-size: 18px;
      line-height: 26px;
      font-weight: 300;
      padding-bottom: 20px;

      i {
        font-size: 40px;
        font-weight: 300;
      }
    }
  }
}

.vendor_signup {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../assets/img/business-grow.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  color: #fff;
  background-position: bottom;
  padding: 200px 0;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    padding: 80px 0;
  }
}

.vendor-details-modal {
  .modal-dialog {
    max-width: 750px;

    .modal-content {
      padding: 20px;
      background-color: #fff !important;
      border-radius: 10px;

      .modal-header {
        padding: 0;
        border: none;
      }

      .modal-body {
        font-family: 'Arima', system-ui;

        .modal-title {
          color: var(--orange);
          font-weight: 600;
          font-size: 18px !important;
        }
        p{
          span{
           &.set_line {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
           }
          }
        }
        h3.title_size {
          font-size: 28px;
          color: #4a4a4a;
        }

        padding: 0;

        p.term-use {
          font-weight: 600;
          font-size: 12px;
          color: #000;
        }

        p.heading-content {
          font-size: 15px;
          margin-bottom: 10px;
          font-weight: 600;
          color: #000;
        }

        textarea,
        input {
          height: unset;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 3px;
        }

        .interested-services {
          h3 {
            font-size: 18px;
            margin: 20px 0px 10px;
            font-weight: 600;
            color: #000;

            span {
              color: #9b9b9b;
            }
          }

          .services-tag {
            display: flex;
            gap: 10px;

            .btn-group {
              gap: 10px;

              span {
                background-color: #efe1d4;
                border-radius: 10px;

                &:focus {
                  box-shadow: unset;
                  outline: none;
                }

                .btn-check {
                  &:checked + .btn-outline-primary {
                    color: #fff;
                    background-color: var(--orange);
                    border-color: var(--orange);
                    border-radius: 10px;
                  }
                }
              }

              label.btn-outline-primary {
                border: none !important;
                border-radius: 10px;

                &:hover {
                  border-radius: 10px;
                  background-color: var(--orange);
                }

                &:active {
                  background-color: var(--orange);
                }

                &:focus {
                  box-shadow: unset;
                  outline: none;
                }

                &::before {
                  content: none;
                }

                &::after {
                  content: none;
                }
              }

              @media (max-width: 767px) {
                flex-wrap: wrap;
              }
            }
          }
        }
      }

      .modal-footer {
        border: none;
        padding: 0;

        button.btn {
          padding: 10px 50px;
          // border-radius: 50px;
        }
      }
    }
  }
}

.vendor-search {
  background-color: #fff6ed;
  padding-bottom: 50px;
  font-family: 'Arima', system-ui;

  .popular-search {
    flex: 1;

    h3 {
      font-size: 20px;
      color: #000;
      margin-bottom: 10px;
    }

    .search-list {
      a {
        color: #000;
        cursor: pointer !important;
        display: block;
      }
    }
  }
}

.image-gallery {
  .image-gallery-icon {
    &:hover {
      color: #bc8129;
    }

    svg {
      height: 60px !important;
    }
  }
}

.more_filter_popup {
  .modal-dialog {
    margin-top: 0;
    width: 100%;
    max-width: 1296px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    .modal-content {
      border: 0;
      border-radius: 0;
      width: 100%;

      margin: 0 auto;
      .multi_field_container {
        display: flex;
        width: 100%;
        .field_panel {
          display: flex;
          gap: 12px;
          width: 100%;

          .field_container {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 5px;
            padding: 12px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px;
            .filter_box {
              display: flex;
              flex-direction: column;
              gap: 8px;

              ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 8px;
                max-height: 240px;
                overflow: auto;
                li {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 8px;
                  height: 28px;
                  .Checkbox {
                    width: 18px;
                    height: 18px;
                    .Checkbox-visible {
                      width: 18px;
                      height: 18px;
                    }
                    + span {
                      position: relative;
                      top: 2px;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.show {
    display: flex !important;
    align-items: center;
  }
  @media (max-width: 900px) {
    .modal-dialog {
      max-width: 95%;
      height: 100%;
      margin: 0;
      border-radius: 0;
      .modal-content {
        height: 100%;
        .modal-body {
          overflow: auto;
        }
        .field_panel {
          display: flex;
          flex-wrap: wrap;
          .field_container {
            flex-basis: 420px !important;
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.vendor_gallery_popup{
  .modal-header{
    max-width: 800px;
    padding: 16px 0;
    margin: 0 auto;
    width: 100%;
    @media (max-width: 767px) {
      padding-right: 24px;
    }
  }
}
