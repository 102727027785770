/**
 * Reset Styles
 *
 * This stylesheet resets default browser styling to establish a consistent baseline
 * across different HTML elements. It ensures a uniform starting point for styling
 * and enhances cross-browser compatibility by standardizing behaviors.
 *
 * Reset includes:
 * - Removal of margins, paddings, and borders from all elements.
 * - Setting font size to 100% and inheriting font properties for consistency.
 * - Aligning inline elements vertically to the baseline.
 * - Converting HTML5 semantic elements to block display for older browser support.
 * - Eliminating default list styles, quotes, and collapsing table borders.
 *
 * Author: Ganesh Ughade
 * Version: 1.0
 */

/* Reset for all elements */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%; /* Ensures consistent font size across browsers */
    font: inherit; /* Inherits font settings from parent elements */
    vertical-align: baseline; /* Aligns inline elements vertically */
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block; /* Ensures block-level display for semantic elements */
}

/* Body-specific resets */
body {
    line-height: 1; /* Sets a consistent line height */
}

/* List styles reset */
ol, ul {
    list-style: none; /* Removes default list styles */
}

/* Blockquote and quote resets */
blockquote, q {
    quotes: none; /* Removes default quotation marks */
}

/* Table styles reset */
table {
    border-collapse: collapse; /* Collapses borders for consistent table styling */
    border-spacing: 0; /* Sets spacing between table cells to zero */
}
