.payment_information {
  .container {
    max-width: 1024px;
    padding: 0 72px;
    h2.main-heading {
      padding: 20px 0;
      border-bottom: 1px solid var(--grayLight);
    }
.main-accordation {
 .accordion-button {
	&:not(.collapsed) {
		color: #000;
		background-color: #eee;
	}

  &:focus {
  
    box-shadow: none;
}
}

  

    .payment_column {
      margin:10px;

      .accordion {
        padding-right: 10px;

        .accordion-item {
          border: none;

          .accordion-header {
            .accordion-button {
              padding: 0;
              &::after {
                flex-shrink: 0;
                margin-left: auto;
                content: '';
                background-repeat: no-repeat;
                background-size: 1.25rem;
                display: block;
                transform: rotate(225deg);
                background-image: none;
                width: 8px;
                height: 8px;
                border-top: 1px solid #000;
                border-left: 1px solid #000;
                transition: transform 0.2s ease-in-out;
              }
              &:not(.collapsed) {
                &::after {
                  transform: rotate(45deg);
                }
              }
            }

            input[type='radio'] {
              position: absolute;
              visibility: hidden;
              &:checked {
                ~ {
                  .check {
                    border: 2px solid var(--orange);
                    &::before {
                      background: var(--orange);
                    }
                  }
                  label {
                    color: var(--orange);
                  }
                }
              }
            }
            label {
              display: flex;
              font-weight: 500;
              font-size: 16px;
              z-index: 9;
              cursor: pointer;
              -webkit-transition: all 0.25s linear;
              gap: 10px;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: center;
              padding: 10px;
              width: 100% !important;
            }
            ul {
              li {
                &:hover {
                  label {
                    color: #ffffff;
                  }
                  .check {
                    border: 5px solid #ffffff;
                  }
                }
              }
            }
            .check {
              display: block;

              border: 2px solid #aaaaaa;
              border-radius: 100%;
              height: 20px;
              width: 20px;

              z-index: 5;
              transition: border 0.25s linear;
              -webkit-transition: border 0.25s linear;
              &::before {
                display: block;
                position: relative;
                content: '';
                border-radius: 100%;
                height: 8px;
                width: 8px;
                top: 4px;
                margin: auto;
                transition: background 0.25s linear;
                -webkit-transition: background 0.25s linear;
              }
            }

            .accordion-button {
              &:not(.collapsed) {
                color: #4c4c4c;
                background-color: transparent;
                box-shadow: none;
              }
            }

            .accordion-button {
              &:focus {
                z-index: 3;
                border-color: transparent;
                outline: 0;
                box-shadow: none;
              }
            }
          }

          .accordion-body {
            .credit.box {
              padding: 20px;

              .payment_icon {
                text-align: center;

                margin-bottom: 30px;
              }

              .group_field_container {
                display: flex;
                flex-wrap: wrap;
                gap: 14px;
                flex-direction: column;

                .multi_field_container {
                  display: flex;
                  gap: 16px;
                  align-items: flex-end;

                  label {
                    margin: 10px 0;
                  }

                  input[type='text'] {
                    padding: 20px 8px;
                  }
                }
              }
            }
            .paypal {
              padding: 20px;
              .image-paypal {
                text-align: center;
              }
            }

            .form-sizing {
              display: flex;
              flex-direction: column;

              width: 100%;
              margin: 0 auto;
              padding: 20px 40px;
              border: 1px solid #eee;

              .payment_icon {
                text-align: center;
                margin-bottom: 30px;
            }
            }
          }
        }
      }
    }
  }
    .right_aside {
      display: flex;
      border-left: 1px solid var(--grayLight);
      .right_box {
        margin: 20px 0;
        width: 100%;

        .top_heading {
          display: flex;
          justify-content: space-between;
        }

        .order_box {
          padding: 10px;
          .order_summery {
            .image_sample {
              display: flex;
              flex-wrap: nowrap;
              gap: 20px;
              padding: 15px 0;
              border-bottom: 1px solid #eee;

              .left_image {
                position: relative;

                img {
                  width: 100px;
                }

                .box_name {
                  background: #fdddd5;
                  padding: 20px 27px;
                  border-radius: 3px;
                }
              }
            }

            .right_content {
              &.al-ct {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
              }

              h4 {
                font-size: 18px;
                margin-bottom: 10px;
              }
              .list_detail {
                display: flex;
                gap: 12px;

                .list_order {
                  display: flex;
                  flex-direction: column;
                  font-size: 14px;

                  span {
                    display: block;
                  }
                }
              }
            }
          }

          .promo_code {
            padding: 30px 0;
            border-bottom: 1px solid var(--grayLight);

            form {
              display: flex;
              gap: 10px;
              padding-bottom: 15px;

              input {
                padding: 25px 15px;
              }

              .brd-org {
                border: 1px solid var(--orange) !important;
                color: var(--orange) !important;
              }
            }
          }

          .subtotal_row {
            display: flex;
            flex-direction: column;
            margin-top: -3px;
            padding: 30px 0 0;
            .total_block,
            .discount_block,
            .taxes_block {
              display: flex;
              gap: 40px;
              font-weight: 300;
              justify-content: space-between;
              margin-top: 3px;
            }
          }
          .total_price {
            background: #eee;
            padding: 10px;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
