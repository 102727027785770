.black.thick.frame {
  width: 100%;
  max-width: 320px;
}

.background-texture {
  .invitation_editor_main {
    position: relative;

    .black.thick.frame {
      width: 100%;
      max-width: 320px;
    }

    .editable_element {
      border: 2px dotted #000 !important;

      &[contenteditable='false'] {
        &:empty {
          &::before {
            content: attr(placeholder);
          }
        }
      }

      &[contenteditable='true'] {
        &:empty {
          &:before {
            content: attr(placeholder);

            color: darkgray;
          }
        }
      }

      .editable_container {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        top: -17px;
        left: 0;
        gap: 12px;
      }

      .option-button {
        left: 0 !important;
        position: relative !important;
        transform: t0 !important;
        top: 0 !important;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        transform: unset !important;

        i {
          font-size: 15px;
          color: #000;
        }
      }
    }

    .editor_parent_container {
      display: flex;
      gap: 48px;
      margin: 0 auto;
      padding: 24px;
      justify-content: center;
      background-image: url(../assets/img/invitation-editor-bkg.jpg);
      background-position: right bottom;
      background-size: cover;
      flex: 1;

      .edit_template_container {
        overflow: auto;
        .save_the_date_container {
          div:first-child {
            padding: 0 !important;
          }
        }

        table {
          width: 100% !important;
          td {
            height: auto !important;
            background-color: transparent !important;
          }

          #mycanvas {
            background-color: transparent !important;

            // height: auto !important;
            transform: scale(0.9);
            p {
              width: 100%;
              padding: 10px;
            }
            // @media (max-width: 900px) {
            //   display:  block !important;
            //   background-size: cover !important;
            //   transform: scale(0.8);
            // }
          }
        }

        body {
          overflow: hidden !important;
        }

        @media (max-width: 1100px) {
          transform: scale(0.7);
          margin-top: -100px;
        }
      }

      @media (max-width: 768px) {
        padding: 0 !important;
        width: 100%;

        .edit_template_container {
          // .invitation_wraaper_box {
          //   width: 100% !important;
          // }
        }
      }
    }
  }

  .right_editable_box {
    border: 1px solid #ccc;
    padding: 30px;
    border-radius: 10px;

    background-color: #fff;
    align-self: stretch;
    overflow: auto;
    min-width: 300px;
    max-width: 300px;

    .editor_container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .text-label {
      display: flex;
      justify-content: space-between;
    }

    label {
      display: block;
      color: #5c5c5c;
      font-size: 14px;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .clear-btn {
      display: block;
      color: #5352eb;
      font-size: 15px;
      margin-bottom: 10px;
      background: none;
      cursor: pointer;
      border: none;
    }

    .nav {
      border-bottom: 1px solid #b9b9b9;
      margin-bottom: 20px;

      .nav-item {
        .nav-link {
          color: #6e6a93 !important;
          list-style: none;
          display: flex;
          align-items: center;
          border-bottom: 5px solid transparent !important;
          border-bottom-color: transparent;
          height: 100% !important;
          border: none;
          padding: 5px 0px;
          margin-right: 30px;
        }

        .nav-link.active {
          background-color: transparent;
          border-radius: 0;
          border-color: var(--orange) !important;
        }
      }
    }

    .editor_format {
      .text-editor {
        textarea {
          resize: none;
          padding: 20px;
          background-color: rgb(255, 255, 255);
          border: 1px solid #dedede;
          width: 100%;
          height: 100%;
          margin-top: 0;
        }
      }

      .text-style {
        .dropdown {
          border: 1px solid #dedede;

          a {
            display: block;
            padding: 0.5em;
            color: #5c5c5c;
            text-decoration: none;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            font-size: 15px;
          }

          ul {
            display: none;
            border-top: 0.1em solid #c0cdd1;
            list-style: none;
          }

          li {
            padding: 0.5em;
            cursor: pointer;
            color: #5c5c5c;
            font-size: 15px;

            &:hover {
              background: #eee;
            }
          }

          .triangle-down {
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #555;
          }

          ul.js-dropdown-list {
            padding: 0;
            margin: 0;
          }
        }
      }

      .textsize {
        .box-size {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #eee;
          padding: 20px;
          gap: 10px;

          input[type='range'] {
            height: 1px;
            background-color: #e1e3e6;
            display: block;
            cursor: w-resize;
            background-size: 0% 100%;
            background-repeat: no-repeat;
            border-radius: 4px;
            flex: 1;
          }

          .range-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .range-value {
            border: 1px solid #eee;
            padding: 5px;
            width: 70px;
            text-align: center;

            span {
              font-size: 14px;
            }
          }

          .select-dropdown-container {
            width: 100%;
          }
        }
      }

      .textcolor {
        border: 1px solid #eee;
        padding: 20px;

        .color_selector {
          .fav-color {
            padding: 10px;
            border: 1px solid #eee;
          }

          ul {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(4, 1fr);
            justify-items: center;
            padding: 10px 0 0 0;
            margin-bottom: 0;
            row-gap: 0;
          }

          li {
            list-style: none;
            padding: 5px;

            .color-box {
              width: 24px;
              height: 24px;
              display: inline-flex;
              border-radius: 50%;
              cursor: pointer;
            }
          }
        }
      }

      .text-format {
        .text-column {
          padding: 20px;
          border: 1px solid #eee;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          gap: 20px;

          .text-transform {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;

            button.btn-white {
              background: #fff;
              border: 1px solid #333;
              padding: 15px;
              cursor: pointer;
            }

            button.btn-white.active {
              background: #fff;
              border: 2px solid #f05537;
              color: #f05537;
            }
          }

          .text-align {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;

            .align-item.active {
              background: #fff;
              border: 2px solid #f05537;
              color: #f05537;
            }

            .align-item {
              cursor: pointer;
              border: 2px solid #000;
              border-radius: 50%;
              padding: 5px 10px;
            }
          }
        }
      }

      .textsize {
        margin-bottom: 0;

        .box-formating {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .box {
            padding: 20px;
            border: 1px solid #eee;
            align-items: baseline;

            input[type='range'] {
              height: 1px;
              background-color: #e1e3e6;
              display: block;
              cursor: w-resize;
              background-size: 0% 100%;
              background-repeat: no-repeat;
              border-radius: 4px;
              flex: 1;
            }

            .range-row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
            }

            .range-value {
              border: 1px solid #eee;
              padding: 5px;
              width: 70px;
              text-align: center;

              span {
                font-size: 14px;
              }
            }
          }
        }
      }

      .image_selector {
        border: 1px solid #eee;
        row-gap: 15px;
        padding: 10px;
        column-gap: 15px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-items: center;

        img {
          width: 32px;
          border-radius: 5px;
        }
      }

      .text-style {
        margin: 30px 0;
      }

      .textsize {
        margin-bottom: 0;
      }
    }
  }

  .layout_column {
    width: 300px;
    text-align: center;
    background: #f5f4f4;
    padding: 20px;
    height: 100%;

    .invitation_card {
      padding: 10px;
      flex: 47%;
      background: #ffffff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      cursor: pointer;

      img {
        width: 175px;
        max-height: 250px;
      }
    }

    &.editor_template_listing {
      padding: 0;
      width: 300px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .layout_format {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow: auto;
        flex: 1;
      }

      .title_large {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        color: #fff;
        background-color: #21253e;
        padding: 0 12px;
        font-size: 18px;
        border-bottom: 1px solid #ccc;
        box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);

        i {
          font-size: 24px;
          opacity: 0;
        }
      }
    }

    @media (max-width: 1200px) {
      position: absolute;
      z-index: 99;
      width: 100% !important;
      max-width: 250px !important;
      .title_large {
        font-size: 18px !important;

        i {
          opacity: 1 !important;
        }
      }

      &.hide_panel {
        width: 50px !important;
        background: transparent;

        .layout_format {
          display: none;
        }

        .title_large {
          font-size: 0 !important;
          justify-content: center;
          font-size: 0 !important;
          justify-content: center;
          border-radius: 5px;
          margin: 5px;
          width: 40px !important;
          overflow: hidden;
          height: 40px;
        }
      }
    }
  }

  .editor_parent_container {
    @media (max-width: 1300px) {
      flex-direction: row;
      // align-items: center;
      gap: 24px !important;
      padding: 16px;
      margin: 0 !important;
      position: relative;

      .right_editable_box {
        max-width: 320px;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      gap: 24px !important;
      padding: 16px;
      margin: 0 !important;

      .right_editable_box {
        max-width: 320px;
        display: none;
      }

      .edit_template_container {
        // width: 100%;

        #mycanvas {
          // width: 100% !important;
          // max-width: 420px !important;
          margin: 0 auto !important;
          border-radius: 10px;
          // overflow: hidden;
          // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }
    }
  }

  #mycanvas {
    z-index: 101 !important;
  }

  .footer-column {
    background: #fff;
    padding: 0px;
    padding-right: 12px;
    display: flex;
    // position: fixed;
    justify-content: flex-end;
    align-items: center;
    bottom: 0;
    gap: 8px;
    width: 100%;
    height: 56px;
    min-height: 56px;
    border-top: var(--border);

    @media (max-width: 1100px) {
      position: fixed;
      z-index: 9;
    }
  }
}

.editor_mobile_strip {
  display: none;
}

.editor_mobile_strip {
  @media (max-width: 900px) {
    bottom: 0px;
    background-color: #fff;
    position: fixed;
    z-index: 1040;
    width: 100%;
    min-height: 56px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    left: 0;

    input[type='range'] {
      -webkit-appearance: none;
      margin: 10px 0;
      width: 100%;
    }

    input[type='range']:focus {
      outline: none;
    }

    input[type='range']::-webkit-slider-runnable-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      background: #f05537;
      border-radius: 25px;
      border: 0px solid #000101;
    }

    input[type='range']::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      border: 0px solid #000000;
      height: 12px;
      width: 12px;
      border-radius: 50px;
      background: #65001c;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -5px;
    }

    input[type='range']:focus::-webkit-slider-runnable-track {
      background: #ac51b5;
    }

    input[type='range']::-moz-range-track {
      width: 100%;
      height: 12.8px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      background: #ac51b5;
      border-radius: 25px;
      border: 0px solid #000101;
    }

    input[type='range']::-moz-range-thumb {
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      border: 0px solid #000000;
      height: 20px;
      width: 39px;
      border-radius: 7px;
      background: #65001c;
      cursor: pointer;
    }

    input[type='range']::-ms-track {
      width: 100%;
      height: 12.8px;
      cursor: pointer;
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      border-width: 39px 0;
      color: transparent;
    }

    input[type='range']::-ms-fill-lower {
      background: #ac51b5;
      border: 0px solid #000101;
      border-radius: 50px;
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }

    input[type='range']::-ms-fill-upper {
      background: #ac51b5;
      border: 0px solid #000101;
      border-radius: 50px;
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    }

    input[type='range']::-ms-thumb {
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      border: 0px solid #000000;
      height: 20px;
      width: 39px;
      border-radius: 7px;
      background: #65001c;
      cursor: pointer;
    }

    input[type='range']:focus::-ms-fill-lower {
      background: #ac51b5;
    }

    input[type='range']:focus::-ms-fill-upper {
      background: #ac51b5;
    }

    .icon_parent {
      display: inline-flex;
      width: 52px;
      height: 36px;
      border-radius: 5px;
      // border: 1px solid #000;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 24px;

      input {
        border: 0;
        width: 24px;
        height: 24px;
        outline: none;
      }
    }

    textarea {
      display: none;
    }

    .textedit {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        textarea {
          display: block;
          position: absolute;
          width: 100%;
          min-height: 56px;
          height: 150px;
          overflow: auto;
          bottom: 75px;
          left: 0;
        }
      }
    }
  }
}

.editor-background-texture {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 56px);

  .invitation_editor_main {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
}

@media (max-width: 900px) {
  .primary-setup {
    &:has(.editor_mobile_strip) {
      .footer-column {
        bottom: 77px;
      }
    }
  }
}
