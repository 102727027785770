@use '../themes/default/variables' as *; // Import default theme variables
@use '../themes/default/colors' as *;
@use '../abstracts/mixins' as *;

.user_dropdown{
    .dropdown-toggle{
      @include flex-direction(flex, flex-start, center, row);
  
      gap: var(--s-8);
      border: 0 !important;
      
      &:active,&:focus{
        border: 0;
        outline: 0;
        box-shadow: none;
      }
      &::after,&::before {
        display: none;
      }
    }
    .dropdown-menu{
      &.show{
        left: unset !important;
        right: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        top: 46px;
        overflow: hidden;
      }
      li{
        border-bottom: 1px solid #ccc;
        &:last-child {
            border-bottom: 0;
        }
      }
      .dropdown-item{
        @include flex-direction(flex, flex-start, center, row);
  
        height: 2rem;
        padding: 0 var(--s-12);
        gap: var(--s-6);
        font-size: var(--heading-6);
       
        &:hover,&:focus,&:active {
          text-decoration: none;
          background-color: #fbf5eb;
          color: $black;
        }
        &.active{
            background-color: #fbf5eb;
            color: $black;
        }
      }
    }
    .user_icon{
      @include flex-direction(inline-flex, center, center, row);
  
      width: var(--s-36);
      height: var(--s-36);
      border: var(--border);
      border-radius: 50%;
      border: 1px solid #ccc;
    }
  }