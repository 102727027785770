@use '../abstracts/variables' as *;
@use '../themes/default/variables' as *;
@use '../abstracts/mixins' as *;
@use '../themes/default/colors' as *;
@use '../abstracts/breakpoints' as *;
@use '../components/buttons' as *;

/* Footer */
.footer {
    .brd-btm {
        border-bottom: 1px solid $dark-gray;

        @media (max-width: 700px) {
            border-bottom: none;
        }
    }

    p {
        color: $white;
        padding-top: var(--s-12);
        font-weight: 300;
    }

    .link_panel {
        display: inline-flex;
        gap: var(--s-16);

        a {
            color: $white;
        }
    }

    &.footer-section {
        background-color: $darker-gray;
        position: relative;
        overflow: hidden;
        margin-bottom: var(--s-72);

        @include respond-to('laptop') {
            margin-bottom: 0;
        }
    }

    .footer-wrap {
        @include respond-to('laptop') {
            .mobile_hide {
                display: flex;
            }
        }

        .left-row {
            border-right: 1px solid $dark-gray;

            .footer-about {
                display: flex;
                gap: 50px;
                padding: 50px 0;
                border-bottom: 1px solid $dark-gray;
            }

            .quick_links {
                display: flex;
                color: $white;
                gap: 90px;
                padding: var(--s-40) 0;

                .footer_links {
                    span {
                        font-size: var(--s-24);
                        margin-bottom: var(--s-16);
                        display: block;
                    }

                    ul {
                        list-style: none;
                        padding-left: 0;

                        li {
                            a {
                                font-size: var(--s-14);
                                color: $white;
                                padding: 6px 0;
                                font-weight: 100;

                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }

        .right-row {
            @include flex-direction(flex, center, center, row);

            .footer-widget {
                .footer-widget-heading {
                    margin-bottom: 30px;

                    h3 {
                        color: $white;
                        font-size: var(--s-32);
                        font-weight: 100;
                        margin-bottom: var(--s-14);
                        position: relative;
                    }
                }
                ul {
                    li {
                        display: inline-block;
                        width: 50%;
                        margin-bottom: var(--s-12);

                        a {
                            color: $white;
                            text-transform: capitalize;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }

            .subscribe-form {
                position: relative;
                overflow: hidden;
                margin-top: var(--s-20);

                input {
                    width: 100%;
                    height: 50px;
                    background: $white;
                    border: 1px solid #ccc;
                    color: $black;
                }

                button {
                    position: absolute;
                    right: 0;
                    background: $primary-color;
                    padding: 13px var(--s-20);
                    border: 1px solid $primary-color;
                    top: 0;
                    height: 50px;
                    display: inline-flex;
                    align-items: center;

                    i {
                        color: $white;
                        font-size: var(--s-20);
                        transform: rotate(-6deg);
                    }
                }
            }
        }

        .copyright-text {
            p {
                margin: 0;
                font-size: var(--s-14);
                color: $white;
                margin-bottom: 0;

                a {
                    color: $primary-color;
                }
            }
        }

        .footer-menu {
            ul {
                padding-left: 0;

                li {
                    display: inline-block;
                    margin-left: var(--s-20);

                    &:hover {
                        a {
                            color: $primary-color;
                        }
                    }

                    a {
                        font-size: var(--s-14);
                        color: $white;
                        font-weight: 100;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        .footer-wrap {
            .left-row {
                padding-right: var(--s-10);

                .quick_links {
                    gap: var(--s-12) !important;
                    padding: var(--s-20) 0 !important;
                }
            }

            .right-row {
                .footer-widget {
                    padding: var(--s-10);
                }
            }
        }
    }

    @media (max-width: 700px) {
        .footer-wrap {
            .left-row {
                border-right: none;

                .footer-about {
                    gap: var(--s-20);
                    padding: var(--s-20) 0;
                    flex-direction: column;
                }

                .quick_links {
                    gap: var(--s-40);
                    padding: var(--s-20) 0;

                    .footer_links {
                        span {
                            font-size: var(--s-16);
                        }
                    }
                }
            }

            .right-row {
                border-bottom: 1px solid $dark-gray;
                border-top: 1px solid $dark-gray;
                padding-top: var(--s-24);
                padding-bottom: var(--s-24);
                justify-content: flex-start;

                .footer-widget {
                    padding-top: 0px;
                }
            }

            .copyright-area {
                flex-direction: column-reverse;
                gap: var(--s-16);

                .copyright-text {
                    p {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                }

                .footer-menu {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    ul {
                        text-align: center !important;

                        li {
                            margin-left: 0px;

                            a {
                                display: inline;
                                padding: var(--s-10);
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        .footer-wrap {
            .left-row {
                .footer-about {
                    flex-direction: column;
                    gap: 0;
                    padding: var(--s-20);
                }

                .quick_links {
                    gap: var(--s-24);

                    .footer_links {
                        a {
                            font-size: var(--s-12) !important;
                        }

                        span {
                            font-size: var(--s-16);
                        }
                    }
                }
            }

            .right-row {
                padding: var(--s-40);
            }

            .copyright-area {
                flex-direction: column-reverse;
                gap: var(--s-16);
                align-items: center;

                .copyright-text {
                    p {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                    }
                }

                .footer-menu {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    ul {
                        text-align: center !important;

                        li {
                            margin-left: 0px;

                            a {
                                display: inline;
                                padding: var(--s-10);
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .footer-wrap {
            .left-row {
                .footer-about {
                    gap: 0;
                    padding: var(--s-20);
                }

                .quick_links {
                    gap: 50px;
                    padding: var(--s-20);

                    .footer_links {
                        span {
                            font-size: var(--s-16);
                        }
                    }
                }
            }

            .right-row {
                padding: var(--s-40);

                h3 {
                    font-size: var(--s-32) !important;
                }
            }

            .copyright-area {
                .footer-menu {
                    ul {
                        li {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        .footer-wrap {
            .left-row {
                .footer-about {
                    gap: 0;
                    padding: var(--s-20);
                }

                .quick_links {
                    gap: var(--s-56);
                    padding: var(--s-20);

                    .footer_links {
                        span {
                            font-size: var(--s-24);
                        }
                    }
                }
            }

            .right-row {
                padding: var(--s-40);

                h3 {
                    font-size: var(--s-32) !important;
                }
            }
        }
    }
}
