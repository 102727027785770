.wedding_invitaion_card {
  padding-bottom: 32px;

  .btn.big {
    font-size: 16px;
    border-radius: 3px;
    padding: 6px 12px;
  }

  .plan_status {
    border-top: 1px dashed #000;
    border-bottom: 1px dashed #000;
    display: inline-flex;
    align-items: center;
    min-width: 100px;
    min-height: 36px;

    &.premium_user {
      border-color: #9253c9;
    }

    .free {
    }

    .premium {
      display: inline-flex;
      gap: 8px;
      align-items: center;
      color: #9253c9;
    }
  }

  .container {
    max-width: 1024px;
    padding: 0 72px;

    .envelope_box {
      background-color: #f8f8fa;
      display: flex;
      justify-content: center;
      padding: 35px;
      position: relative;

      .envolape_img {
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        right: 0;
        padding-bottom: 98%;
        background-image: url(../assets/img/envolape_img.png);
        transform: rotate(327deg);
        left: -11%;
        top: -39px;
        width: 396px;
      }

      img {
        width: 250px;
        box-shadow: 15px 15px 25px 0 rgba(0, 0, 0, 0.2), 3px 3px 12px 0 rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 10;
        text-align: center;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: 767px) {
          width: 180px;
        }
      }
    }

    .content_area {
      .download_row {
        padding-left: 0;

        li {
          display: inline-block;
          vertical-align: top;
          line-height: 1;
          padding: 5px;
          font-weight: 300;

          i {
            display: inline-block;
          }

          span {
            display: inline-block;
            margin: 0px 10px 5px 5px;
            vertical-align: middle;
            text-align: left;
            border-right: 1px solid #eee !important;
          }
        }
      }
    }

    .details_area {
      padding: 20px 0;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;

      span {
        font-weight: 300;
      }

      .size_dimensions {
        display: flex;
        gap: 120px;
      }
    }

    .response_card {
      padding: 20px 0;

      .card_dimensions {
        display: flex;
        gap: 55px;
      }
    }

    @media (max-width: 767px) {
      padding: 16px;

      .border-start {
        border: 0 !important;
      }

      .row {
        margin: 0 !important;
      }
    }
  }

  .collapse-horizontal {
    font-weight: 300;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin-bottom: 15px;
        display: flex;

        i {
          margin-top: 3px;
          margin-right: 12px;
        }
      }
    }

    button.btn-radius {
      width: 140px;
      background: #fff;
      border-radius: 50px;
      border: 2px solid var(--orange);
      margin-bottom: 20px;
      padding: 6px 16px;
      color: var(--orange);
    }

    &.premium_box {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      padding: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f9f5f0;

      .card-body {
        background-color: transparent;
      }
    }
  }

  .btn-collapse {
    text-decoration: none;
    display: block;
    color: var(--orange);
    background: transparent;
    border: none;
    font-size: 14px;
    padding-left: 28px;

    &::after {
      flex-shrink: 0;
      margin-left: auto;
      content: '';
      background-repeat: no-repeat;
      background-size: 1.25rem;
      position: relative;
      display: inline-block;
      transform: rotate(225deg);
      background-image: none;
      width: 8px;
      height: 8px;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      transition: transform 0.2s ease-in-out;
      top: -1px;
      left: 6px;
    }

    &:not(.collapsed)::after {
      transform: rotate(45deg);
      position: relative;
      top: 3px;
      left: 6px;
    }

    &.premium_plan_btn {
      color: #9253c9;
      font-size: inherit;

      &:after {
        border-color: #9253c9;
        border-top-width: 2px;
        border-left-width: 2px;
      }
    }
  }

  .total_amount {
    border: var(--border);
    height: 36px;
    border-radius: 3px;
    margin-bottom: 16px;
    display: flex;

    span {
      &:first-child {
        border-right: var(--border);
        width: 50%;
      }
    }
  }

  .user_activity_status {
    .selection-details-text {
      line-height: 32px;
    }

    &.with_plan {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 12px;
        gap: 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        
        .item_box {
          align-items: center;


          i {
            width: 24px;
            height: 24px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            border-radius: 50px;
            background-color: var(--orange);
            font-size: 12px;
            color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }

        .detail_box {
          width: 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 12px;
          border: 1px solid #ccc;
          border-radius: 5px;
          background-color: #f9f5f0;
        }
      }

      .total_payment_box {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: 1px solid #f3ad9f;
        border-radius: 5px;
        overflow: hidden;

        tr {
          td {
            font-weight: 300;
            padding: 8px 12px;

            &:last-child {
              text-align: right;
            }
          }

          &.row_selection {
            border-top: 1px solid #f3ad9f;

            td {
              font-weight: 300;
              font-size: 20px;
              color: #f05537;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 16px !important;
  }
}
.wedding_wrap {
  #mycanvas {
    p {
      padding: 10px;
    }
    @media (max-width: 556px) {
      width: 100% !important;
    }
    @media (min-width: 557px) and (max-width: 767px) {
      margin: auto;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 100% !important;
    }
  }

  @media (max-width:991px) {

    .border-custom {
      border: none !important;

    }
    
  }
}

table {
&.table-save-date-list {
  table-layout: unset !important;
  border: 1px solid #0000001f;


  tr {
  
  &:nth-child(even) {

    background-color: transparent !important;
  }
  td {
    vertical-align: top;
    border: 1px solid #eee;
    border-top: none;
}
}

table {
  table-layout: unset !important;

  td {


  }

}
}
}

@media (max-width:767px) {

  .wedding_wrap {

    .wedding_box_left {
      display: none;
     
          }
  }
  
}

@media (min-width:768px) and (max-width:1380px)  {

  .wedding_wrap {

    .wedding_box_left {
      display: flex;
      justify-content: center;
      margin-bottom: -240px;
      .invitation_wraaper_box {
        transform-origin: top;
        transform: scale(0.7);
      }
    }
  }
  
}


.invitaion_consist {
.accordion-item {
border: none;
  .accordion-header {


    button {
      border: none;
      padding-left: 0;
    }
    .accordion-button {
      box-shadow: none !important;



        &::after {
          background-image: none;
          font-size: 20px;
          content: "\f067";
          font-family: "Font Awesome 5 Pro";
          display: block;
          color: var(--orange);
          transform: rotate(0);
      }
      &:not(.collapsed) {
        
        &::after {
          content: "\f068";
          font-family: "Font Awesome 5 Pro";
          display: block;
          color: var(--orange);
        }
      }
    
    
      &:not(.collapsed) {
        background-color: transparent !important;
        color: unset;
      }

     
  
      &:focus {
  
        box-shadow: none !important;
      }

    

       
    }
  }
.accordion-body {

  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

}
  
  
}
