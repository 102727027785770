@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

.invite_box {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: var(--s-16);
  @include respond-to('tablet-portrait') {
    grid-template-columns: repeat(3, 2fr);
  }
  @include respond-to('tablet') {
    grid-template-columns: repeat(4, 2fr);
    gap: var(--s-36);
  }
}

.invitation-card {
  transition: transform 0.2s;

  .invitation_card_show {
    @include border-radius(0.5rem);
    @include box-shadow(0px 0 12px rgba($black, 0.1));
    border: 8px solid $white;
    height: 280px;
    overflow: hidden;
    position: relative;
    margin-bottom: var(--s-12);
    @include respond-to('tablet') {
      height: 420px;
    }

    &:hover {
      .card-hover-button-wrapper {
        opacity: 1;
      }
    }

    .card-hover-button-wrapper {
      position: absolute;
      padding: var(--s-36) var(--s-12);
      padding-top: var(--s-56);
      bottom: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out 0s;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.56) 42%,
        rgba(0, 0, 0, 1) 98%
      );
    }

    img {
      @include border-radius(0.5rem);
      max-width: unset;
      position: relative;
      width: 100%;
    }

    .btn_panel {
      gap: var(--s-12);
      flex-direction: column;
      @include respond-to('tablet') {
        flex-direction: row;
      }

      a {
        width: 100%;
        &:active,
        &:focus {
          background-color: $primary-color;
          color: $white;
          border-color: $primary-color;
        }
      }
    }
  }
}

// .filter_panel {
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   align-items: center;
//   flex-wrap: wrap;
//   gap: 12px;
//   opacity: 0.5;
//   pointer-events: none;
//   .left {
//     display: inline-flex;
//     gap: 12px;
//   }
//   .btn-primary {
//     background-color: #fff;
//     display: flex;
//     gap: 16px;
//     &:hover,
//     &:focus {
//       background-color: #000;
//       color: #fff;
//     }
//   }

//   @media (max-width:1200px) {

//     justify-content: center;

//   }
// }
