/* Bootstrap scss */
@use '~bootstrap/scss/bootstrap';

@use '../abstracts/breakpoints' as *;
@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;
@use '../components/buttons' as *;
@use '../themes/default/colors' as *;
@use '../themes/default/variables' as *;

.user_dashboard {
  .box_model_view {
    @include box-shadow(0 2px 4px rgba($black, 0.1));
    @include border-radius(0.5rem);

    padding: var(--s-12);
    .change_image {
      @include flex-direction(inline-flex, center, center, row);
      background-color: $primary-color;
      height: var(--s-32);
      width: var(--s-32);

      border-radius: 50%;
      top: var(--s-4);
      right: var(--s-4);
      i {
        color: #fff;
      }
    }
  }
  .box_direction {
    @include flex-direction(flex, flex-start, flex-start, column);

    gap: var(--s-16);
    .wedding_description {
      * {
        color: $gray-midium;
        text-align: center;
      }
      @include flex-direction(flex, flex-start, center, column);

      gap: var(--s-12);
      padding: var(--s-16);
      .day {
        color: $primary-color;
      }
    }

    .wedding_prepration {
      margin-top: var(--s-16);
      .box_model_view {
        @include flex-direction(flex, flex-start, flex-start, row);

        position: relative;
        gap: var(--s-12);
        margin-top: var(--s-16);

        .user_pick {
          border-radius: 5px;
          width: calc(var(--s-56) * 2);
          height: calc(var(--s-56) * 2);
          overflow: hidden;
          border: 1px solid lighten($gray, 30%);
          img {
            height: 100%;
            width: 100%;
            max-width: unset;
            object-fit: cover;
          }
        }
        .step_info {
          @include flex-direction(flex, flex-start, flex-start, column);

          gap: var(--s-4);
          flex: 1;
          h4 {
            color: $gray-midium;
          }
          a {
            align-self: flex-end;
            background-color: transparent;
            color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        &.done {
          &::after {
            position: absolute;

            content: '\f058';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 300;
            font-size: var(--s-20);
            color: green;
            top: 0;
            right: 2px;
          }
        }
      }
    }
    @include respond-to('laptop') {
      .wedding_description {
        flex: 1;
        align-items: flex-start;
      }
      .box_model_view {
        @include box-shadow(0 2px 4px rgba($black, 0.1));
        @include border-radius(0.5rem);

        width: 100%;
        &.top_banner {
          @include flex-direction(flex, flex-start, center, row-reverse);

          background-color: $light-pink;
          padding: var(--s-24) !important;
          margin-bottom: var(--s-24);
          .user_pick {
            width: calc(var(--s-72) * 2);
            height: calc(var(--s-72) * 2);
            overflow: hidden;
            border-radius: var(--s-6);

            img {
              height: 100%;
            }
          }
        }
      }
      .wedding_prepration {
        @include flex-direction(flex, flex-start, flex-start, row);

        width: 100%;
        flex-wrap: wrap;
        flex: 1;
        h3 {
          width: 100%;
          text-align: center;
        }

        .user_account_list {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: var(--s-16);
          .user_pick {
            width: calc(var(--s-72) * 2);
            height: calc(var(--s-72) * 2);
            img {
              width: 100%;
            }
          }
          .box_model_view {
            .btn {
              border-radius: $button-border-radius;
              font-size: 1rem;
              height: 2.375rem;
              padding: var(--s-6) var(--s-12);
            }
          }
        }
      }
    }
  }
}
