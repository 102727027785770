.custom_design {
  padding: var(--s-56) 0;
  background-image: url(../assets/img/invitation-Collage.png);
  background-position: right bottom;
  background-size: cover;
  border-top: 1px solid #ccc;
  .box_model {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    a.btn {
      max-width: fit-content;
    }
    p {
      text-align: center;
    }
  }
}

/* how it work*/
.how_it_work {
  padding-top: 50px;
  background-color: #f9f5f0;

  @media (max-width: 767px) {
    padding: 32px 0;
  }
}
