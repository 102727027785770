@use './breakpoints' as *;

// All Z-index value
$zindex-dropdown:                   1000;
$zindex-sticky:                     1020;
$zindex-fixed:                      1030;
$zindex-modal-backdrop:             1040;
$zindex-offcanvas:                  1050;
$zindex-modal:                      1060;
$zindex-popover:                    1070;
$zindex-tooltip:                    1080;

// All sizes units

$Container-width: 1296px;


// all Titles font sizes in mobile
:root {
  --heading-1: 1.375em; //S:22px M:32 L:40
  --heading-2: 1.25em; //S:20px M: 28: L:32
  --heading-3: 1.125em; //S::18px M: 24: L:24
  --heading-4: 1em; //S:16px M:20 L:20
  --heading-5: 0.875em; //S:14px M:20 L: 20
  --heading-6: 0.75em; //S:12px M:16 L:16

  // all running text font sizes
  --text-1: 0.875em; //S: 14px M:18px L:18px
  --text-2: 0.75em; //S:12px M:16 L:16

  //some fixed unit sizes

  --s-4: 0.25em; // 4px
  --s-6: 0.375em; // 6px
  --s-8: 0.5em; // 8px
  --s-10: 0.625em; // 10px
  --s-12: 0.75em; //12px
  --s-14: 0.875em; //14px
  --s-16: 1em; //16px
  --s-18: 1.125em; //16px
  --s-20: 1.25em; //20px
  --s-24: 1.5em; //24px
  --s-28: 1.75em; //28px
  --s-32: 2em; // 32px
  --s-36: 2.25em; // 36px
  --s-40: 2.5em; //40px
  --s-56:3.5em; //56px
  --s-72: 4.5em; //72px
}

// work with >=  1920px screen (large screen)
@include respond-to('laptop') {
    :root {
      --heading-1: 2.2em;
      --heading-2: 2em;
      --heading-3: 1.5em;
      --heading-4: 1.25em;
      --heading-5: 1.25em;
      --heading-6: 1em;
  
      // all running text font sizes
      --text-1: 1.125em; 
      --text-2: 1em; 
    }
  }
