.account_managemn-section {

  .left_side {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #c7c7c7;

    padding: 20px;

    h2 {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .nav {
      border-left: 2px solid #cbcbcb;
      @media (max-width:768px) {
        border-bottom: none;
        border-left:none;
        gap: 20px;
              }

      .nav-link {
        text-align: left;
        color: #212529;
        font-size: 18px;
        padding: 12px 0 12px 30px;

        i {
          margin-right: 15px;
        }

        &.active {
          color: var(--orange);
          background-color: transparent;
          position: relative;

          &::before {

            content: '';
            display: block;
            width: 4px;
            height: 52px;
            background-color: var(--orange);
            position: absolute;
            left: -2px;
            top: 0;

            @media (max-width:768px) {
              width: 100%;
              height: 4px;
            
            top: unset;
              left: 0 !important;
              bottom: -2px;
                    }
          }

        }

        @media (max-width:768px) {
          font-size: 14px;
          padding: 10px 0;
           i {
            margin-right: 5px;
           }
        }

        @media (min-width:768px) and (max-width:812px) {

          font-size: 14px;
          padding: 12px 0 12px 15px;
           i {
            margin-right: 7px;
           }
        }

        @media (min-width:813px) and (max-width:1024px) {

          font-size: 15px;
          padding: 12px 0 12px 15px;
           i {
            margin-right: 7px;
           }
        }

        @media (min-width:1025px) and (max-width:1120px) {

          font-size: 16px;
          padding: 12px 0 12px 15px;
           i {
            margin-right: 7px;
           }
        }
      }
    }
  }

  .right_side {
    padding: 40px;

    .personal_information,
    .contact_information,
    .address_information,
    .main-image,
    .emblem-image,
    .website-pages,
    .owner_row,
    .admin_row {
      background: #fff;
      padding: 20px 20px 40px 20px;
      border: 1px solid #c7c7c7;
      margin-bottom: 30px;

      .group_field_container {

        max-width: 100%;
      }
    }

    .address_information {

      .select-dropdown-container {
        position: relative;
    z-index: 99;
@media (max-width:767px) {
  z-index: unset;
}
    
      }
    }

    .image_column {
      display: flex;
      gap: 30px;

      .main-image {
        width: 50%;

        .brd-btm {
          padding-bottom: 10px;
          border-bottom: var(--border);
        }

        .avatar-upload {
          position: relative;
          max-width: 100%;
          margin: 50px auto;

          .avatar-edit {
            position: absolute;
            right: 12px;
            z-index: 1;
            top: 10px;

            input {
              display: none;

              + {
                label {
                  display: inline-block;
                  width: 34px;
                  height: 34px;
                  margin-bottom: 0;
                  background: #FFFFFF;
                  border: 1px solid transparent;
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                  cursor: pointer;
                  font-weight: normal;
                  transition: all 0.2s ease-in-out;

                  &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                  }

                  &:after {
                    content: "\f040";
                    font-family: "Font Awesome 5 Pro";
                    color: #757575;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                  }
                }
              }
            }
          }

          .avatar-preview {
            width: 100%;
            height: 200px;
            position: relative;
            border: 6px solid #F8F8F8;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

            >div {
              width: 100%;
              height: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            }
          }

        }

      }

      .emblem-image {

        width: 50%;

        .brd-btm {

          border-bottom: var(--border);
          padding-bottom: 10px;
        }

        .avatar-upload {
          position: relative;
          max-width: 205px;
          margin: 50px auto;

          .avatar-edit {
            position: absolute;
            right: 12px;
            z-index: 1;
            top: 10px;

            input {
              display: none;

              + {
                label {
                  display: inline-block;
                  width: 34px;
                  height: 34px;
                  margin-bottom: 0;
                  border-radius: 100%;
                  background: #ffffff;
                  border: 1px solid transparent;
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                  cursor: pointer;
                  font-weight: normal;
                  transition: all 0.2s ease-in-out;

                  &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                  }

                  &:after {
                    content: "\f040";
                    font-family: "Font Awesome 5 Pro";
                    color: #757575;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                  }
                }
              }
            }
          }

          .avatar-preview {
            width: 192px;
            height: 192px;
            position: relative;
            border-radius: 100%;
            border: 6px solid #f8f8f8;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

            >div {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }


      }

      @media (max-width:767px) {
        flex-direction: column;
        margin-bottom: 24px;

        .main-image,
        .emblem-image {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }

    .admins_section {


      .brd-btm {

        border-bottom: 1px solid #d1d1d1;
        padding-bottom: 10px;
      }

      .owner_row {

        .eamil_address {
          margin-top: 30px;

          span {
            border: var(--border);
            padding: 10px 20px;
            margin-bottom: 8px;
            font-weight: 100;
            display: inline-block;
          }

        }
      }


      .admin_row {
        .new_admin {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .admin-btn {
            border: none !important;
            color: var(--orange);
            background: transparent;
          }
        }

        i {
          margin-right: 5px;
        }

        .admin_name {

          tr {
            vertical-align: middle;

            td {
              .wc_setSelection {
                margin: 10px;
                border: none;
                font-weight: 100;


                &:focus-visible {

                  outline: none;

                }
              }

              span {
                display: block;
                color: #838383;
                font-weight: 100;
                font-size: 14px;
              }

              .delete-btn {
                background-color: transparent;
                border: none;
                color: var(--orange);
              }
            }
          }
        }
      }
    }

    @media (max-width:767px) {
      padding: 16px;
    }
  }

  @media (max-width:767px) {
    .multi_field_container {
      flex-direction: column;

      .field_container {
        width: 100% !important;
      }
    }
  }
}
