/* Header */
.scroll-watcher{
  height: 2px;
  width: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  background-color: #bc8129 ;
  scale: 0 1;
  transform-origin: left;
  animation: scroll-watcher linear;
  animation-timeline: scroll();
}
@keyframes scroll-watcher {
  to {scale: 1 1;}
}
.navbar-brand {
  color: var(--orange) !important;
  font-weight: 400;
  font-size: 24px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.stripe-section {
  background:  var(--orange);
  text-align: center;
  color: #fff;

  .top-stripe {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stripe-text {
      flex: 1;
    }

    .close-button {
      width: 34px;
      height: 34px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.header {

  //For overlap menu
  // width: 100%;
  // height: var(--header-height);
  // box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.5);
  // position: absolute;
  // z-index: 999;
  // top: 16px;
  // max-width: 1200px;
  // background-color: #fff;
  // left: 50%;
  // transform: translateX(-50%);
  // border-radius: 16px;

  //For sticky menu
  width: 100%;
  height: var(--header-height);
  box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: #fff;
  .icon-one {
    z-index: 9999;
  }

  .navbar-toggler {
    position: relative;
    width: 28px;
    height: 36px;
    z-index: 9999;

    &:focus {
      outline: 0;
      box-shadow: none !important;
    }

    /* hamburger icon design */
    .hamburger {
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background:  var(--orange);
      position: absolute;
      transition: 0.5s;
      border-radius: 2px;

      &:before {
        top: -8px;
        width: 80% !important;
        right: 0;
      }

      &:after {
        top: 8px;
        left: 0;
      }
    }

    .icon-one {
      position: absolute;
      left: 0;
      width: 28px;
      cursor: pointer;

      &.active {
        .hamburger-one {
          background: rgba(0, 0, 0, 0);

          &:before {
            top: 0;
            transform: rotate(45deg);
            width: 100% !important;
          }

          &:after {
            top: 0;
            transform: rotate(135deg);
          }
        }
      }
    }

    .hamburger-one:before,
    .hamburger-one:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background:  var(--orange);
      transition: 0.5s;
      border-radius: 2px;
    }

    &.collapsed {
      .icon-one {
        &.active {
          .hamburger-one {
            background:  var(--orange);

            &:before {
              top: -8px;
              transform: unset;
              width: 100% !important;
            }

            &:after {
              top: 8px;
              transform: unset;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .navbar-nav {
      justify-content: flex-start !important;
    }
  }

  .navbar-nav {
    justify-content: center;
    flex: 1;
    gap: 12px;
  }

  .nav-item {
    .nav-link {
      color: #000;
      font-family:"Arima", system-ui;
      .item{
        font-weight: 500;
      }
      .icon-mobile{
        display: none;
      }
      @media (max-width: 991px) {
        .icon-desktop{
          display: none;
        }
        .icon-mobile{
          display: inline-flex;
          color: #bc8129;
        }
        .item {
          gap: 8px !important;
          align-items: center;
          justify-content: flex-start;
        }
        .menu_group {
          display: inline-flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    &.drop-down-menu {
      position: relative;
      cursor: default;

      .nav-link {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;

        .far {
          font-size: 12px;
        }
      }

      .menu_popover {
        display: flex;
        position: absolute;
        border-radius: 5px;
        background-color: #fff;
        pointer-events: none;
        visibility: hidden;
        top: 60px;
        z-index: -9999;
        opacity: 0;
        height: 0;
        padding: 0;
        transition: all 0.5s ease-out;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        *{
         
          font-weight: 500;
        }

        .title_mediumLight {
          font-size: 18px;
        }

        p {
          font-size: 13px;
          margin: 0;
          white-space: nowrap;
          color: #57606a;
          transition: all 0.5s ease-out;
        }

        .item {
          text-decoration: none;
          color: #414448;
          padding: 16px;
          transition: all 0.5s ease-out;
          font-weight: 500;

          &:hover {
            color: var(--orange);
            cursor: pointer;
            transition: all 0.5s ease-out;

            p {
              color: var(--orange);
              transition: all 0.5s ease-out;
            }
          }
        }

        .item_column {
          border-right: 1px solid #e7e7e7;

          &:last-child {
            border-right: 0;
          }
        }
      }

      &:hover {
        .menu_popover {
          opacity: 1;
          z-index: 99999;
          height: fit-content;
          visibility: visible;
          pointer-events: unset;
          padding: 24px 0;
          top: 39px;
          transition: all 0.5s ease-out;
        }
      }
    }

    
    &:nth-child(3) {

      .item_column {
        display: flex;
    &::after {
      content: "New";
      display: block;

      background-color: #d70c0c;
      padding: 1px 7px;
      font-size: 12px;
      border-radius: 5px;
      color: #fff;
      height: 19px;
      margin-left: 2px;
      margin-top: -5px;
    }
  }
  }
  }

  .on-mobile {
    display: none;
  }

  .on-desktop {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  @media (max-width: 991px) {
    .on-mobile {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-right: 12px;
      gap: 12px;

      .user-drop-down {
        min-width: unset !important;
        padding: 0;
        min-width: unset !important;
        padding: 0;
        border-left: 1px solid #ccc !important;
        padding-left: 12px;
        margin-left: 6px;
        border-radius: 0;
      }
    }

    .on-desktop {
      display: none;
    }
  }
  @media (max-width: 480px) {
    .on-mobile {
      gap: 5px;
      justify-content: flex-end;
    

      .btn {
        min-width: 50px !important;
        font-size: 12px !important;
      }
    }

    .on-desktop {
      display: none;
    }
  }

  @media (max-width: 991px) {
    position: sticky;
    max-width: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    transform: unset;
    z-index: 999;

    .navbar-collapse {
      height: calc(100vh - 0px);
      // background-color: rgba(255, 255, 255, 0.95);
      background-color: #fff;
      padding: 20px;
      z-index: 999;
      position: absolute;
      right: -100vw;
      top: 0px;
      width: calc(100vw - 50px);
      box-shadow: -5px 14px 5px 0px rgba(0, 0, 0, 0.1);
      overflow: auto;
      transition: all 0.3s ease-out;
      
      &.show{
        right: 0;
      }

      .navbar-nav-scroll {
        overflow-y: unset;
      }

      .menu_popover {
        flex-direction: column !important;
        width: 100%;

        /*-- drop down menus always open --*/
        flex-direction: column !important;
        width: 100%;
        position: relative !important;
        opacity: 1 !important;
        height: fit-content !important;
        position: relative !important;
        opacity: 1 !important;
        top: 0 !important;
        padding: 0 !important;
        visibility: visible !important;
        pointer-events: unset !important;
        box-shadow: none !important;
        .item{
          padding: 12px 12px 12px 24px !important;
          h1{
            font-size: 16px !important;
          }
          p{
            color: #000 !important
          }
          
        }

        .item_column {
          border-right: 0 !important;
        }

        i {
          display: none;
        }

        p {
          white-space: unset !important;
        }
      }
    }
  }

  &:hover {
    overflow: visible;
  }

  .mobile-image {
    display: none;
  }

  @media (max-width: 600px) {
    height: 100%;

    .mobile-image {
      display: block;
    }

    .desktop-image {
      display: none;
    }
  }
  &.is-sticky{
    position: sticky;
    max-width: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    transform: unset;
  }
}

/* Notification button */
.notification_icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: #333333;
  background: #ededed;
  border: var(--border);
  outline: none;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background: #cccccc;
  }
}

.notification_icon-button__badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background:  var(--orange);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 300;
}

// Switch Account Drawer Inner Design 
.admins_section {

  .owner_row {

    .eamil_address {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      span {

        padding: 12px 0;
    border-bottom: 1px solid #eee;
    font-size: 16px;
    font-family: "Arima", system-ui;
         cursor: pointer !important;
    i {

      margin-right: 15px;
      padding: 10px;
      border-radius: 50px;
      background: #ffeede;
    }

      }
    }
  }
}


.notification_modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
    .notification-section {
      background-color: #fffdd0;
        text-align: center;
       
        position: relative;
        z-index: 999;
        max-width: 500px;
        margin: 0 auto;
        border-radius: 5px;
        padding: 32px;
        margin-top: 14px;
    
      .top-notification {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        flex-direction: column;
        img{
          width: 70px;
        }
    
        .notification-text {
          flex: 1;
          display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        color: #000;
        .btn {
          text-decoration: none !important;
        }
        }
    
        .close-button {
          width: 34px;
          height: 34px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
}