.cart_wrapper {
  .cart_box {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    h2 {
      font-size: 25px;
    }
    .table {
      text-align: center;
    }

    .cross_btn {

      span {
        display: unset !important;
      }
    }
  }
}
