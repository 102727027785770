/**
 * Breakpoints for Responsive Design
 *
 * This file defines responsive breakpoints to be used throughout the project.
 * These breakpoints help to create a consistent responsive design by adjusting
 * styles at various screen widths.
 *
 * Each breakpoint corresponds to a common device width, allowing for fine-tuned
 * styling across different devices and screen sizes.
 *
 * @version: 1.0
 */

 @use 'sass:map';

 // Define responsive breakpoints
 $breakpoints: (
   'tablet-portrait': 768px,
   'tablet': 1024px,
   'laptop': 1300px,
   'largeScreen': 1920px,
   'largeMediumScreen': 2100px,
   'xxlargeScren': 2500px
 );
 
 // Mixin to apply styles for a specific breakpoint and up
 @mixin respond-to($breakpoint) {
   @media only screen and (min-width: map.get($breakpoints, $breakpoint)) {
     @content;
   }
 }