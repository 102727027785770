.terms_privacy_container {
  width: 100%;
  max-width: 1024px;
  margin: 50px auto;
  ol{
    li{
      font-size: 18px;
    }
    p{
      font-weight: 400;
      font-size: 16px;
      .nav-link {
        display: inline;
        padding: 0;
      }
       .inner_list {
        padding-top: 12px;
        margin-bottom: 0;
      }
    }
  }
  .inner_list {
    margin-bottom: 16px;
    li {
      font-weight: 400;
      font-size: 16px;

    }
  }
  .vendor-terms {
    border-top: 1px solid #ccc;
    padding-top: 50px;
    margin-top: 50px;
}
  .bottom_condition{
    border-top: 1px solid #ccc;
    padding-top: 50px;
    margin-top: 50px;
    .title {
      font-size: 20px;
    }
  }
}
