.group_manager {
  padding: 32px 0;

  .grid {
    list-style: none;
    display: flex;
    max-height: 700px;
  }

  .gc {
    box-sizing: border-box;

    margin-right: -0.25em;
    min-height: 1px;
    vertical-align: top;
  }

  .gc--1-of-3 {
    width: 33.33333%;
    max-width: 300px;
  }

  .gc--2-of-3 {
    @extend %flex-center;
    place-items: center;

    flex: 1;
  }

  .tab_container {
    position: relative;
    margin: 32px auto 0;

    .menu {
      height: 100%;
      overflow: auto;

      li {
        padding: 15px 20px 15px 40px;
        color: var(--textGlobalColor);
        /* background: $second-color; */
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        position: relative;
        vertical-align: middle;
        transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        p {
          font-size: 14px;
          opacity: 0.9;
        }

        span {
          &.light {
            height: 10px;
            width: 10px;
            position: absolute;
            top: 24px;
            left: 15px;
            /* background-color: var(--textGlobalColor); */
            border-radius: 100%;
            transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }

        &.active {
          /* color: var(--orange); */
          padding: 15px 20px 15px 20px;

          /* background-color: var(--sliderCardBKG); */
          span {
            &.light {
              background-color: var(--orange);
              left: 0;
              height: 100%;
              width: 3px;
              top: 0;
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  ul {
    &.nacc {
      @extend %flex-center;
      width: 100%;
      height: 100% !important;
      position: relative;
      height: 0px;
      list-style: none;
      margin: 0;
      padding: 0;
      transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);

      li {
        opacity: 0;
        transform: translateX(50px);
        position: absolute;
        list-style: none;
        transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);

        &.active {
          transition-delay: 0.3s;
          z-index: 2;
          opacity: 1;
          transform: translateX(0px);
          text-align: center;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}
