/**
 * Default Theme Mixin
 *
 * Applies styles for the default theme by using predefined variables.
 * This mixin sets specific background, text, and link colors for the default theme.
 *
 * Usage:
 * - Include this mixin in your stylesheet to apply the default theme styles to elements.
 *
 * Variables Used:
 * - $white: White color used for background.
 * - $gray: Gray color used for text.
 * - $primary-color: Main color used for links in the default theme.
 *
 * Elements Styled:
 * - body: Sets the background color to $white and text color to $gray.
 * - a: Sets link color to $primary-color.
 *
 * Example Usage:
 * ```scss
 * .default-mode {
 *     @include default-theme;
 * }
 * ```
 *
 * @author: Ganesh Ughade
 * @version: 1.0
 */

 @mixin default-theme {
    // Variables specific to default theme
    $title-color: $primary-color;
    $sub-title-color: $black;
    $background-color: $white;
    $text-color: $gray;
    $link-color: $primary-color;
    

    // Apply styles using default theme variables
    body {
        background-color: $background-color;
        color: $text-color;
    }

    h1, h2, h3, h4, h5, h6{
        color: $title-color;
    }

    a {
        color: $link-color;
    }

   

    // Additional styles for default theme can be added here
}
