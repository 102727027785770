/* Manage Website Page */

.hide-mobile {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.manage_website_banner {
  padding: 32px 0;

  .container {
    display: flex;
    align-items: center;

    .event_slogan {
      width: 100%;
      max-width: 500px;

      .copy {
        margin-top: 20px;

        form {
          position: relative;
          width: 100%;
          display: flex;
          gap: 8px;

          input {
            padding-right: 90px;
            display: block;
            width: 100%;
            outline: 0;
            background: #eee;
            font-size: 15px;
            padding: 10px 10px;
            margin-bottom: 0px;
            width: 60% !important;
          }

          .btn {
            width: 38%;
          }
        }

        @media (max-width: 767px) {
          .btn {
            width: 100% !important;
          }

          form {
            flex-direction: column;
            align-items: center;

            input {
              width: 100% !important;
            }
          }
        }
      }

      @media (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f4f4f7;
        padding-bottom: 20px;

        .copy {
          margin-top: 0px !important;
        }
      }
    }

    .action-item-event {
      flex: 1;
      display: flex;
      gap: 12px;
      justify-content: flex-end;
    }
  }

  .right_side {
    flex: 1;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }
}

.managent_website {
  background-color: #f4f4f7;
  position: relative;

  .container {
    .page_contain {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 20px;

      .left_side {
        padding: 10px;
        width: 100%;
        max-height: 80vh;
        display: flex;
        flex-direction: column;
        .accordion {
          height: 100%;
          overflow: auto;
        }

        .top_line {
          display: flex;
          justify-content: space-between;

          .user-drop-down {
            .user_popover-menu {
              top: 38px !important;
            }
          }

          .healing_page {
            font-size: 25px;
          }

          span {
            display: flex;
            gap: 10px;
            align-items: baseline;
          }

          @media (max-width: 767px) {
            margin: 0px !important;
          }
        }

        .accordion-item {
          margin: 15px 0;

          .accordion-header {
            display: flex;
            align-items: center;
            padding-right: 20px;
            gap: 30px;

            .ant-space-compact-block {
              width: auto;
            }

            button.sub_menu_btn {
              background: transparent;
              border: none;
              font-size: 14px;
              padding: 6px;
              background-color: #f05537;
              color: #fff;
              border-radius: 4px;
              width: 30%;
              line-height: 1;
            }

            span.function_button {
              display: flex;
              align-items: center;
              padding: 5px;

              border-radius: 8px;

              i {
                font-size: 20px;
                color: #f05537;
                cursor: pointer;
              }
            }
          }

          .accordion-button {
            background-color: #fff !important;

            .heading_name {
              width: 100%;
            }

            &::after {
              content: none;
            }

            &:not(.collapsed) {
              box-shadow: none;
              color: #393655;
            }

            &:not(.collapsed)::before {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
              transform: rotate(-180deg);
            }

            &::before {
              flex-shrink: 0;
              width: 1.25rem;
              height: 1.25rem;
              content: '';
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
              background-repeat: no-repeat;
              background-size: 1.25rem;
              transition: transform 0.2s ease-in-out;
              margin-right: 10px;
            }

            &:focus {
              border-color: transparent;
              box-shadow: none;
            }

            .check-btn {
              width: 100%;
              display: flex;
              justify-content: flex-end;
            }
          }
        }

        .upload_img {
          margin: 10px 0;

          .border_dotted {
            border: 3px dashed #ccc;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-radius: 18px;
          }
        }

        .detail_w {
          margin: 20px 0;

          span {
            color: #9d9d9d;
            display: block;
          }
        }

        .more_itm {
          margin: 20px 0;

          .contain-bd {
            position: relative;

            p {
              line-height: 28px;
              padding-top: 16px;
              color: #583a20;
              text-align: center;
            }

            h1,
            h2,
            h3,
            h4 {
              margin: 0;
              text-align: center;
            }

            .column_wrap_design {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: flex-start;

              &:hover {
                &::before {
                  content: '';
                  width: 100%;
                  height: 100%;
                  background-color: rgba(255, 255, 255, 0.9);
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }

              .left,
              .right {
                width: 50%;
              }

              img {
                width: 50%;
              }

              .adit-delete-btn {
                gap: 8px;

                display: none;
              }

              &:hover {
                .adit-delete-btn {
                  display: flex !important;
                  justify-content: flex-end !important;
                  gap: 8px;
                  display: block;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  color: #000;
                  font-size: 16px;
                  z-index: 999;
                  transform: translate(-50%, -50%);
                  cursor: pointer;

                  span {
                    width: 32px;
                    height: 32px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }

              #dangeroushtmlDiv {
                width: 100%;
              }
              .left-right-wrap {
                gap: 0 !important;
                #dangeroushtmlDiv {
                  width: 100%;
                }
                h1 {
                  font-size: 22px;
                  word-wrap: break-word;
                }
                p {
                  word-wrap: break-word;
                }
                .left,
                .right {
                  padding: 24px;
                }
              }
              @media (max-width: 767px) {
                .left,
                .right {
                  width: 100%;
                }
              }
            }
          }

          a {
            cursor: pointer;
            color: #0d6efd;
            text-decoration: underline;
          }
        }

        .custome_page {
          display: flex;
          padding: 20px;
          justify-content: center;
          border: 3px dashed #000;
          border-radius: 20px;
          margin-top: 40px;
          background: #fff;
          gap: 10px;

          button {
            color: #0d6efd;
            text-decoration: underline;
            border: none;
            background: no-repeat;
          }

          @media (max-width: 676px) {
            margin: 20px 0;
          }
        }

        .accordion-body {
          .more_itm {
            .contain-bd {
              text-align: center;
            }

            .event_container {
              text-align: center;

              .event_card {
                text-align: center;
                padding: 30px;
                width: 100%;
                border: 1px solid #ccc;
                max-width: 300px;
                border-radius: 10px;
                max-width: 240px;

                h3 {
                  font-size: 40px !important;
                }

                h5 {
                  font-size: 18px;
                  font-weight: 600;
                  color: #583a20;
                  margin: 16px 0;
                }

                span {
                  margin-bottom: 10px;
                  font-size: 18px;
                }

                p {
                  padding: 5px;
                  margin: 0;
                  line-height: normal;
                }
              }
            }

            .event_container.vertical {
              display: flex;
              flex-direction: column;
              gap: 24px;
              align-items: center;
            }

            .event_container.horizontal {
              display: flex;
              gap: 5px;
              justify-content: center;
              flex-wrap: wrap;
            }
          }
        }
      }

      .right_side {
        width: 24%;
        padding: 10px;

        img {
          max-width: 100%;
        }

        .top_line {
          display: flex;
          justify-content: space-between;
        }

        .color_select {
          margin-top: 20px;
          display: flex;
          gap: 20px;
          flex-wrap: wrap;

          .color_selt {
            width: 24px;
            border: 1px solid #a8a8a8;
            height: 24px;
            display: inline-flex;
          }
        }

        .custom_chnge {
          padding-top: 40px;

          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }

      @media (max-width: 767px) {
        padding: 20px 0;

        .left_side {
          width: 100% !important;

          .accordion-header {
            gap: 8px !important;

            .ant-btn {
              font-size: 12px !important;
              padding: 5px !important;
            }
          }
        }

        .right_side {
          width: 100% !important;
        }
      }

      @media (min-width: 901px) and (max-width: 1028px) {
        width: 500px;

        .left_side {
          width: 100% !important;
        }

        .right_side {
          width: 28% !important;
        }
      }
      #gallery {
        .contain-bd {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}

#website_popover_panel {
  .page_name {
    padding: 0 0 20px 0;
    // border-bottom: var(--border);
    // margin-bottom: 40px;

    .content_panel {
      .block_title {
        margin: 0 0 0px 0 !important;

        textarea {
          height: 85px !important;
          background-color: #fff;
        }
      }
    }
  }

  .brd-line {
    border-top: var(--border);
    padding-top: 30px;

    .content_panel {
      .block_title {
        textarea {
          height: auto !important;
          background-color: #fff !important;
        }
      }
    }
  }

  .pop-up_pannel {
    .add-to-page {
      padding-bottom: 20px;

      .block_elements {
        .active-box {
          background: var(--orange) !important;
          color: #fff;
        }
      }
    }

    .add-title {
      button {
        padding: 8px 14px;
        margin: 5px;
        border: none;
        font-size: 15px;
        border-radius: 3px;
        text-transform: uppercase;

        &.active-button {
          background: var(--orange) !important;
          color: #fff;
        }
      }
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-height: var(--header-height);
    }

    .content_panel {
      .upload_img {
        &.popup-form {
          max-width: 600px;
          width: 100%;
        }
      }
    }

    .layout_radio {
      display: flex;
      gap: 50px;
      margin: 25px 0;
    }

    .layout_main_div {
      display: inline-block;

      .layout_wrapper {
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        margin-top: 10px;

        .layout_row {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 15px;
          background: #f8f8fa;
          border: 1px solid #d3d3d3;
          border-radius: 10px;

          .layout_column {
            span.label_text {
              font-size: 16px;
              display: inline-block;
              margin-bottom: 10px;
            }

            input {
              background-color: #fff !important;
            }

            .accordion-body {
              padding: 0;

              .upload_img {
                .border_dotted {
                  background-color: #fff !important;
                }
              }
            }

            .content_panel {
              textarea {
                background-color: #fff !important;
              }
            }
          }
        }
        @media (max-width: 480px) {
          gap: 10px !important;
          .layout_row {
            padding: 10px !important;
          }
        }
      }
    }
  }

  .rdw-editor-wrapper {
    border: 1px solid #f1f1f1 !important;

    .rdw-editor-toolbar {
      border: unset;
      border-bottom: 1px solid #f1f1f1 !important;
    }
  }
}

.vertical_layout {
  display: flex;
  gap: 50px;

  .layout_wrap {
    padding: 20px;
    border: var(--border);

    .radiobox {
      margin-bottom: 17px;
    }
  }
}

.offcanvas-body {
  overflow-y: unset !important;
}

.event_container {
  &.vertical {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  &.horizontal {
    display: flex;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .event_card {
    h3 {
      font-size: 40px !important;
    }

    p {
      font-size: 20px !important;
    }

    h5 {
      border-bottom: 1px solid;
      display: inline-block;
      font-size: 25px;
    }

    h6 {
      font-size: 25px !important;
      margin: 20px 0;
    }

    .table {
      tr {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 20px 0;
        border-bottom: 1px solid;

        th {
          font-size: 18px;
        }
      }
    }
  }
}

.event_rsvp_container {
  .no_rsvp {
    display: flex;
    justify-content: space-between;

    .guest_selction {
      width: 100%;

      table {
        width: 100%;

        tr {
          width: 100%;

          td {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
}
body {
  .rsvp_container_main {
    display: none;
    .all_invited_Guests {
      li {
        span {
          border: none !important;

          input {
            background-color: #fff !important;
            border: none;
            color: #000 !important;
            font-weight: 400 !important;
            &::placeholder {
              font-weight: 400 !important;
              color: #000 !important;
            }
          }

          .phone_input_country {
            background: #fff !important;
            height: 100%;
            gap: 4px;
            padding: 0 10px 0;
            .PhoneInputCountry {
              width: 28px;
              min-width: 28px;
            }
            input.PhoneInputInput {
              font-weight: 400 !important;
              height: 100%;
              background: transparent;
              color: #000 !important;
              width: 100%;

              .PhoneInputCountryIconImg {
                margin: 0 !important;
              }
              &:read-only {
                background-color: #f7f7f7 !important;
                border-color: #ccc;
                color: #9f9797;
                cursor: default;
              }
              .phone_input_country {
                background: #fff;
                height: 100%;
                gap: 4px;
                padding: 0 10px 0;

                .PhoneInputCountry {
                  width: 28px;
                  min-width: 28px;
                }
                input.PhoneInputInput {
                  height: 100%;

                  width: 100%;

                  &:focus-visible {
                    outline: none;
                  }
                }
              }
            }
          }
        }
        &::before {
          content: none !important;
        }
      }
    }
  }
}

.mailing_address {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  span.input_fields_row {
    width: 48% !important;

    label.field_title {
      padding-bottom: 5px !important;
    }

    input {
      font-size: 15px;
      font-weight: 400;
    }

    @media (max-width: 567px) {
      &.w-mobile-100 {
        width: 100% !important;
      }
    }
  }
}

.add-more-guest {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 0 !important;

  .add-guest-btn {
    color: #000;
    padding: 10px;
    background: transparent;
    border: 1px solid #000;
    border-radius: 6px;
    cursor: pointer !important;
    display: block;

    &:disabled {
      color: #9f9f9f;
      border: 1px solid #9f9f9f;
      cursor: not-allowed !important;
    }

    i {
      display: block;
    }
  }

  .update-guest-btn {
    background: var(--orange);
    padding: 8px 14px;
    border-radius: 8px;
    cursor: pointer !important;
    color: #fff;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.mailing_address-container {
  P {
    padding-top: 0 !important;
  }
}

.event_rsvp_container {
  .event_list {
    flex-direction: column !important;
  }

  .btn_rsvp_group {
    padding-top: 16px !important;

    a {
      font-size: 14px;
    }

    .reset_rsvp {
      color: #0000ee;
    }
  }

  .no_of_people {
    display: flex;
    gap: 16px;

    .program_date {
      flex: 1;
      font-size: 15px !important;
    }

    .invite_people {
      display: flex;
      gap: 8px;
      padding-top: 0;
      display: flex;
      align-items: center;
      line-height: 20px;
      width: 180px !important;
      padding-top: 0;
      display: flex;
      align-items: center;
      line-height: 20px;
      font-size: 12px !important;
      justify-content: flex-end;

      @media (max-width: 567px) {
        justify-content: flex-start;
      }

      .box {
        display: inline-flex;
        border: 1px solid #000;
        border-radius: 3px;
        width: 35px;
        height: 32px;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: 567px) {
      flex-direction: column !important;
    }
  }
}

.Toastify__toast-container {
  z-index: 9999999 !important;
}

.preview_heaer {
  background-color: #fff;
  height: auto;
  box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e3e3e3;
  .container {
    max-width: 1920px;
    display: flex;
    align-items: center;
  }

  .left_side {
    flex: 1;

    .title_large {
      font-size: 22px;

      span {
        font-size: 14px;
        color: #000;
        font-weight: 600;
      }
    }

    .copy_url_panel {
      display: inline-flex;
      gap: 12px;

      input {
        height: auto;
        padding: 0;
        border: 0;
        background-color: transparent;
        width: fit-content;
        font-weight: 600;
        color: #000;
      }
    }
  }

  .right_side {
    display: inline-flex;
    gap: 12px;
  }
  .hide-desktop {
    display: none;
  }
  @media (max-width: 900px) {
    .hide-desktop {
      display: inline-flex;
    }
  }
  @media (max-width: 767px) {
    .container {
      flex-direction: column;

      .right_side {
        scale: 0.7;
      }
    }
  }
}

.new_website_preview {
  max-width: 1920px !important;
  display: flex;

  .priview_header {
    display: none !important;
  }

  .page_contain {
    width: 700px;
    min-width: 700px;

    @media (max-width: 900px) {
      width: 100%;
      min-width: unset !important;
      max-width: unset;
      scale: 1;
    }
  }

  .preview_website_container {
    flex: 1;
    background-color: #fbfbfb;

    .toggle_btn_group {
      border: 1px solid #ccc;
      border-radius: 5px;
      overflow: hidden;
      height: 40px;

      .size-select {
        position: absolute;
        top: -100px;
        left: -100px;
        opacity: 0;

        & + label {
          padding-left: 0px;

          width: 55px;
          height: 100%;
          z-index: 1;
          background: #fff;

          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #807e7e;

          svg {
            fill: currentcolor;
            height: 20px;
            width: 20px;
          }

          &::before {
            display: none;
          }
        }

        &:checked {
          + label {
            background-color: #e5e5e5;

            svg {
              fill: #000;
            }
          }
        }
      }

      @media only screen and (max-width: 767px) {
        display: none !important;
      }
    }

    .invitation-preview {
      background-color: #fbfbfb;

      .preview-wrapper {
        .desktop {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 16px;

          #viewer {
            position: relative;
            //width: 100%;
            // max-width: 1024px;

            width: calc(1920px - 700px);
            transform: scale(0.8);
            height: 800px;
            background-color: #fff;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: rgba(51, 51, 51, 0.16) 0px 8px 40px;

            &::after {
              content: ' ';
              display: block;
              width: 16px;
              height: 16px;
              position: absolute;
              top: 24px;
              border-radius: 50%;
              transform: translateY(-50%);
              left: 12px;
              box-shadow: rgb(235, 235, 235) 24px 0px 0px 0px, rgb(235, 235, 235) 48px 0px 0px 0px;
              background: rgb(235, 235, 235);
              opacity: 1;
              transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
            }

            @media (max-width: 1919px) {
              width: calc(100vw - 740px);
            }

            @media (max-width: 1730px) {
              width: calc(100vw - 740px);
              transform: scale(0.6);
            }
          }
        }
      }
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1800px) and (min-width: 1200px) {
    max-width: 100% !important;
  }

  @media only screen and (max-width: 1320px) and (min-width: 901px) {
    .page_contain {
      width: 450px;
      min-width: 450px;
    }

    .preview_website_container {
      .desktop #viewer {
        width: calc(100%) !important;
        transform: scale(0.8) !important;
        transform-origin: left, top;
      }
    }
  }
  @media only screen and (max-width: 1600px) and (min-width: 1321px) {
    .page_contain {
      width: 550px;
      min-width: 550px;
    }

    .preview_website_container {
      .desktop {
        #viewer {
          width: calc(100vw - 372px) !important;
          transform: scale(0.75) !important;
          transform-origin: top left;
        }
      }
    }
  }
}

.website_bottom_link {
  p {
    font-weight: 400;

    font-size: 14px !important;
  }
  a {
    color: var(--orange) !important;
    font-weight: 400;
    font-size: 14px !important;
  }
}

.unauthorized_wrap {
  .unauthorized_content {
    h3 {
      margin-bottom: 0 !important;
    }
    &.verification-otp {
      .enter-email {
        margin-bottom: 20px;
        input {
          margin: 0 5px !important;
        }
      }
    }
  }
}

@media (max-width: 665px) {
  .unauthorized_wrap {
    .unauthorized_content {
      &.verification-otp {
        padding: 0;

        h3 {
          font-size: 35px !important;
          margin-bottom: 0;
        }

        .enter-email {
          margin-bottom: 20px;
          input {
            width: 38px !important;
            height: 38px !important;
            margin: 0 5px !important;
            font-size: 18px !important;
          }
        }
      }
    }
  }
}
