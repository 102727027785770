
@mixin object-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.vendor_profile_container {
    padding: 32px 0;
    color: #71717a;

    .box_design {
        border-radius: 12px;
        border: 1px solid #e4e4e7;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 5px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .box_title {
            font-size: 17px;
            color: #09090b;
            font-weight: 500;
        }
    }

    .vendor_all_details_panel {
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;

        .profile_left_panel,
        .profile_right_panel {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
        .profile_left_panel{
            position: sticky;
            height: 60vh;
            top: 80px;
            @media (max-width: 800px) {
                position: relative;
                height: unset;
                top: unset;
                
            }
        }

        .user_info {
            .company_logo {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 1px solid #e4e4e7;
                overflow: hidden;

.profile-pic {
  color: transparent;
  transition: all .3s ease;
  @include object-center;
  position: relative;
  transition: all .3s ease;
  
  input {
    display: none;
  }
  
  img {
    position: absolute;
    object-fit: cover;
    width:100px;
    height: 100px;
    box-shadow:  0 0 10px 0 rgba(255,255,255,.35);
    border-radius: 100px;
    z-index: 0;
  }
  
  .-label {
    cursor: pointer;
    height: 100px;
    width: 100px;
  }
  
  &:hover {
    .-label {
      @include object-center;
      background-color: rgba(0,0,0,.8);
      z-index: 10000;
      color:rgb(250,250,250);
      transition: background-color .2s ease-in-out;
      border-radius: 100px;
      margin-bottom: 0;
      flex-direction: column;
    }
  }
  
  span {
    display: inline-flex;
    padding: .2em;
    font-size: 12px;
  }
}

            }

            .contact_info {
                display: flex;
                flex-direction: column;
                gap: 12px;
                margin-top: 8px;

                span {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    font-size: 15px;
                }

                i {
                    color: #09090b;
                    font-size: 20px;
                }
            }
        }

        .social_media_info {
            align-items: flex-start;

            .social_info {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;

                form {
                    width: 100%;
                }

                span {
                    display: flex;
                    gap: 8px;
                    height: 30px;
                    align-items: center;

                    i {

                        color: var(--orange);
                    }

                    img {
                        min-width: 20px;
                        max-width: 20px;
                        height: auto;
                        align-self: center;
                    }

                    input {
                        border: 0;
                        flex: 1;
                        border-bottom: 1px solid #ccc;
                        height: 30px;
                        font-size: 14px;
                        color: #09090b;
                        border-radius: 0;
                    }

                    ::placeholder {
                        font-size: 14px;
                        opacity: 1;
                        /* Firefox */
                    }
                }
            }
        }

        .profile_status {
            align-items: flex-start;

            .status {
                display: flex;
                gap: 8px;
                width: 100%;
                align-items: center;

                .progress_bar {
                    width: 100%;
                    background-color: #f0f0f0;
                    height: 10px;
                    border-radius: 3px;
                    overflow: hidden;
                }
            }
        }

        .status_no {
            font-size: 14px;
            color: #09090b;
            font-weight: 600;
        }

        .profile_right_panel {
            .slider_panel {
                padding: 0;
                overflow: hidden;
                position: relative;

                .image-gallery {
                    width: 100%;

                    .image-gallery-thumbnails-wrapper {
                        display: none;
                    }

                    .image-gallery-fullscreen-button {
                        display: none;
                    }

                    .image-gallery-right-nav,
                    .image-gallery-left-nav {
                        svg {
                            width: 24px;
                        }

                    }

                    .image-gallery-slides {
                        img {
                            height: 300px;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .gallery_item {
                    &:nth-child(1) {
                      grid-column: span 2;
                      grid-row: span 2;
                    }
                
                    &:nth-child(2n) {
                      grid-column: unset;
                    }
                
                    &:nth-child(4n) {
                      grid-column: span 2;
                      grid-row: span 1;
                    }
                  }
                .gallery_btn{
                    position: absolute;
                    bottom: 2px;
                    right: 5px;
                    z-index: 9;
                    display: inline-flex;
                    gap: 10px;
                    align-items: center;
                    .slider_upload_images {
                        background-color: #fff;
                        width: 36px;
                        height: 36px;
                        border-radius: 5px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 600;
                        color: #09090b;
                        border: 1px solid #ccc;
                    }
                }
                
            }

            .tab_panel_container {
                border: 0;
                box-shadow: none;
                padding: 0;
                align-items: flex-start;

                .nav-item {
                    button {
                        font-weight: unset !important;
                    }
                }

                .tab-content {
                    padding: 16px 0;
                }

               
            }
        }

        @media (max-width: 767px) {
            grid-auto-flow: row;
            grid-template-rows: 1fr;
            grid-template-columns: unset;

            .field_container {
                width: 100% !important;
            }
        }
    }
}

.vendor_image_selection_drawer {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 40px);
    overflow: auto;
    gap: 16px;
    align-content: flex-start;
    justify-content: space-between;

    div {
        position: relative;
        flex-basis: 98%;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07), 0 7px 27px 0 rgba(44, 41, 37, 0.06);
        padding: 8px;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 65px;
            height: 65px;
            border-radius: 3px;
        }

        .img_action_panel {
            display: inline-flex;
            align-items: center;
            gap: 10px;

            i {
                width: 24px;
                height: 24px;
                border-radius: 3px;
                color: #444;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    color: #bc8129;
                }
            }
        }
    }
}

.vendor_image_selection_drawer_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    min-height: 40px;
    margin-bottom: 12px;
    position: relative;

    [type="file"] {
        color: #000;
        width: 36px;
        height: 36px;
        position: absolute;
        right: 6px;
        bottom: 6px;
        z-index: 99;
        opacity: 0;
    }

    [type="file"]::-webkit-file-upload-button {
        background: #bc8129;
        color: #fff;
        cursor: pointer;
        border: 0;
        font-size: 14px;
        outline: none;
        padding: 10px 25px;
        transition: all 1s ease;
        height: 36px;
        border-radius: 3px;
    }

    .upload_more_images {
        background-color: #fff;
        width: 36px;
        height: 36px;
        position: absolute;
        z-index: 9;
        bottom: 6px;
        right: 6px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: #09090b;
        border: 1px solid #ccc;
    }

}

.vendor_enquiry_form_container {
    %box_design {
        padding: 16px 0;
        margin-top: 16px;
        border-top: 1px solid #e4e4e7;
        border-bottom: 1px solid #e4e4e7;
    }

    .inside_title {
        font-size: 19px;
        color: #333335;
        font-weight: 500;

        &.with_image {
            img {
                width: 24px;
                margin-right: 12px;
            }
        }

        &.with_text {
            display: flex;
            flex-direction: column;

            p {
                color: #71717a;
                margin: 0;
                padding: 0;
            }
        }
    }

    .viewer_list {
        @extend %box_design;

        display: flex;
        align-items: center;
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 5px;

        border: 1px solid #e4e4e7;
        display: flex;
        width: 100%;
        max-width: 600px;
        padding: 12px;
        border-radius: 5px;
        background-color: #fff3ee;
        border: 1px solid #e1dad7;

        .viewer_count {
            display: flex;
            flex-direction: column;
            gap: 2px;

            span {
                font-size: 16px;
                color: #fff;
                font-weight: 600;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: #f96328;
                box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 16px 0px, rgba(44, 41, 37, 0.06) 0px 7px 27px 0px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            p {
                font-size: 15px;
                color: #333335;
            }
        }
    }

    .viewer_list_with_name {
        @extend %box_design;

        .enquiry_items_container {
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            li {
                @extend %box_design;
                box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 5px;
                border: 1px solid #e4e4e7;

                display: flex;
                width: 100%;
                max-width: 600px;
                padding: 12px;
                border-radius: 5px;
                margin-top: 0;

                .viewer_name {
                    flex: 1;

                    display: flex;
                    flex-direction: column;

                    .name {
                        color: #09090b;
                        font-size: 18px;
                    }

                    .wedding_day {
                        font-size: 15px;
                    }
                }

                .btn {
                    min-width: 36px;
                    min-height: 36px;
                    max-width: 36px;
                    border-radius: 50%;
                    border-color: #09090b !important;
                    color: #09090b;
                    font-weight: 600;
                    align-self: flex-start;
                }

            }
        }
    }

    .unlock_plan {
        margin-top: 16px;
        display: flex;
        gap: 16px;
        max-width: 600px;
        padding: 12px;
        border-radius: 5px;
        background-color: #fff3ee;
        border: 1px solid #e1dad7;

        img {
            min-width: 32px;
            width: 32px;
            align-self: flex-start;
        }

        .plan_details {
            flex: 1;
        }

        .permium_vendors {
            display: flex;
            padding-top: 16px;
            gap: 16px;

            span {
                display: inline-flex;

                img {
                    width: 24px;
                    min-width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }

            }

            p {
                margin: 0;
                padding: 0;
                color: #333335;
            }
        }
    }
}