.box {
  @extend %flex-center !optional;
  position: relative;
  width: 100%;
  max-width: 300px;
  /* box-shadow: 0px 12px 15px -13px rgba(0, 0, 0, 0.5); */
  overflow: hidden;
  padding: 12px;
  border: 1px solid #eee;

  .text-box-event {
    position: relative;
    display: flex;
    flex-direction: column;

    .title_small {
      color: #000;
      margin-bottom: 10px;
    }

    p {
      font-weight: normal;
      font-size: 13px;
      margin: 0;
    }
  }

  &:before {
    width: 100%;
    height: 200%;
    border-radius: 100%;
    content: '';
    position: absolute;
    right: -50%;
  }

  &.orange {
    border-color: #fdd197;
    background-image: linear-gradient(35deg, #fde9cd 50%, transparent 50%),
      linear-gradient(-60deg, #fdd197 30%, #fdd197 30%);

    &:before {
      background-color: #fde9cd;
    }
  }

  &.red {
    border-color: #edc8bb;
    background-image: linear-gradient(35deg, #ffe8e0 50%, transparent 50%),
      linear-gradient(-60deg, #ffdacc 30%, #ffdacc 30%);

    &:before {
      background-color: #ffe8e0;
    }
  }

  &.purple {
    border-color: #e4cde9;
    background-image: linear-gradient(35deg, #fbeaff 50%, transparent 50%),
      linear-gradient(-60deg, #e4cde9 30%, #e4cde9 30%);

    &:before {
      background-color: #fbeaff;
    }
  }

  &.blue {
    border-color: #b7e0ed;
    background-image: linear-gradient(35deg, #d5f5ff 50%, transparent 50%),
      linear-gradient(-60deg, #b7e0ed 30%, #b7e0ed 30%);

    &:before {
      background-color: #d5f5ff;
    }
  }

  &.purpleLight {
    border-color: #bec2ff;
    background-image: linear-gradient(35deg, #d7d9fb 50%, transparent 50%),
      linear-gradient(-60deg, #bec2ff 30%, #bec2ff 30%);

    &:before {
      background-color: #d7d9fb;
    }
  }
}