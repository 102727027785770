.login_fullPage_container:has(.address_collector) {
  height: auto !important;
}

.content-container {
  border: 1px solid #bc8129;
  background: #fffcf7;
  padding: 20px;

}

.address_collector {
  display: flex;


  .address_left_section {
    width: 100%;
    max-width: 400px;
    overflow-y: auto;
    position: relative;
    // box-shadow: rgba(100, 100, 111, 0.3) 27px 7px 29px 0px;
    border-right: 0;
    padding: 20px;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 2;
    height: calc(100vh - 0rem);
    // background-image: url(../assets/img/form-left-banner.png);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100%;
    background-color: #f1e4d5 !important;
    // overflow-y: scroll;

    @media (max-width:767px) {
      height: auto;
      position: unset;
      padding-bottom: 30px !important;

      
    }
    .content_address_wrap {
      display: flex;

      flex-direction: column;
      gap: 30px;
      .image_couple {
        border-radius: 10px;
        overflow: hidden;
        width: 250px;
      }
      .address_content_wrap {

        padding: 14px;
    background: #f6ede2;
      }

      span {
        color: var(--orange);
        margin: 0 auto;
        font-size: 20px;
        // max-width: 200px;
        img {
          width: 100%;
        }
      }

     
    }

    @media (max-width: 767px) {
      max-width: unset;
      overflow-y: unset;

      .content_address_wrap {
        text-align: center;
        margin-top: 15px;
      }
    }
  }

 

  .address_right_section {
    // background-image: url(../assets/img/background-7.jpg);
    // background-repeat: repeat;
    // overflow-x: scroll;
    background-color: #f7f4f1;
    padding: 0 20px;
    justify-content: center;
    .address_collector_form {
      max-width: 600px;
      width: 100%;
      margin: auto 0;

      background: #ffffff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 40px !important;
    height: auto;

    @media (max-width:991px) {
      max-width: 450px;
      padding: 30px !important;
      margin: 30px 0;
    
        label {
        font-size: 14px !important;
        }
     
    }
      .top_content {
        text-align: center;
        // margin-bottom: 60px;
        h1 {
          font-size: 40px;
          color: var(--orange);

          span {
            display: block;
            color: var(--orange);

            font-size: 35px;
          }
          @media (max-width:991px) {
            font-size: 30px; 

            span {
              font-size: 30px; 

            }
            
          }
        }
      }

      &.address_collect_step_2 {
        max-width: fit-content;
        width: 100%;
        margin: 20px 0;

        @media (min-width:768px) and (max-width:991px) {
         
          margin: 20px;
        }

        input[type='email'],
        input[type='password'],
        input[type='text'],
        input[type='date'],
        input[type='number'],
        input[type='time'],
        textarea {
          border-radius: 3px;
          border: 1px solid #ccc !important;
          border: none;
          background: #f8f8fa;
          font-weight: 400;
        }

        textarea {
          resize: none;
        }

        .list_guest {
          padding-bottom: 30px;
          border-bottom: 1px solid #8b8b8b;
          span.number_field {
            border: 1px solid var(--orange);
            width: 35px;
            height: 35px;
            border-radius: 30px;
            color: var(--orange);
            display: flex;
            justify-content: center;
            font-size: 18px;
            align-items: center;
            font-weight: 600;
          }
        }

        .select_dropdown__control {
         
          border-radius: 2px;
       
          border: 1px solid #ccc;
          background: #f8f8fa;
          font-size: 14px;
          display: flex;
          font-size: 14px;
          align-items: center;

          .select_dropdown__value-container {
            height: auto;

            .select_dropdown__input-container {
              height: auto;
              opacity: 0 !important;
            }
          }
        }
        .content_panel {
          overflow: unset;
          .group_field_container {
            gap: 25px;
          }
        }
      }

      &.all_set_wrap {
        padding: 20px;

        .top_content {
          margin-bottom: 40px;
          text-align: left !important;
          padding: 0 20px;
        }

        .about_row {
          padding: 20px;
          border: 1px solid #c9c1c1;
          background: #fff;
          border-radius: 20px;
          
        }
      }
      @media (max-width: 676px) {
        margin: 20px 0 !important;
        h1 {
          font-size: 20px !important;
          margin-bottom: 0px;
          line-height: 1.2 !important;
        }
      }
    }
    @media (max-width: 767px) {
      overflow-x: unset !important;
      height: auto !important;
      .top_content {
        margin-bottom: 0 !important;
      }
      &.content_panel {
        overflow: unset !important;
      }
    }
  }
  @media (max-width: 767px) {

    justify-content: space-between;

    overflow-x: unset !important;
    height: auto !important;
    .address_left_section {
      padding: 0 20px;
      background-image: none !important;
      background-color: transparent;
      box-shadow: none;

      .content_address_wrap {
        .address_content_wrap {
          p {
            font-size: 14px;
            line-height: 1.2;
          }
        }
      }
    }

    .address_right_section {
      .address_collector_form {
      
        .top_content {
          display: none;
        }
        .enter_form {
          label {
            font-size: 16px ;
            font-weight: 100;
            text-align: center;
          }
          form {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          
        }
      }
    }

    .top_content {
      margin-bottom: 0 !important;
    }
    &.content_panel {
      overflow: unset !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .address_left_section {
      max-width: 300px;

      .content_address_wrap {
        .address_content_wrap {
          p {
            font-size: 14px;
          }
        }
        .date_wrap {
          .box_time {
            .date {
              font-size: 20px !important;
            }
          }
        }
      }
    }

    .address_right_section {
      padding: 0 !important;
      .address_collector_form {
        padding: 40px !important;
      }
    }
  }
}

.date_wrap {
  .date_duration {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--orange);
    margin-top: 0px;
    border-top: 1px solid var(--orange);
    .box_time {
      color: var(--orange);
      padding: 0px 20px;
      font-size: 40px;
      border-right: 1px solid var(--orange);
      .date {
        display: block;
        font-size: 40px;
        font-weight: 600;
        color: var(--orange);
        font-size: 30px;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
  }
}